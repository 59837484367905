// sortingSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ColumnSort {
  column: string
  direction: 'asc' | 'desc'
}

interface SortingState {
  [sortKey: string]: ColumnSort[]
}

interface SortAction {
  sortKey: string
  sort?: ColumnSort
  index?: number
}

const initialState: SortingState = {}

export const sortingSlice = createSlice({
  name: 'sorting',
  initialState,
  reducers: {
    addSorting: (state, action: PayloadAction<SortAction>) => {
      const { sortKey, sort } = action.payload
      if (!state[sortKey]) {
        state[sortKey] = []
      }
      if (sort) {
        state[sortKey].push(sort)
      }
    },
    removeSorting: (state, action: PayloadAction<SortAction>) => {
      const { sortKey, index } = action.payload
      if (state[sortKey] && index !== undefined) {
        state[sortKey] = state[sortKey].filter((_, i) => i !== index)
      }
    },
    removeAllSorting: (state, action: PayloadAction<string>) => {
      const sortKey = action.payload
      if (state[sortKey]) {
        state[sortKey] = []
      }
    },
    updateSorting: (state, action: PayloadAction<SortAction>) => {
      const { sortKey, index, sort } = action.payload
      if (state[sortKey] && index !== undefined && sort) {
        state[sortKey][index] = sort
      }
    },
  },
})

export const { addSorting, removeSorting, removeAllSorting, updateSorting } =
  sortingSlice.actions

export default sortingSlice.reducer

export const { reducer } = sortingSlice

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { ENTITIES } from 'src/common/apis/constants'
import { get, post, postDelete, put } from 'src/common/utils/api'
import {
  RolesSubmitValues as SubmitValues,
  RolesMutationProp as mutationProp,
} from 'src/common/types/Role'

export const useGetPermissionsQuery = () => {
  return useQuery(['acl/permissions'], () => {
    return get(`acl/permissions`)
  })
}

export const useGetRolesQuery = () => {
  return useQuery(['acl/roles'])
}

export const useGetRoleQuery = (roleId: string) => {
  return useQuery(['acl/roles', roleId], () => {
    return get(`acl/roles/${roleId}`)
  })
}

export const useCreateRoleMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => post('acl/roles', values, setFieldError),
    onSuccess: () => {
      toast.success(`Role was created successfully`)
      queryClient.invalidateQueries([ENTITIES.ROLES])
      callback && callback()
    },
    onError: () => {
      toast.error(`Role could not be created! Please try again`)
    },
  })
}

export const useUpdateRoleMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => put(`acl/roles/${values.id}`, values, setFieldError),
    onSuccess: () => {
      toast.success(`Success! Role was updated`)
      queryClient.invalidateQueries([ENTITIES.ROLES])
      callback && callback()
    },
    onError: () => {
      toast.error(`Role could not be updated! Please try again`)
    },
  })
}

export const useDeleteRoleMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (rolesId: string) => postDelete(`acl/roles/${rolesId}`),
    onSuccess: () => {
      toast.success(`Role was deleted successfully`)
      queryClient.invalidateQueries([ENTITIES.ROLES])
      callback && callback()
    },
    onError: () => {
      toast.error(`Role could not be deleted! Please try again`)
    },
  })
}

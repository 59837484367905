/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tabs as MUITabs,
  Tab,
  Stack,
  Typography,
} from '@mui/material'
import WarningIcon from '@mui/icons-material/Warning'
import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react'

interface TabcontentProps {
  children?: ReactNode
  index: number
  value: number
}

function Tabcontent(props: TabcontentProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabcontent"
      hidden={value !== index}
      id={`simple-tabcontent-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

interface A11yProps {
  id: string
  'aria-controls': string
}

function a11yProps(index: number, id: string): A11yProps {
  return {
    id: `tab-${id}-${index}`,
    'aria-controls': `simple-tabcontent-${index}`,
  }
}

interface TabItem {
  title: string | ReactNode
  content: ReactNode
  disabled?: boolean
  onClick?: (event: any) => void
  subTab?: boolean
  ref?: any
}

interface TabsProps {
  items: (TabItem | null)[]
  id: string
  activeTab?: number
  setActiveTab?: (tab: number) => void
  selectedSubTab?: any
  removeSelectedSubTab?: () => void
  orientation?: 'vertical' | 'horizontal'
  indicatorColor?: 'primary' | 'secondary'
  variant?: 'fullWidth' | 'scrollable' | 'standard'
}
const Tabs: React.FC<TabsProps> = ({
  items,
  id,
  activeTab,
  setActiveTab,
  orientation = 'horizontal',
  variant = 'scrollable',
  indicatorColor = 'primary',
  selectedSubTab,
  removeSelectedSubTab,

  ...rest
}) => {
  const [value, setValue] = useState(0)
  const [modal, setModal] = useState<{ open: boolean; tab?: number | null }>({
    open: false,
    tab: 0,
  })

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    if (items[value]?.ref?.current?.isFormDirty) {
      setModal({ open: true, tab: newValue })
      return
    }
    setActiveTab && setActiveTab(newValue)
    if (items[newValue]?.subTab && !selectedSubTab) return
    setValue(newValue)
    localStorage.setItem('tabs-' + id, newValue.toString())
    removeSelectedSubTab?.()
  }

  const handleChangeTab = () => {
    setActiveTab && setActiveTab(modal.tab ?? 0)
    setValue(modal.tab ?? 0)
    localStorage.setItem('tabs-' + id, (modal.tab ?? 0).toString())
    setModal({ open: false, tab: 0 })
  }

  useEffect(() => {
    if (selectedSubTab) setValue(activeTab || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubTab])

  return (
    <Box
      display={'flex'}
      flexDirection={orientation === 'horizontal' ? 'column' : 'row'}
    >
      <Box mr={orientation === 'horizontal' ? 0 : '8px'} flexWrap={'nowrap'}>
        <MUITabs
          value={value}
          onChange={handleChange}
          aria-label={id}
          sx={{ marginLeft: 2 }}
          variant={variant}
          orientation={orientation}
          indicatorColor={indicatorColor}
          textColor={indicatorColor}
          {...rest}
        >
          {items
            .filter((x) => x !== null)
            .map((item, index) => (
              <Tab
                label={item?.title}
                disabled={item?.disabled}
                key={'tab-' + index}
                {...a11yProps(index, id)}
                sx={{ minWidth: '70px' }}
                onClick={item?.onClick}
              />
            ))}
        </MUITabs>
      </Box>
      <Box pt={1}>
        {items
          .filter((x) => x !== null)
          .map((item, index) => (
            <Tabcontent
              value={value}
              index={index}
              key={'tab-content-' + index}
            >
              {item?.content}
            </Tabcontent>
          ))}
      </Box>

      <Dialog
        open={modal?.open}
        sx={{ p: 5 }}
        BackdropProps={{
          style: { opacity: '0.2', backgroundColor: '#000000' },
        }}
      >
        <DialogContent sx={{ width: '350px' }}>
          <DialogContentText id="alert-dialog-description">
            <Stack alignItems="center" spacing={3}>
              <Stack alignItems="center" spacing={1}>
                <WarningIcon sx={{ fontSize: '40px' }} color="warning" />
                <Typography variant="h6">Leave tab ?</Typography>
              </Stack>
              <Typography variant="subtitle2">
                You have unsaved changes. To save them, click on the save button
                below
              </Typography>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ padding: '10px' }}
            onClick={() => {
              handleChangeTab()
              setModal({ open: false, tab: null })
            }}
            color="error"
          >
            Discard Changes
          </Button>
          <Button
            style={{ padding: '10px', margin: 0 }}
            onClick={() => {
              items[value]?.ref?.current?.submitForm()
              setModal({ open: false, tab: null })
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Tabs

import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'
const CreateShipment = lazy(
  () => import('../../../modules/files/shipments/shipmentTabs'),
)

const InvoiceDetailsPage = lazy(
  () => import('../../../modules/invoices/details-page'),
)

const PurchaseDetailsPage = lazy(
  () => import('../../../modules/purchases/details-page'),
)

const FilesOverviewPage = lazy(
  () => import('src/pages/backoffice/Operations/Files/overview'),
)

const FilesDeailsPage = lazy(
  () => import('src/pages/backoffice/Operations/Files/detail'),
)

export const filesRoutes: CustomRouteObject[] = [
  {
    path: 'files',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <FilesOverviewPage />,
        scopes: ['file.read', 'file.write'],
      },
      {
        path: ':id',
        element: <FilesDeailsPage />,
        scopes: ['file.read', 'file.write'],
      },
      {
        path: ':fileId/shipments/create',
        element: <CreateShipment />,
        scopes: ['file.write'],
      },
      {
        path: ':fileId/shipments/:shipmentId',
        element: <CreateShipment />,
        scopes: ['file.read', 'file.write'],
      },
      {
        path: ':fileId/invoices/new',
        element: <InvoiceDetailsPage />,
        scopes: ['invoice.write'],
      },
      {
        path: ':fileId/invoices/:type/:invoiceId',
        element: <InvoiceDetailsPage />,
        scopes: ['invoice.read', 'invoice.write'],
      },
      {
        path: ':fileId/purchases/:purchaseId',
        element: <PurchaseDetailsPage />,
        scopes: ['purchase.read', 'purchase.write'],
      },
      {
        path: ':fileId/invoices/:invoiceId',
        element: <InvoiceDetailsPage />,
        scopes: ['invoice.read', 'invoice.write'],
      },
    ],
  },
]

import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'

const VesselsOverviewPage = lazy(
  () => import('src/pages/backoffice/Vessels/overview'),
)

export const vesselsRoutes: CustomRouteObject[] = [
  {
    path: 'vessels',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <VesselsOverviewPage />,
        scopes: ['vessel.read', 'vessel.write'],
      },
    ],
  },
]

/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress, MenuItem, Popper, TextField } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useTheme } from '@mui/material/styles'
import { useRef, useState } from 'react'
import { Option, OptionKey } from '../../grid/types'
import { StaticAutocompleteWithCreateProps } from './types'

// This autocomplete expects value as id or array of ids
const StaticAutocompleteFilter = ({
  identifier = 'id' as OptionKey,
  name = '',
  label,
  options = [],
  readOnly = false,
  allowCreate = true,
  isLoading = false,
  size = 'medium',
  multiple = false,
  placeholder,
  variant = 'outlined',
  disableClearable = false,
  onChange = () => {
    return
  },
  value,
  ...otherProps
}: StaticAutocompleteWithCreateProps) => {
  const theme = useTheme()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isDownOrUpKeyPressed, setisDownOrUpKeyPressed] = useState(false)

  const allOptions = allowCreate
    ? [
        {
          value: '',
          label: 'All',
          id: '',
        },
        ...options,
      ]
    : options

  const inputRef = useRef<HTMLInputElement | null>(null)

  const filterOptionsFn = createFilterOptions({
    stringify: (option: Option) =>
      typeof option?.label === 'string'
        ? option?.label
        : option?.value?.toString(),
    matchFrom: 'any',
    limit: Infinity,
  })

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <Autocomplete
        id={name + '-autocomplete'}
        PopperComponent={(props: any) => (
          <div id={'autocomplete-container-' + name}>
            <Popper
              {...props}
              container={containerRef.current}
              onMouseEnter={() => setisDownOrUpKeyPressed(false)}
            />
          </div>
        )}
        sx={{ width: '100%' }}
        placeholder={placeholder}
        disabled={isLoading || readOnly}
        multiple={multiple}
        options={allOptions}
        getOptionLabel={(option: Option) => {
          if (option?.value === '') return ''
          return typeof option?.label === 'string'
            ? option?.label
            : option?.value?.toString()
        }}
        isOptionEqualToValue={(option, selectedOption) => {
          return option?.value === selectedOption?.value
        }}
        autoSelect={isDownOrUpKeyPressed}
        autoHighlight
        openOnFocus
        value={options.find((x) => x?.value === value)}
        size={size}
        disableClearable={disableClearable}
        onChange={(_, newValue: Option[] | Option | null) => {
          if (multiple) {
            const validItems = Array.isArray(newValue)
              ? newValue?.filter(
                  (item: Option) => item.value !== 'create-new-option',
                )
              : []

            onChange(validItems.map((item: Option) => item[identifier]))
            onChange && onChange(multiple ? validItems : validItems[0] || null)
          } else {
            if (
              newValue &&
              !Array.isArray(newValue) &&
              newValue.value !== 'create-new-option'
            ) {
              onChange(newValue[identifier])
              onChange &&
                onChange({ target: { value: newValue[identifier] } } || null)
            } else {
              onChange('')
              onChange && onChange(null)
            }
          }
          setisDownOrUpKeyPressed(false)
        }}
        onBlur={() => {
          setisDownOrUpKeyPressed(false)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            setisDownOrUpKeyPressed(true)
          }
          if (e.key === 'Tab') {
            // Filter out the object with id "create-new-option"
            const filteredOptions = allOptions.filter(
              (option) => option.id !== 'create-new-option',
            )

            // Ensure there is only one element left
            if (filteredOptions.length === 1) {
              if (multiple) {
                const selectedItems = [
                  ...(value || []),
                  filteredOptions[0][identifier],
                ]
                onChange(selectedItems)
              } else {
                onChange(filteredOptions[0][identifier])
              }
            }
          }
        }}
        renderOption={(props, option, { selected }) => (
          <MenuItem
            {...props}
            selected={selected}
            key={option.id}
            id={option.id}
          >
            {option.label}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            id={name + '-autocomplete-input'}
            fullWidth
            label={label}
            name={name}
            // helperText={showValidationError ? get(formik.errors, name) : ''}
            placeholder={placeholder}
            // error={showValidationError}
            // onBlur={props?.handleBlur}
            variant={variant}
            value={value}
            inputRef={inputRef}
            disabled={isLoading}
            size={size}
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              readOnly: readOnly,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      style={{
                        position: 'absolute',
                        right: 10,
                        top: 20,
                        color: theme.palette.grey[500],
                      }}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        filterOptions={(options, params) => {
          try {
            const filtered = filterOptionsFn(options, params)

            if (params.inputValue !== '') {
              const existsNew =
                filtered.findIndex(
                  (item: Option) => item.value === 'create-new-option',
                ) !== -1

              if (!existsNew && allowCreate) {
                filtered.unshift({
                  value: 'create-new-option',
                  label: 'Add new',
                })
              }
            }
            return filtered
          } catch (e) {
            console.log({ e, params })
            return options
          }
        }}
        {...otherProps}
      />
    </div>
  )
}

export default StaticAutocompleteFilter

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
// import { styled, alpha } from '@mui/material/styles'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { Chip, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import { useAuth } from 'src/common/hooks/use-auth'
import { useRouter } from 'src/common/hooks/use-router'
import { useStyles } from './MenuStyles'
import { useGetOffices } from 'src/common/apis'
import { useEffect } from 'react'

interface Office {
  id: string
  name: string
  status: string
}

export function OfficesButton() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const theme = useTheme()
  const classes = useStyles()
  const auth = useAuth()
  const { user } = auth

  const { data, isLoading } = useGetOffices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const offices = data?.data || []

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSelectOffice = (curentOffice: Office) => {
    const officeID = curentOffice.id

    localStorage.setItem('activeOfficeId', officeID)
    localStorage.setItem('activeOffice', JSON.stringify(curentOffice))
    location.reload()
    setAnchorEl(null)
  }
  const router = useRouter()
  const handleClickManage = () => {
    router.push('/offices')
    setAnchorEl(null)
  }

  const activeOfficeId =
    localStorage.getItem('activeOfficeId') || user?.officeId

  const filteredOffices = (offices || []).filter((office: any) =>
    user?.officeIds?.includes(office.id),
  )

  useEffect(() => {
    //if no active office is set, set the first office as active
    if (
      (!activeOfficeId || activeOfficeId === 'undefined') &&
      offices.length > 0
    ) {
      const defaultUserOffice = offices.find(
        (office: any) => office.id === user?.defaultOfficeId,
      )

      const secondDefaultOffice = filteredOffices ? filteredOffices[0] : null
      const thirdDefaultOffice = offices[0]

      handleSelectOffice(
        defaultUserOffice || secondDefaultOffice || thirdDefaultOffice,
      )
    }
  }, [
    activeOfficeId,
    user?.defaultOfficeId,
    offices,
    user?.officeIds,
    filteredOffices,
  ])

  return (
    <div>
      <Button
        id="office-button"
        aria-controls={open ? 'office-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        disableElevation
        onClick={handleClick}
        startIcon={<CorporateFareIcon />}
        endIcon={
          isLoading ? <CircularProgress size={20} /> : <KeyboardArrowDownIcon />
        }
        disabled={isLoading}
      >
        {offices?.find((office: Office) => office.id === activeOfficeId)?.name}
      </Button>
      <Menu
        id="office-menu"
        MenuListProps={{
          className: classes.menuList,
          'aria-labelledby': 'office-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: classes.menuPaper,
        }}
      >
        <MenuItem
          key="manage offices"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          disableRipple
          className={classes.menuItem}
        >
          <Typography variant="h6"> Offices</Typography>
          <Chip
            label="Manage"
            variant="outlined"
            onClick={handleClickManage}
            component={Button}
          />
        </MenuItem>
        {filteredOffices?.map((office: Office) => {
          return (
            <MenuItem
              id={`office-${office.name}`}
              key={office.name}
              onClick={() => handleSelectOffice(office)}
              disableRipple
              sx={{
                color:
                  office.id === activeOfficeId
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
              }}
            >
              <LocationOnIcon />
              {office.name}
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import Paper from '@mui/material/Paper'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DescriptionIcon from '@mui/icons-material/Description'
import DifferenceIcon from '@mui/icons-material/Difference'
import LockIcon from '@mui/icons-material/Lock'
// import LockOpenIcon from '@mui/icons-material/LockOpen'
import InvoiceForm from './form/mainForm'
import ConfirmationDialog from 'src/common/components/dialogs/ConfirmationDialog'
import {
  useCreateInvoiceMutation,
  useFinaliseInvoiceMutation,
  useGenerateInvoicePDFMutation,
  useGetInvoiceDetails,
  useUpdateInvoiceMutation,
} from './useRequests'
import { initialValues, validationSchema } from './form/formConfigs'
import { StickyFooter } from 'src/common/components/sticky-footer'
import { formatDate } from 'src/common/utils/date-locale'
import { useGetFileDetails } from '../files/useFilesRequests'
import { Reference } from 'src/common/components/reference'
import { STATUS, Status, StatusKey } from 'src/common/components/statuses'
import scrollToFirstError from 'src/common/utils/scrollToFirstError'
import { useGetShipementDetails } from '../files/shipments/useShipmentRequests'
import { useModal } from 'src/common/hooks/use-modal'
import { omit } from 'lodash'
import GenereatePDFButton from 'src/common/components/pdf-button'
import Button from 'src/common/components/loadingButton'
import OptionsMenu from 'src/common/components/options-menu'
import ButtonWithOptions from 'src/common/components/button-with-options'

const processInvoiceDetails = (details: any, type?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, status, reference, ...remainingDetails } = details
  // Process details based on type
  if (type === 'credit') {
    return {
      ...remainingDetails,
      lineItems: remainingDetails?.lineItems?.map((line: any) => ({
        ...line,
        unitPrice: line.unitPrice * -1,
      })),
    }
  } else if (type === 'debit') {
    return remainingDetails
  }

  // Return original details without omitted fields if type is null or undefined
  return details
}

const InvoiceDetails = () => {
  const { fileId, invoiceId, type } = useParams()
  const invoiceDetailsQuery = useGetInvoiceDetails(invoiceId || null)
  const details: any = invoiceDetailsQuery.isLoading
    ? {}
    : processInvoiceDetails(invoiceDetailsQuery.data || {}, type)

  const {
    handleClose: handleCloseConfirmation,
    handleOpen: handleOpenConfirmation,
    open: openConfirmation,
  } = useModal()

  const fileDetailsQuery = useGetFileDetails(fileId || null)
  const shipmentDetailsQuery = useGetShipementDetails({ fileId: fileId || '' })
  const shipmentDetails = shipmentDetailsQuery?.isLoading
    ? {}
    : shipmentDetailsQuery.data

  const fileDetails = fileDetailsQuery?.isLoading ? {} : fileDetailsQuery?.data
  const isFinanceLocked = fileDetails?.isFinanceLocked
  const isLoading =
    (invoiceId && invoiceDetailsQuery.isLoading) || fileDetails.isLoading
  // const handleReopen = () => {
  //   // todo: reoopen mutation
  //   return
  // }
  const navigate = useNavigate()

  const handleNewCredit = (el: any) => {
    fileId && navigate(`/files/${fileId}/invoices/credit/${el?.id}`)
  }
  const handleNewDebit = (el: any) => {
    fileId && navigate(`/files/${fileId}/invoices/debit/${el?.id}`)
  }

  const createMutation = useCreateInvoiceMutation({
    callback: () => {
      formik.resetForm()
      navigate(`/files/${fileId}`)
    },
  })
  const updateMutation = useUpdateInvoiceMutation({
    callback: () => {
      formik.resetForm()
      navigate(`/files/${fileId}`)
    },
  })
  const finaliseMutation = useFinaliseInvoiceMutation({
    callback: () => {
      formik.resetForm()
      navigate(`/files/${fileId}`)
    },
    id: details?.id,
  })

  const finaliseInvoice = () => {
    finaliseMutation.mutate()
  }
  const officeData = JSON.parse(localStorage.getItem('activeOffice') || '{}')
  console.log(details)
  const formik = useFormik({
    initialValues: details.id
      ? {
          ...initialValues,
          ...details,
          businessEntity: details.businessEntityId,
          businessEntityId: details.businessEntityId?.id,
          mot: details?.id ? details?.mot || null : shipmentDetails?.mot,
          customerId: details.customer || fileDetails?.customerId,
          currency: details?.currency || officeData?.currency,
          conversionRate: details?.conversionRate || 1,
          customerReferenceNo: details?.id
            ? details?.customerReferenceNo
            : `${details?.businessEntityId?.address?.fullAddress || ''} ${
                details?.businessEntityId?.taxId || ''
              }`,
          clientNumber: details?.businessEntityId?.clientNumber,
          fileId,
        }
      : {
          ...initialValues,
          ...details,
          customerId: details.customer || fileDetails?.customerId,
          businessEntity: details.businessEntityId,
          businessEntityId: details.businessEntityId?.id,
          currency: details?.currency || officeData?.currency,
          clientNumber: details?.businessEntity?.clientNumber,
        },
    validationSchema: validationSchema,
    enableReinitialize: true,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: async (values: any): Promise<void> => {
      console.log({ values })
    },
  })

  const submitForm = async () => {
    if (isFinanceLocked) return

    formik.setFieldTouched('taxRate')
    formik.setFieldTouched('mot')

    await formik.handleSubmit()

    if (Object.keys(formik.errors || {}).length === 0) {
      const { customerId, contactId } = formik.values as any

      const submitData = {
        ...formik?.values,
        mot: formik?.values?.mot || null,
        invoiceDate: formik?.values.invoiceDate
          ? formatDate(formik?.values.invoiceDate)
          : formik?.values.invoiceDate,
        dueDate: formik?.values.dueDate
          ? formatDate(formik?.values.dueDate)
          : formik?.values.dueDate,
        customerId: customerId?.id || null,
        contactId: contactId?.value || null,
        invoiceItems: formik?.values.lineItems,
        conversionRate:
          formik?.values.currency === officeData?.currency
            ? 1
            : formik?.values.conversionRate,
        fileId: fileId,
      }
      const ommitedData = omit(submitData, [
        'customer',
        'businessEntity',
        'lineItems',
      ])

      details.id
        ? updateMutation.mutate({
            values: ommitedData,
            setFieldError: formik.setFieldError,
          })
        : await createMutation.mutate({
            values: ommitedData,
            setFieldError: formik.setFieldError,
          })
    }
    scrollToFirstError()
  }
  const generatePDfMutation = useGenerateInvoicePDFMutation({ id: details.id })
  const isDraft = details.id && details.status === 'draft'
  const isReOpened = details.id && details.status === 're-opened'
  const isFinalized = details.id && details.status === 'finalized'

  const hasChanges = Object.keys(formik?.touched)?.length > 0

  return (
    <Box component="main" py={4}>
      <Stack
        pl={2}
        spacing={1}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack spacing={1} display={'flex'} flexDirection={'row'} gap={1}>
          <Typography variant="h5">
            {invoiceId && !type ? 'Invoice details ' : 'Create invoice'}{' '}
          </Typography>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Reference type="file" reference={fileDetails?.reference} />
          )}
          {details?.reference && (
            <Reference type="invoice" reference={details?.reference} />
          )}
          <Status
            key={details.status}
            status={STATUS[details.status as StatusKey]}
          />
        </Stack>
      </Stack>

      <Paper sx={{ margin: 2 }}>
        <Container maxWidth="xl" sx={{}}>
          <Stack spacing={4}>
            <InvoiceForm
              formik={formik}
              readOnly={isFinalized || isFinanceLocked}
              reOpenedFields={
                isReOpened
                  ? [
                      'invoiceDate',
                      'invoiceNo',
                      'customerId',
                      'entityId',
                      'clientNo',
                      'currency',
                      'unitPrice',
                      'quantity',
                      'subtotal',
                      'taxRate',
                      'total',
                    ]
                  : []
              }
              isLoading={isLoading}
              fileReference={fileDetails?.reference}
              showExchangeRate={formik.values.currency !== officeData?.currency}
            />
          </Stack>
        </Container>
      </Paper>
      <StickyFooter
        rightComponent={
          <>
            {' '}
            <GenereatePDFButton
              links={
                formik?.values.pdfUrl
                  ? [
                      {
                        name: 'Invoice.pdf',
                        url: formik?.values.pdfUrl,
                      },
                    ]
                  : []
              }
              generatePdf={() => generatePDfMutation.mutate()}
              showLinkFirst
              hideLinks
              isLoading={generatePDfMutation.isLoading}
            />
            <ButtonWithOptions
              variant="contained"
              options={[
                {
                  title: 'Save',
                  hideThisOption: !!details?.id,
                  handleClick: submitForm,
                  isLoading:
                    isLoading ||
                    formik.isSubmitting ||
                    createMutation.isLoading ||
                    updateMutation.isLoading,
                  disabled:
                    isLoading ||
                    isFinanceLocked ||
                    formik.isSubmitting ||
                    updateMutation.isLoading,
                },
                {
                  title: 'Update',
                  hideThisOption: !details?.id,
                  handleClick: submitForm,
                  isLoading: updateMutation.isLoading,
                  disabled:
                    isLoading ||
                    isFinanceLocked ||
                    formik.isSubmitting ||
                    updateMutation.isLoading,
                },
                {
                  title: 'Confirm',
                  hideThisOption: !isDraft,
                  handleClick: handleOpenConfirmation,
                  disabled:
                    isFinanceLocked ||
                    formik.isSubmitting ||
                    finaliseMutation.isLoading ||
                    hasChanges,
                  isLoading: finaliseMutation.isLoading,
                  icon: <LockIcon />,
                },
                // {
                //   title: 'Re Open',
                //   hideThisOption: !isFinalized,
                //   handleClick: handleReopen,
                //   disabled: !isFinalized,
                //   icon: <LockOpenIcon />,
                // },
              ]}
            />
          </>
        }
        leftComponent={
          <>
            <Button
              id="sales-files-shipment-cancel"
              variant="outlined"
              isLoading={isLoading}
              onClick={() => {
                navigate(`/files/${fileId}`)
              }}
            >
              {isFinalized ? 'Go back' : 'Cancel'}
            </Button>

            {invoiceId && !type && (
              <OptionsMenu
                title={<ContentCopyIcon />}
                options={[
                  {
                    title: 'Copy Debit Invoice',
                    handleClick: () => {
                      handleNewDebit(details)
                    },
                    icon: <DescriptionIcon color="success" />,
                  },
                  {
                    title: 'Copy Credit Invoice',
                    handleClick: () => {
                      handleNewCredit(details)
                    },
                    icon: <DifferenceIcon color="error" />,
                  },
                ]}
              />
            )}
          </>
        }
      />
      <ConfirmationDialog
        buttonId="confirm-invoice-file"
        open={openConfirmation}
        title="Confirm Finalize"
        type="finalize"
        message="Are you sure you want to finalize this Invoice? You will not be able to modify it again"
        onConfirm={finaliseInvoice}
        isLoading={finaliseMutation.isLoading}
        onCancel={() => {
          handleCloseConfirmation()
        }}
      />
    </Box>
  )
}

export default InvoiceDetails

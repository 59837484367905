/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Quote } from 'src/common/types/Quote'
import { convertEnumToOptions } from 'src/common/utils/helpers'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'

export const initialValues: Quote = {
  lineItems: [
    {
      id: uuidv4(),
      serviceType: null,
      serviceTypeItem: null,
      description: null,
      unitPrice: null,
      quantity: null,
      uom: null,
      total: null,
    },
  ],
  reference: null,
  customerId: null,
  businessEntityId: null,
  contactId: null,
  mot: null,
  validUntil: '',
  description: '',
  currency: 'EUR',
  discount: 0,
  subTotal: null,
  total: null,
  taxRate: null,
  terms: [],
  remarks: '',
  status: 'new',
  priceType: null,
  containerType: null,
  incoTerm: null,
  pickUpPort: null,
  deliveryPort: null,

  pickupAddress: {
    fullAddress: null,
    street: null,
    country: null,
    zipCode: null,
  },
  deliveryAddress: {
    fullAddress: null,
    street: null,
    country: null,
    zipCode: null,
  },
  packages: [],
}

const packageShape = Yup.object().shape({
  qty: Yup.string().required('Required'),
  dimensions: Yup.object().shape({
    width: Yup.string().nullable(),
    length: Yup.string().nullable(),
    height: Yup.string().nullable(),
    unit: Yup.string().required('Required'),
    volume: Yup.object().shape({
      value: Yup.string().required('Required'),
      unit: Yup.string().nullable(),
    }),
  }),
  weight: Yup.object().shape({
    value: Yup.string().required('Required'),
    unit: Yup.string().required('Required'),
  }),
})
const today = new Date()
today.setHours(0, 0, 0, 0) // Set time to midnight to consider the entire day
export const editValidationSchema = Yup.object().shape({
  customerId: Yup.mixed().required('Required'),
  businessEntityId: Yup.mixed().required('Required'),
  contactId: Yup.mixed().required('Required'),
  validUntil: Yup.date().required('Required'),
  // description: Yup.string().nullable().notRequired(),
  currency: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
  discount: Yup.number().nullable(),
  taxRate: Yup.string().required('Required'),
  terms: Yup.array().nullable(),
  remarks: Yup.string().nullable().notRequired(),
  lineItems: Yup.array()
    .of(
      Yup.object().shape({
        serviceType: Yup.string().required('Required'),
        serviceTypeItem: Yup.string().required('Required'),
        unitPrice: Yup.string().required('Required'),
        quantity: Yup.string().required('Required'),
        uom: Yup.string().required('Required'),
        description: Yup.string().nullable().notRequired(),
      }),
    )
    .min(1, 'At least one item is required'),
  pickUpPort: Yup.mixed().nullable().notRequired(),
  deliveryPort: Yup.mixed().nullable().notRequired(),
  pickupAddress: Yup.object()
    .shape({
      fullAddress: Yup.string().nullable().notRequired(),
      street: Yup.string().nullable().notRequired(),
      country: Yup.string().nullable().notRequired(),
      zipCode: Yup.string().nullable().notRequired(),
      city: Yup.string().nullable().notRequired(),
    })
    .test(
      'all-or-none',
      'If one field is filled, all fields must be filled.',
      (value: any) => {
        const { fullAddress, street, country, zipCode, city } = value || {}

        const isAnyFieldFilled =
          fullAddress || street || country || zipCode || city
        const areAllFieldsFilled =
          fullAddress && street && country && zipCode && city

        return !isAnyFieldFilled || areAllFieldsFilled
      },
    )
    .notRequired(),
  deliveryAddress: Yup.object()
    .shape({
      fullAddress: Yup.string().nullable().notRequired(),
      street: Yup.string().nullable().notRequired(),
      country: Yup.string().nullable().notRequired(),
      zipCode: Yup.string().nullable().notRequired(),
      city: Yup.string().nullable().notRequired(),
    })
    .test(
      'all-or-none',
      'If one field is filled, all fields must be filled.',
      (value: any) => {
        const { fullAddress, street, country, zipCode, city } = value || {}

        const isAnyFieldFilled =
          fullAddress || street || country || zipCode || city
        const areAllFieldsFilled =
          fullAddress && street && country && zipCode && city

        return !isAnyFieldFilled || areAllFieldsFilled
      },
    )
    .notRequired(),
  packages: Yup.array().of(packageShape).nullable(),
})
// .test('packages-qty', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       if (!pkg.qty) {
//         return createError({
//           path: `packages[${i}].qty`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test('packages-dimensions.width', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       // dimensions
//       if (!pkg.dimensions.width) {
//         return createError({
//           path: `packages[${i}].dimensions.width`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test('packages-dimensions.height', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       if (!pkg.dimensions.height) {
//         return createError({
//           path: `packages[${i}].dimensions.height`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test('packages-dimensions.length', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       if (!pkg.dimensions['length']) {
//         return createError({
//           path: `packages[${i}].dimensions.length`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test('packages-dimensions.unit', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       if (!pkg.dimensions.unit) {
//         return createError({
//           path: `packages[${i}].dimensions.unit`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test(
//   'packages-dimensions.weight.value',
//   '',
//   (obj: any, testContext): any => {
//     const { packages, lineItems } = obj
//     const { createError } = testContext

//     if (isSomeLineItemFMorPL(lineItems)) {
//       for (let i = 0; i < packages.length; i++) {
//         const pkg = packages[i]

//         // weight
//         if (!pkg.weight.value) {
//           return createError({
//             path: `packages[${i}].weight.value`,
//             message: 'Required',
//           })
//         }
//       }
//     }
//     return true // Validation is successful
//   },
// )
// .test('packages-dimensions.weight.unit', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       // weight

//       if (!pkg.weight.unit) {
//         return createError({
//           path: `packages[${i}].weight.unit`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test(
//   'incoTerm-conditional-validation',
//   'Incoterm is required',
//   (obj: any) => {
//     const shouldShowError =
//       isSomeLineItemFMorPL(obj?.lineItems) && !obj?.incoTerm

//     if (shouldShowError) {
//       throw new ValidationError('Incoterm is required', null, 'incoTerm')
//     }
//     return true
//   },
// )
// .test(
//   'incoTerm-conditional-validation',
//   'Incoterm is required',
//   (obj: any) => {
//     const shouldShowError =
//       isSomeLineItemFMorPL(obj?.lineItems) && !obj?.incoTerm

//     if (shouldShowError) {
//       throw new ValidationError('Incoterm is required', null, 'incoTerm')
//     }
//     return true
//   },
// )
// .test(
//   'pickup-validation',
//   'Either loading port or loading address is required',
//   (obj) => {
//     if (   !obj.pickUpPort &&
//       areFieldsEmptyOrNullOrUndefined(obj.pickupAddress) &&
//       obj.mot === 'Sea'
//     ) {
//       throw new ValidationError(
//         'Either loading port or loading address is required',
//         null,
//         'pickUpPort',
//       )
//     }
//     return true
//   },
// )
// .test(
//   'discharge-validation',
//   'Either discharge port or discharge address is required',
//   (obj) => {
//     if (   !obj.deliveryPort &&
//       areFieldsEmptyOrNullOrUndefined(obj.deliveryAddress) &&
//       obj.mot === 'Sea'
//     ) {
//       throw new ValidationError(
//         'Either discharge port or discharge address is required',
//         null,
//         'deliveryPort',
//       )
//     }
//     return true
//   },
// )

export const validationSchema = Yup.object().shape({
  customerId: Yup.mixed().required('Required'),
  businessEntityId: Yup.string().required('Required'),
  contactId: Yup.mixed().required('Required'),
  validUntil: Yup.date()
    .min(today, 'Date should be today or in the future')
    .required('Required'),
  // mot: Yup.string().required('Required'),
  description: Yup.string().nullable().notRequired(),
  currency: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
  discount: Yup.number().required('Required'),
  taxRate: Yup.string().required('Required'),
  terms: Yup.array().of(Yup.string()).nullable(), //.min(1, 'At least one item is required'),
  remarks: Yup.string().nullable().notRequired(),
  lineItems: Yup.array()
    .of(
      Yup.object().shape({
        serviceType: Yup.string().required('Required'),
        serviceTypeItem: Yup.string().required('Required'),
        unitPrice: Yup.string().required('Required'),
        quantity: Yup.string().required('Required'),
        uom: Yup.string().required('Required'),
        description: Yup.string().nullable().notRequired(),
      }),
    )
    .min(1, 'At least one item is required'),
  pickUpPort: Yup.mixed().nullable().notRequired(),
  deliveryPort: Yup.mixed().nullable().notRequired(),
  pickupAddress: Yup.object().shape({
    fullAddress: Yup.string().nullable().notRequired(),
    street: Yup.string().nullable().notRequired(),
    country: Yup.string().nullable().notRequired(),
    zipCode: Yup.string().nullable().notRequired(),
    description: Yup.string().nullable().notRequired(),
  }),
  deliveryAddress: Yup.object().shape({
    fullAddress: Yup.string().nullable().notRequired(),
    street: Yup.string().nullable().notRequired(),
    country: Yup.string().nullable().notRequired(),
    zipCode: Yup.string().nullable().notRequired(),
    description: Yup.string().nullable().notRequired(),
  }),
  packages: Yup.array().of(packageShape).nullable(),
})
// .test('packages-qty', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       if (!pkg.qty) {
//         return createError({
//           path: `packages[${i}].qty`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test('packages-dimensions.width', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       // dimensions
//       if (!pkg.dimensions.width) {
//         return createError({
//           path: `packages[${i}].dimensions.width`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test('packages-dimensions.height', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       if (!pkg.dimensions.height) {
//         return createError({
//           path: `packages[${i}].dimensions.height`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test('packages-dimensions.length', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       if (!pkg.dimensions['length']) {
//         return createError({
//           path: `packages[${i}].dimensions.length`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test('packages-dimensions.unit', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       if (!pkg.dimensions.unit) {
//         return createError({
//           path: `packages[${i}].dimensions.unit`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test(
//   'packages-dimensions.weight.value',
//   '',
//   (obj: any, testContext): any => {
//     const { packages, lineItems } = obj
//     const { createError } = testContext

//     if (isSomeLineItemFMorPL(lineItems)) {
//       for (let i = 0; i < packages.length; i++) {
//         const pkg = packages[i]

//         // weight
//         if (!pkg.weight.value) {
//           return createError({
//             path: `packages[${i}].weight.value`,
//             message: 'Required',
//           })
//         }
//       }
//     }
//     return true // Validation is successful
//   },
// )
// .test('packages-dimensions.weight.unit', '', (obj: any, testContext): any => {
//   const { packages, lineItems } = obj
//   const { createError } = testContext

//   if (isSomeLineItemFMorPL(lineItems)) {
//     for (let i = 0; i < packages.length; i++) {
//       const pkg = packages[i]

//       // weight

//       if (!pkg.weight.unit) {
//         return createError({
//           path: `packages[${i}].weight.unit`,
//           message: 'Required',
//         })
//       }
//     }
//   }
//   return true // Validation is successful
// })
// .test(
//   'incoTerm-conditional-validation',
//   'Incoterm is required',
//   (obj: any) => {
//     const shouldShowError =
//       isSomeLineItemFMorPL(obj?.lineItems) && !obj?.incoTerm

//     if (shouldShowError) {
//       throw new ValidationError('Incoterm is required', null, 'incoTerm')
//     }
//     return true
//   },
// )

// .test(
//   'incoTerm-conditional-validation',
//   'Incoterm is required',
//   (obj: any) => {
//     const shouldShowError =
//       isSomeLineItemFMorPL(obj?.lineItems) && !obj?.incoTerm

//     if (shouldShowError) {
//       throw new ValidationError('Incoterm is required', null, 'incoTerm')
//     }
//     return true
//   },
// )
// .test(
//   'pickup-validation',
//   'Either loading port or loading address is required',
//   (obj) => {
//     if (
//       !obj.pickUpPort &&
//       !areFieldsEmptyOrNullOrUndefined(obj.pickupAddress)
//     ) {
//       throw new ValidationError(
//         'Either loading port or loading address is required',
//         null,
//         'pickUpPort',
//       )
//     }
//     return true
//   },
// )
// .test(
//   'discharge-validation',
//   'Either discharge port or discharge address is required',
//   (obj) => {
//     if (
//       !obj.deliveryPort &&
//       !areFieldsEmptyOrNullOrUndefined(obj.deliveryAddress)
//     ) {
//       throw new ValidationError(
//         'Either discharge port or discharge address is required',
//         null,
//         'deliveryPort',
//       )
//     }
//     return true
//   }
// )

// export const applicableTaxesOptions = ['0% - Not Applicable', '5%', '6%', '21%']

export enum Currencies {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  SGD = 'SGD',
  MYR = 'MYR',
  AED = 'AED',
}

export enum Taxes {
  '0% - Not Applicable' = 0,
  '5%' = 0.05,
  '6%' = 0.06,
  '20%' = 0.2,
  '21%' = 0.21,
}

export enum ContainerType {
  '20 DV' = "20' DV",
  '20 Flat Rack' = "20' Flat Rack",
  '20 Reefer' = "20' Reefer",
  '20 Open Top' = "20' Open Top",
  '40 DV' = "40' DV",
  '40 Flat Rack' = "40' Flat Rack",
  '40 HQ' = "40' HQ",
  '40 Reefer' = "40' Reefer",
  '45 HQ' = "45' HQ",
  '40 Open Top' = "40' Open Top",
}

export enum Incoterm {
  EXW = 'EXW - Ex Works',
  FCA = 'FCA - Free Carrier',
  CPT = 'CPT - Carriage Paid To',
  CIP = 'CIP - Carriage Insurance Paid To',
  DAP = 'DAP - Delivered At Place',
  DPU = 'DPU - Delivered at Place Unloaded',
  DDP = 'DDP - Delivered Duty Paid',
  FAS = 'FAS - Free Alongside Ship',
  FOB = 'FOB - Free On Board',
  CFR = 'CFR - Cost And Freight',
  CIF = 'CIF - Cost, Insurance and Freight',
  DAT = 'DAT - Delivered At Terminal',
}

export enum PriceType {
  FCL = 'FCL',
  LCL = 'LCL',
  FTL = 'FTL',
  LTL = 'LTL',
}

export enum MOT {
  air = 'Air',
  sea = 'Sea',
  road = 'Road',
  rail = 'Rail',
  parcel = 'Parcel',
}

export enum MeasureUnit {
  mm = 'mm',
  cm = 'cm',
  m = 'm',
  in = 'in',
  f = 'ft',
}

export enum WeightUnit {
  kg = 'kg',
  lb = 'lb',
  mt = 'MT',
}

export const CurrenciesOptions = convertEnumToOptions(Currencies)
export const TaxesOptions = convertEnumToOptions(Taxes)
export const ContainerTypeOptions = convertEnumToOptions(ContainerType, true)
export const IncotermOptions = convertEnumToOptions(Incoterm)
export const PriceTypeOptions = convertEnumToOptions(PriceType)
export const MOTOptions = convertEnumToOptions(MOT, true)

export const MeasureUnitOptions = convertEnumToOptions(MeasureUnit)
export const WeightUnitOptions = convertEnumToOptions(WeightUnit, true)

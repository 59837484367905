/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import type { Page as PageType } from 'src/common/types/page'
import { useRouter } from 'src/common/hooks/use-router'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import InvoicesList from './list'

const InvoicesOverview: PageType = () => {
  const router = useRouter()

  return (
    <Box
      component="main"
      sx={{
        py: 4,
      }}
    >
      <Container maxWidth="xl" sx={{}}>
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Invoices</Typography>
            </Stack>
            <Stack>
              <Button
                startIcon={
                  <SvgIcon>
                    <LocalPrintshopIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => router.push('/invoices/print')}
                id="invoices-print"
              >
                Batch print
              </Button>
            </Stack>
            {/* <Stack alignItems="center" direction="row" spacing={3}>
              <Button
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => openModal('create-invoice-modal', true)}
              >
                Create new invoice
              </Button>

              <CreateModal
                key="create-invoice-modal"
                handleClose={() => closeModal('create-invoice-modal')}
                open={
                  !!modals.find((x) => x.id == 'create-invoice-modal')?.open
                }
              />
            </Stack> */}
          </Stack>
          <Card>
            <Box p={1}>
              <InvoicesList />
            </Box>
          </Card>
        </Stack>
      </Container>
      {/* <CreateModal
        handleClose={() => closeModal('create-invoice-modal')}
        open={!!modals.find((x) => x.id === 'create-invoice-modal')?.open}
      /> */}
    </Box>
  )
}

export default InvoicesOverview

import React from 'react'
import type { FC } from 'react'
import { useSettings } from '../hooks/use-settings'

type logoProps = {
  inputColor?: string
}

export const Logo: FC<logoProps> = ({ inputColor }) => {
  const settings = useSettings()

  const color =
    inputColor !== undefined && inputColor !== ''
      ? inputColor
      : (settings.paletteMode === 'light' && settings.navColor === 'evident') ||
        settings.paletteMode === 'dark'
      ? '#ffffff'
      : '#012F53'

  return (
    <svg
      width="100%"
      height=""
      viewBox="0 0 300 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.476562 34.7215C0.489501 34.7215 0.489501 34.7215 0.489501 34.7215C6.49667 38.0719 11.8803 39.5365 16.8092 39.6444C21.7375 39.7523 26.2438 38.5045 30.4983 36.382C37.8487 32.715 44.398 26.4706 51.0391 20.1386C52.1154 19.1125 53.194 18.084 54.2788 17.0639C62.0172 9.7872 70.0664 2.94211 79.7014 0.737664C89.3844 -1.47775 100.55 1.01245 114.477 12.1068C101.252 8.01517 90.5961 11.1384 82.3817 16.0972C75.8822 20.0206 70.3733 25.6193 64.907 31.1746C63.4219 32.6839 61.9399 34.1901 60.4421 35.6585C53.468 42.4958 46.1605 48.497 36.7057 49.7903C27.2362 51.0857 15.7998 46.4169 0.489501 34.7215H0.476562Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49 45.1379C49.0173 45.1379 49.0173 45.1379 49.0173 45.1379C57.0268 49.4935 64.2049 51.3975 70.7768 51.5377C77.3479 51.6779 83.3563 50.0558 89.029 47.2966C98.8295 42.5295 107.562 34.4117 116.417 26.1802C117.852 24.8462 119.29 23.5092 120.736 22.1831C131.054 12.7234 141.786 3.82474 154.633 0.958963C167.544 -1.92107 182.432 1.31619 201 15.7388C183.368 10.4197 169.159 14.48 158.207 20.9263C149.541 26.0268 142.196 33.305 134.907 40.527C132.927 42.489 130.951 44.4471 128.954 46.3561C119.655 55.2445 109.912 63.0461 97.3055 64.7274C84.6795 66.4114 69.431 60.3419 49.0173 45.1379H49Z"
        fill="#00ACEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118 55.5543C118.022 55.5543 118.022 55.5543 118.022 55.5543C128.034 60.915 137.006 63.2584 145.221 63.431C153.435 63.6036 160.945 61.6071 168.036 58.2112C180.287 52.3441 191.202 42.3529 202.271 32.2217C204.065 30.5799 205.862 28.9345 207.67 27.3023C220.568 15.6595 233.983 4.70738 250.041 1.18026C266.18 -2.3644 284.79 1.61993 308 19.3709C285.96 12.8243 268.199 17.8215 254.509 25.7555C243.676 32.033 234.495 40.9908 225.384 49.8794C222.909 52.2942 220.439 54.7042 217.942 57.0536C206.319 67.9932 194.14 77.5952 178.382 79.6646C162.599 81.7371 143.539 74.267 118.022 55.5543H118Z"
        fill="#9FC54C"
      />
    </svg>
  )
}

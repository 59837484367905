import { tokens } from '../tokens'

export const en = {
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.account]: 'Account',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'Auth',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Concepts',
  [tokens.nav.contact]: 'Contact',
  [tokens.nav.course]: 'Course',
  [tokens.nav.create]: 'Create',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customers]: 'Customers',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.appSettings]: 'App Settings',
  [tokens.nav.details]: 'Details',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.edit]: 'Edit',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.files]: 'Files',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.invoices]: 'Invoices',
  [tokens.nav.fleet]: 'Fleet',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.invoiceList]: 'Invoices',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'List',
  [tokens.nav.login]: 'Login',
  [tokens.nav.logistics]: 'Logistics',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.orderList]: 'Orders',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.pages]: 'Pages',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.register]: 'Register',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.socialMedia]: 'Social Media',
  [tokens.nav.verifyCode]: 'Verify Code',
  [tokens.nav.users]: 'Users',
  [tokens.nav.usersRolesPermissions]: 'Users, Roles & Permissions',
  [tokens.nav.usersRoles]: 'Roles',
  [tokens.nav.usersPermissions]: 'Permissions',
  [tokens.nav.sales]: 'Sales',
  [tokens.nav.leads]: 'Leads',
  [tokens.nav.opportunities]: 'Opportunities',
  [tokens.nav.contacts]: 'Contacts',
  [tokens.nav.crm]: 'CRM',
  [tokens.nav.roles]: 'Roles',
  [tokens.nav.termsAndConditions]: 'Terms And Conditions',
  [tokens.nav.seaports]: 'Seaports',
  [tokens.nav.airports]: 'Airports',
  [tokens.nav.currencies]: 'Currencies',
  [tokens.nav.entities]: 'Business Entities',
  [tokens.nav.quotes]: 'Quotes',
  [tokens.nav.contracts]: 'Contracts',
  [tokens.nav.miscellaneous]: 'Miscellaneous',
  [tokens.nav.operations]: 'Operations',
  [tokens.nav.carriers]: 'Carriers',
  [tokens.nav.purchaseOrders]: 'Purchase Orders',
  [tokens.nav.shipments]: 'Shipments',
}

import React from 'react'
import { MobileNav } from '../navigation/mobile-nav'
import { SideNav } from './side-nav'
import { TopNav } from './top-nav'
import { useMobileNav } from './use-mobile-nav'
import type { Section } from '../navigation/menuConfig'
import type { NavColor } from '../../common/types/Settings'
import type { Theme } from '@mui/material'
import { useMediaQuery } from '@mui/material'

interface NavigationProps {
  navColor?: NavColor
  sections?: Section[]
}

export const NavigationBars: React.FC<NavigationProps> = ({
  navColor,
  sections,
}) => {
  const mobileNav = useMobileNav()
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  return (
    <>
      <TopNav onMobileNavOpen={mobileNav.handleOpen} />
      {lgUp && <SideNav color={navColor} sections={sections} />}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
    </>
  )
}

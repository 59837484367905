import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'
import PreviewQuote from 'src/modules/quotes/preview/index'

const QuotesOverviewPage = lazy(
  () => import('src/pages/backoffice/Quotes/overview'),
)
const EditQuote = lazy(() => import('../../../modules/quotes/details'))
const CloneQuote = lazy(
  () => import('../../../modules/quotes/details-clone-quote'),
)

export const quotesRoutes: CustomRouteObject[] = [
  {
    path: 'quotes',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <QuotesOverviewPage />,
        scopes: ['quote.read', 'quote.write'],
      },
      {
        path: 'details/:quoteId',
        element: <EditQuote />,
        scopes: ['quote.write'],
      },
      {
        path: 'clone/:quoteId',
        element: <CloneQuote />,
        scopes: ['quote.write'],
      },
      {
        path: 'create',
        element: <EditQuote />,
        scopes: ['quote.write'],
      },
      {
        path: 'view/:quoteId',
        element: <PreviewQuote />,
        scopes: ['quote.read', 'quote.write'],
      },
    ],
  },
]

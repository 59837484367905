import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { INDEXES } from 'src/common/apis/constants'
import { get, post, postDelete, put } from 'src/common/utils/api'

interface SubmitValues {
  id?: string
  name: string
  email: string
  phone: string
  customerId?: string
}

interface muataionProp {
  callback?: (data: object) => void
}

export const useCreateContactMutation = ({ callback }: muataionProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => post('crm/contacts', values, setFieldError),
    onSuccess: (data, variables) => {
      toast.success('Success! Contact was created')
      queryClient.invalidateQueries([INDEXES.CONTACT])
      callback && callback({ ...data, ...variables })
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (data: object) => {
      toast.error('Contact could not be created! Please try again')
      // callback && callback(data)
    },
  })
}

export const useGetContactDetails = (id: string) => {
  return useQuery({
    enabled: !!id,
    queryKey: ['contacts', id],
    queryFn: () => get(`crm/contacts/${id}`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      return {
        ...data,
        customerId: {
          ...data?.customerId,
          value: data.customerId?.id,
          label: data.customerId?.name,
        },
      }
    },
  })
}

export const useUpdateContactMutation = ({ callback }: muataionProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => put(`crm/contacts/${values.id}`, values, setFieldError),
    onSuccess: (data) => {
      toast.success('Success! Contact was updated')
      queryClient.invalidateQueries([INDEXES.CONTACT])
      callback && callback(data)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: (data: object) => {
      toast.error('Contact could not be updated! Please try again')
      // callback && callback(data)
    },
  })
}

export const useDeleteContactMutation = ({ callback }: muataionProp) => {
  const queryClient = useQueryClient()
  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: ({ contactId }: any) => postDelete(`crm/contacts/${contactId}`),
    onSuccess: (data) => {
      toast.success('Success! Contact was deleted')
      queryClient.invalidateQueries([INDEXES.CONTACT])
      callback && callback(data)
    },
    onError: (data: object) => {
      toast.error('Contact could not be deleted! Please try again')
      callback && callback(data)
    },
  })
}

import * as Yup from 'yup'

export const initialValues = {
  mot: null,
  invoiceDate: '',
  conversionRate: 1,
  dueDate: '',
  discount: 0,
  lineItems: [
    {
      description: null,
      quantity: null,
      serviceType: null,
      serviceTypeItem: null,
      unitPrice: null,
      uom: null,
    },
  ],
}

export const validationSchema = Yup.object().shape({
  taxRate: Yup.string().required('Tax rate is required'),
  customerId: Yup.mixed().required('Cutomer is required'),
  businessEntityId: Yup.mixed().required('Business Entity is required'),
  currency: Yup.string().required('Currency is required'),
  dueDate: Yup.string().nullable(),
  invoiceDate: Yup.string().nullable(),
  mot: Yup.string()
    .nullable()
    .test(
      'is-required',
      'MOT is required when one line item is TRANSPORT',
      function (value) {
        const { lineItems } = this.parent
        if (!lineItems) return true
        const hasTransport = lineItems.some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (x: any) => x.serviceType === 'transport',
        )

        if (hasTransport) {
          return !!value // Ensure the value is not null or undefined
        }
        return true
      },
    ),
  lineItems: Yup.array()
    .of(
      Yup.object().shape({
        serviceType: Yup.string().required('Required'),
        serviceTypeItem: Yup.string().required('Required'),
        unitPrice: Yup.string().required('Required'),
        uom: Yup.string().required('Required'),
        quantity: Yup.string().required('Required'),
        description: Yup.string().nullable().notRequired(),
      }),
    )
    .min(1, 'At least one item is required'),
})

export const editValidationSchema = Yup.object()

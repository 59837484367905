/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useCallback, useEffect } from 'react'

// MUI Icons
import PrintIcon from '@mui/icons-material/Print'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined'
import CloseIcon from '@mui/icons-material/Close'

// MUI Components
import {
  Box,
  Chip,
  FormControl,
  Grid,
  LinearProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
// Common Components
import RenderField from 'src/common/components/form/RenderField'
import StaticAutocomplete from 'src/common/components/autocomplete/common/autocomplete-with-create'
import TotalWithDifferentCurrency from 'src/common/components/TotalWithDifferentCurrency'
import { Reference } from 'src/common/components/reference'
import { STATUS, Status, StatusKey } from 'src/common/components/statuses'
import Button from 'src/common/components/loadingButton'

// Tanstack Table
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
  RowData,
} from '@tanstack/react-table'

// Tanstack React Query
import { useInfiniteQuery } from '@tanstack/react-query'

// Tanstack React Virtual
import { useVirtualizer } from '@tanstack/react-virtual'

// Utilities
import { infiniteGet } from 'src/common/utils/api'
import { format, subDays, startOfWeek, endOfWeek } from 'date-fns'
import { useFormik } from 'formik'

// Custom Hooks
import { useBatchPrintMutation } from './useRequests'

// Types
import { Invoice } from './types'
import { NoRecordsFound } from 'src/common/components/no-records'

type CustomColumnDef<TData extends RowData> = ColumnDef<TData> & {
  align?: 'left' | 'right' | 'center'
}

const SortingIconsWrapper = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}))

const SortBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  marginLeft: '8px',
  maxWidth: '20px',
})

const StyledArrowIcon = styled('div')({
  cursor: 'pointer',
})

const StyledArrowUpIcon = styled(KeyboardArrowUpIcon)({
  marginBottom: '-5px',
  cursor: 'pointer',
})
interface Response {
  data: any[]
  totalRowCount: number
  totalFilteredItems?: number
  totalPages?: number
  currentPage?: number
  useSearch?: boolean
}

const fetchSize = 100

function BatchPrintTable() {
  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const [sorting, setSorting] = React.useState<SortingState>([
    { id: 'invoiceDate', desc: true },
  ])

  const columns: CustomColumnDef<Invoice>[] = React.useMemo(
    () => [
      {
        accessorKey: 'invoiceDate',
        header: 'Invoice date',
        cell: (info: { getValue: () => any }) => info.getValue()?.split('T')[0],
        enableSorting: false,
        size: 200,
      },
      {
        accessorKey: 'reference',
        header: 'Invoice no.',
        cell: (info: { getValue: () => any }) =>
          info?.getValue() && (
            <Reference type="invoice" reference={info?.getValue()} hideTitle />
          ),
        enableSorting: false,
        size: 200,
      },

      {
        header: 'Status',
        cell: (row: any) => (
          <>
            <Status
              key={row.getValue().status}
              status={STATUS[row.getValue().status as StatusKey]}
            />
          </>
        ),
        enableSorting: false,
        accessorFn: (row: any): { status: string } => ({
          status: row.status,
        }),
        size: 200,
      },
      {
        accessorKey: 'customer.name',
        header: 'Customer',
        cell: (info: { getValue: () => any }) => info.getValue(),
        enableSorting: false,
        size: 400,
      },

      {
        accessorKey: 'dueDate',
        header: 'Due Date',
        cell: (info: { getValue: () => any }) => info.getValue()?.split('T')[0],
        enableSorting: false,
        // size: 200,
      },
      {
        header: 'Subtotal',
        cell: (row: any) => {
          const { subtotal, conversionRate } = row.getValue()

          return (
            <TotalWithDifferentCurrency
              total={subtotal}
              invoiceCurrency={row.getValue().currency}
              conversionRate={conversionRate}
            />
          )
        },
        enableSorting: false,
        accessorFn: (
          row: any,
        ): { subtotal: string; currency: string; conversionRate: number } => ({
          subtotal: row.subtotal,
          conversionRate: row.conversionRate,
          currency: row.currency,
        }),
        // size: 200,
        align: 'right',
      },
      {
        header: 'Total',
        align: 'right',
        cell: (row: any) => {
          const { total, conversionRate } = row.getValue()
          return (
            <TotalWithDifferentCurrency
              total={total}
              invoiceCurrency={row.getValue().currency}
              conversionRate={conversionRate}
            />
          )
        },
        enableSorting: false,
        accessorFn: (
          row: any,
        ): { total: string; currency: string; conversionRate: number } => ({
          total: row.total,
          currency: row.currency,
          conversionRate: row.conversionRate,
        }),
        // size: 200,
      },
      {
        accessorKey: 'isPrinted',
        header: 'Printed',
        cell: (info: { getValue: () => any }) =>
          info.getValue() ? (
            <CheckCircleOutlineOutlinedIcon color="success" />
          ) : (
            <UnpublishedOutlinedIcon color="disabled" />
          ),
        enableSorting: false,
        size: 150,
      },
    ],
    [],
  )
  const formik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      isPrinted: 'no',
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit: (_values) => {
      // console.log(values)
    },
  })

  const queryKey = ['invoices', sorting, formik.values]
  const activeOfficeId = localStorage.getItem('activeOfficeId')

  const { data, fetchNextPage, isFetching, isLoading } =
    useInfiniteQuery<Response>(
      queryKey,
      async ({ pageParam }) => {
        const filters = formik.values
        const start = (pageParam as number) * fetchSize
        const filterStatus = `&status=eq:finalized`
        const officeFilter = `&office=eq:${activeOfficeId}`

        const filterStartDate = filters?.startDate
          ? `&invoiceDate=gte:${format(filters?.startDate, 'yyyy-MM-dd')}`
          : ''
        let filterEndDate = filters?.endDate
          ? `:and:lte:${format(filters?.endDate, 'yyyy-MM-dd')}`
          : ''

        if (filters?.endDate && !filters?.startDate) {
          filterEndDate = `&invoiceDate=lt:${format(
            filters?.endDate,
            'yyyy-MM-dd',
          )}`
        }

        let filterIsPrinted = ''

        if (filters?.isPrinted === 'yes') filterIsPrinted = `&isPrinted=eq:1`
        if (filters?.isPrinted === 'no')
          filterIsPrinted = `&isPrinted=eq:0:or:is:null`

        const filterAllParam =
          filterStatus +
          filterStartDate +
          filterEndDate +
          filterIsPrinted +
          officeFilter

        return infiniteGet(
          'finance/invoices',
          start,
          fetchSize,
          sorting.length ? sorting[0] : null,
          filterAllParam,
          '',
          false,
        )
      },
      {
        getNextPageParam: (lastPage) => {
          if (!lastPage.currentPage) return 1
          if (lastPage.currentPage < (lastPage.totalPages || 0))
            return lastPage.currentPage + 1
          return false
        },

        // Keep the previous data while fetching new data to avoid UI glitches.
        keepPreviousData: true,
        // Disable refetching data when the window gets focus.
        refetchOnWindowFocus: true,
      },
    )

  //flatten the array of arrays from the useInfiniteQuery hook
  const flatData = React.useMemo(
    () => data?.pages?.flatMap((page) => page.data) ?? [],
    [data],
  )

  const totalFilteredItems = data?.pages?.[0]?.totalFilteredItems ?? 0
  const totalFetched = flatData.length
  const totalPages = data?.pages?.[0]?.totalPages ?? 0
  const currentPage = data?.pages?.[0]?.currentPage ?? 0

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const refData = containerRefElement
        const { scrollHeight, scrollTop, clientHeight } = refData

        if (
          scrollHeight - scrollTop - clientHeight < 200 &&
          !isFetching &&
          totalFetched < totalFilteredItems &&
          currentPage &&
          currentPage < totalPages
        ) {
          fetchNextPage()
        }
      }
    },
    [
      isFetching,
      totalFetched,
      totalFilteredItems,
      currentPage,
      totalPages,
      fetchNextPage,
    ],
  )

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current)
  }, [fetchMoreOnBottomReached])

  const batchPrintMutation = useBatchPrintMutation({
    callback: () => {
      // console.log(values)
    },
  })
  const handleBatchPrint = async () => {
    const invoices = flatData.map((x: any) => x.id)

    batchPrintMutation.mutate({
      values: invoices,
    })
  }

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    debugTable: true,
  })

  //scroll to top of table when sorting changes
  const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
    setSorting(updater)
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex?.(0)
    }
  }

  //since this table option is derived from table row model state, we're using the table.setOptions utility
  table.setOptions((prev) => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }))

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 61, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  })

  const virtualRows = rowVirtualizer.getVirtualItems()
  const totalSize = rowVirtualizer.getTotalSize()
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0

  const getSortingColor = (id: string, orientation: 'desc' | 'asc') => {
    if (sorting?.length > 0) {
      const sortedItem = sorting.find((x: any) => x.id === id)
      if (sortedItem) {
        if (orientation === 'desc') {
          return sortedItem?.desc ? 'primary' : 'disabled'
        }
        if (orientation === 'asc') {
          return !sortedItem?.desc ? 'primary' : 'disabled'
        }
      }
    }
    return 'disabled'
  }
  type rangeTypes = 'Today' | 'Yesterday' | 'Last Week'
  const handleDateRangeClick = (range: rangeTypes) => {
    const today = new Date()
    let startDate, endDate

    switch (range) {
      case 'Today':
        startDate = today
        endDate = today
        break
      case 'Yesterday':
        startDate = subDays(today, 1)
        endDate = subDays(today, 1)
        break
      case 'Last Week':
        startDate = startOfWeek(subDays(today, 6))
        endDate = endOfWeek(subDays(today, 7))
        break
      default:
        startDate = null
        endDate = null
    }

    formik.setFieldValue('startDate', startDate)
    formik.setFieldValue('endDate', endDate)
  }
  const isChipActive = (range: rangeTypes) => {
    const today = new Date()
    let startDate: Date
    let endDate: Date

    switch (range) {
      case 'Today':
        startDate = today
        endDate = today
        break
      case 'Yesterday':
        startDate = subDays(today, 1)
        endDate = subDays(today, 1)
        break
      case 'Last Week':
        startDate = startOfWeek(subDays(today, 7))
        endDate = endOfWeek(subDays(today, 7))
        break
      default:
        startDate = subDays(today, 1000)
        endDate = subDays(today, 1000)
    }
    const formikStartDate = formik.values.startDate
      ? format(formik.values.startDate, 'yyyy-MM-dd')
      : ''
    const formikEndDate = formik.values.endDate
      ? format(formik.values.endDate, 'yyyy-MM-dd')
      : ''
    const formatStartDate = format(startDate, 'yyyy-MM-dd')

    const formatEndDate = format(endDate, 'yyyy-MM-dd')

    return (
      formikStartDate === formatStartDate && formikEndDate === formatEndDate
    )
  }
  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <div className="">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={1} my={2}>
          <Grid
            item
            direction="row"
            alignItems="center"
            display={'flex'}
            gap={2}
            sm={12}
          >
            <RenderField
              field={{
                label: 'Start Date',
                type: 'datePicker',
                name: 'startDate',
              }}
              maxDate={formik.values.endDate}
              formik={formik}
              sx={{ flexBasis: '25%' }}
            />
            <RenderField
              field={{
                label: 'End Date',
                type: 'datePicker',
                name: 'endDate',
              }}
              minDate={formik.values.startDate}
              formik={formik}
              sx={{ flexBasis: '25%' }}
            />
            <FormControl sx={{ flexBasis: '10%' }}>
              <StaticAutocomplete
                formik={formik}
                options={[
                  { id: 'yes', label: 'Printed' },
                  { id: 'no', label: 'Not Printed' },
                ].map((x) => ({ ...x, value: x.id }))}
                name={'isPrinted'}
                label={'Is Printed'}
                allowCreate={false}
              />
            </FormControl>
            <Button
              variant="text"
              startIcon={<CloseIcon />}
              onClick={() => formik.resetForm()}
            >
              Clear
            </Button>
            <Box sx={{ marginLeft: 'auto' }}>
              <Button
                variant="contained"
                onClick={handleBatchPrint}
                startIcon={<PrintIcon />}
                color="error"
                isLoading={batchPrintMutation.isLoading}
                disabled={totalFetched === 0}
              >
                Print
              </Button>
            </Box>
          </Grid>
          <Grid item display={'flex'} gap={1}>
            <Chip
              size="small"
              label="Today"
              color={isChipActive('Today') ? 'secondary' : 'default'}
              sx={{ width: '90px' }}
              onClick={() => handleDateRangeClick('Today')}
            />
            <Chip
              size="small"
              label="Yesterday"
              color={isChipActive('Yesterday') ? 'secondary' : 'default'}
              sx={{ width: '90px' }}
              onClick={() => handleDateRangeClick('Yesterday')}
            />
            <Chip
              size="small"
              label="Last Week"
              color={isChipActive('Last Week') ? 'secondary' : 'default'}
              sx={{ width: '90px' }}
              onClick={() => handleDateRangeClick('Last Week')}
            />
          </Grid>
        </Grid>
      </form>
      {(isLoading || isFetching) && <LinearProgress />}
      <TableContainer
        component={Paper}
        ref={tableContainerRef}
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        data-testid={queryKey}
        style={{
          height: '620px',
          overflow: 'auto',
          position: 'relative',
          width: '100%',
        }}
      >
        {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <Table sx={{ display: 'grid' }}>
          <TableHead
            sx={{ display: 'grid', position: 'sticky', top: 0, zIndex: 1 }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                sx={{ display: 'flex', width: '100%' }}
              >
                {headerGroup.headers.map((header: any) => (
                  <TableCell
                    key={header.id}
                    sx={{
                      display: 'flex',
                      flexGrow: header.getSize() > 30 ? 0 : 1,
                      width: header.getSize() > 30 ? header.getSize() : 'auto',
                      paddingLeft: 0,
                      cursor: header.column.getCanSort()
                        ? 'pointer'
                        : 'default',
                      alignItems: 'center',
                      justifyContent: header.column.columnDef.align || 'start',
                    }}
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}

                    <SortingIconsWrapper
                      item
                      xs={header.id === 'actions' ? 0 : 2}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {{
                        asc: (
                          <SortBox>
                            <>
                              <StyledArrowUpIcon
                                as={KeyboardArrowUpIcon}
                                onClick={() =>
                                  header.column.getCanSort() &&
                                  header.column.toggleSorting()
                                }
                                color={getSortingColor(header.id, 'asc')}
                              />
                              <StyledArrowIcon
                                as={KeyboardArrowDownIcon}
                                onClick={() =>
                                  header.column.getCanSort() &&
                                  header.column.toggleSorting()
                                }
                                color={getSortingColor(header.id, 'desc')}
                              />
                            </>
                          </SortBox>
                        ),
                        desc: (
                          <SortBox>
                            <>
                              <StyledArrowUpIcon
                                as={KeyboardArrowUpIcon}
                                onClick={() =>
                                  header.column.getCanSort() &&
                                  header.column.toggleSorting()
                                }
                                color={getSortingColor(header.id, 'asc')}
                              />
                              <StyledArrowIcon
                                as={KeyboardArrowDownIcon}
                                onClick={() =>
                                  header.column.getCanSort() &&
                                  header.column.toggleSorting()
                                }
                                color={getSortingColor(header.id, 'desc')}
                              />
                            </>
                          </SortBox>
                        ),
                      }[header.column.getIsSorted() as string] ?? null}
                    </SortingIconsWrapper>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody
            sx={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`,
              position: 'relative',
            }}
          >
            {paddingTop > 0 && (
              <TableRow>
                <TableCell style={{ height: `${paddingTop}px` }} />
              </TableRow>
            )}
            {virtualRows.length === 0 && (
              <TableRow id="no-rows">
                <Box p={8}>
                  <NoRecordsFound />
                </Box>
              </TableRow>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as any

              return (
                <TableRow
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  // onClick={() => onRowClick && onRowClick(row.original)}
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    width: '100%',
                  }}
                >
                  {row.getVisibleCells().map((cell: any) => {
                    return (
                      <TableCell
                        key={cell.id}
                        style={{
                          display: 'flex',
                          width:
                            cell.column.getSize() > 30
                              ? cell.column.getSize() - 1
                              : 'auto',
                          flexGrow: cell.column.getSize() > 30 ? 0 : 1,
                          alignItems: 'center',
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}

            {paddingBottom > 0 && (
              <TableRow>
                <TableCell style={{ height: `${paddingBottom}px` }} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Typography variant="body2">
                  Total Items: {totalFetched}
                </Typography>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {isLoading && <LinearProgress />}

      {isFetching && <div>Fetching More...</div>}
    </div>
  )
}
export default BatchPrintTable

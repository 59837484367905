import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'
import { CustomRouteObject } from '../types'
import PreviewQuote from 'src/modules/quotes/preview'

const IndexPage = lazy(() => import('src/pages/customer/Quote/index'))

export const quotesRoutes: CustomRouteObject[] = [
  {
    path: 'quotes',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: ':quoteId',
        element: <PreviewQuote />,
        scopes: ['customer.quote.read', 'customer.quote.write'],
      },
    ],
  },
]

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Button from '../loadingButton'

interface ConfirmDialogProps {
  open: boolean
  isLoading?: boolean
  disabled?: boolean
  title: string
  message: string
  buttonId: string
  type?: 'delete' | 'edit' | 'finalize' | 'unlock'
  onConfirm: () => void
  onCancel: () => void
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  isLoading,
  disabled,
  title,
  message,
  buttonId,
  onConfirm,
  onCancel,
  type,
}) => {
  title = title || 'Confirm'
  message = message || 'Are you sure?'

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
    >
      {type && (
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {type === 'delete' && (
            <ErrorOutlineIcon
              color="error"
              sx={{ fontSize: '50px', margin: '16px' }}
            />
          )}
          {type === 'finalize' && (
            <LockIcon
              color="warning"
              sx={{ fontSize: '50px', margin: '16px' }}
            />
          )}
          {type === 'unlock' && (
            <LockOpenIcon
              color="warning"
              sx={{ fontSize: '50px', margin: '16px' }}
            />
          )}
          {title}
        </DialogTitle>
      )}

      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button
          id={buttonId + '-cancel'}
          onClick={onCancel}
          color="primary"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          id={buttonId + '-confirm'}
          onClick={onConfirm}
          color={type === 'delete' ? 'error' : 'primary'}
          // autoFocus
          variant="contained"
          disabled={disabled}
          isLoading={isLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog

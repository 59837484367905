import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { ENTITIES, INDEXES } from 'src/common/apis/constants'
import { get, postFormData, putFormData } from 'src/common/utils/api'
import {
  OfficesSubmitValues as SubmitValues,
  OfficesMutationProp as MutationProp,
  OfficesCountry as Country,
} from 'src/common/types/Office'
import { transformToFormData2 } from 'src/common/utils/transformToFormData'

export const useGetCountryValues = () => {
  return useQuery([ENTITIES.COUNTRIES], {
    queryFn: () => get('common/iso/countries'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (rawData) => {
      return rawData.data.map((x: Country) => ({
        label: x.name,
        value: x.code,
        id: x.code,
      }))
    },
  })
}

export const useGetCurrencyValues = () => {
  return useQuery([ENTITIES.CURRENCIES], {
    queryFn: () => get('common/iso/currencies'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (rawData) => {
      return rawData.data.map((x: Country) => ({
        label: `(${x.code}) ${x.name}`,
        value: x.code,
        id: x.code,
      }))
    },
  })
}

export const useCreateOfficeMutation = ({ callback }: MutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      const submitValues = transformToFormData2(values)
      return postFormData('offices', submitValues, setFieldError)
    },
    onSuccess: () => {
      toast.success('Success! Office was created')
      queryClient.invalidateQueries([INDEXES.OFFICE])
      callback && callback()
    },
    onError: () => {
      toast.error('Office could not be created! Please try again ')
    },
  })
}

export const useUpdateOfficeMutation = ({ callback }: MutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      const submitValues = transformToFormData2(values)
      return putFormData(`offices/${values.id}`, submitValues, setFieldError)
    },
    onSuccess: (_data, variables) => {
      toast.success('Success! Office was updated')
      queryClient.invalidateQueries([INDEXES.OFFICE])
      const activeOffice = JSON.parse(
        localStorage.getItem('activeOffice') || '{}',
      )
      if (activeOffice?.id === variables?.values?.id) {
        activeOffice.dateFormat = variables?.values?.dateFormat
        localStorage.setItem('activeOffice', JSON.stringify(activeOffice))
      }
      callback && callback()
    },
    onError: () => {
      toast.error('Office could not be updated! Please try again ')
    },
  })
}

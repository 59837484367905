import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Typography,
  Tabs,
  Tab,
} from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { useFormik } from 'formik'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { StickyFooter } from 'src/common/components/sticky-footer'
import * as Yup from 'yup'
import RoleForm from './form/roleForm'
import { useGetRoleQuery, useUpdateRoleMutation } from './useRolesRequests'
import { paths } from 'src/navigator/paths'
import { RolesFormValues as Values } from 'src/common/types/Role'
import { useState } from 'react'

const defaultValues: Values = {
  name: '',
  permissions: [],
  submit: null,
}

const validationSchema = Yup.object({
  name: Yup.string().max(255).required('Name is required'),
  permissions: Yup.array().required('Roles is required'),
})

const EditModal = () => {
  const navigate = useNavigate()
  const { roleId = '' } = useParams()
  const quoteQuery = useGetRoleQuery(roleId || '')
  const [selectedTab, setSelectedTab] = useState(0)

  const updateMutation = useUpdateRoleMutation({
    callback: () => {
      navigate(paths.roles.index)
      formik.resetForm()
    },
  })

  const formik = useFormik({
    initialValues: quoteQuery.data || defaultValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      const submitValues = {
        name: values.name,
        permissions: values.permissions,
      }

      const submitValuesWithId = {
        ...submitValues,
        id: roleId,
      }

      updateMutation.mutate({
        values: submitValuesWithId,
        setFieldError: formik.setFieldError,
      })
    },
  })

  const handleTabChange = (_: React.ChangeEvent<object>, newValue: number) => {
    setSelectedTab(newValue)
  }

  return (
    <Box component="main" py={4}>
      <Container maxWidth="xl">
        <Stack spacing={4}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              to={paths.roles.index}
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              Roles
            </Link>
            <Typography color="text.primary">Role</Typography>
          </Breadcrumbs>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Edit Role</Typography>
            </Stack>
          </Stack>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="role type tabs"
          >
            <Tab label="Backoffice Roles" />
            <Tab label="Customer Roles" />
          </Tabs>
          <Card>
            <Box p={2}>
              {selectedTab === 0 && (
                <RoleForm formik={formik} roleType="backoffice" />
              )}
              {selectedTab === 1 && (
                <RoleForm formik={formik} roleType="customer-portal" />
              )}
            </Box>
          </Card>
        </Stack>
      </Container>
      <StickyFooter
        rightComponent={
          <>
            <Button
              id="usersRolesPermissions-roles-edit-save"
              variant="contained"
              disabled={formik.isSubmitting || updateMutation.isLoading}
              fullWidth
              onClick={async () => {
                await formik.handleSubmit()
              }}
              type="submit"
            >
              Save
            </Button>
          </>
        }
        leftComponent={
          <>
            <Button
              id="usersRolesPermissions-roles-edit-cancel"
              variant="outlined"
              onClick={() => {
                formik.resetForm()
                navigate(paths.roles.index)
              }}
            >
              Cancel
            </Button>
          </>
        }
      />
    </Box>
  )
}

export default EditModal

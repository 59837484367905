export const tokens = {
  common: {
    languageChanged: 'common.languageChanged',
  },
  nav: {
    academy: 'nav.academy',
    contacts: 'nav.contacts',
    account: 'nav.account',
    analytics: 'nav.analytics',
    auth: 'nav.auth',
    blog: 'nav.blog',
    browse: 'nav.browse',
    calendar: 'nav.calendar',
    chat: 'nav.chat',
    checkout: 'nav.checkout',
    concepts: 'nav.concepts',
    contact: 'nav.contact',
    course: 'nav.course',
    create: 'nav.create',
    crypto: 'nav.crypto',
    // customers: 'nav.customers',
    dashboard: 'nav.dashboard',
    details: 'nav.details',
    ecommerce: 'nav.ecommerce',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    finance: 'nav.finance',
    invoices: 'nav.invoices',
    fleet: 'nav.fleet',
    forgotPassword: 'nav.forgotPassword',
    invoiceList: 'nav.invoices',
    jobList: 'nav.jobList',
    kanban: 'nav.kanban',
    list: 'nav.list',
    login: 'nav.login',
    logistics: 'nav.logistics',
    mail: 'nav.mail',
    management: 'nav.management',
    orderList: 'nav.orders',
    overview: 'nav.overview',
    pages: 'nav.pages',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    productList: 'nav.products',
    profile: 'nav.profile',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    socialMedia: 'nav.socialMedia',
    verifyCode: 'nav.verifyCode',
    users: 'nav.users',
    usersRolesPermissions: 'nav.usersRolesPermissions',
    usersRoles: 'nav.usersRoles',
    usersPermissions: 'nav.usersPermissions',
    sales: 'nav.sales',
    leads: 'nav.leads',
    notifications: 'Notifications',
    purchaseOrders: 'nav.purchaseOrders',
    vendors: 'Vendors',
    products: 'Products',
    addresses: 'Addresses',
    vessels: 'Vessels',
    carriers: 'Carriers',
    roles: 'nav.roles',
    termsAndConditions: 'nav.termsAndConditions',
    seaports: 'nav.seaports',
    airports: 'nav.airports',
    currencies: 'nav.currencies',
    entities: 'nav.businessEntities',
    quotes: 'nav.quotes',
    customers: 'nav.customers',
    opportunities: 'nav.opportunities',
    crm: 'nav.crm',
    contracts: 'nav.contracts',
    miscellaneous: 'nav.miscellaneous',
    appSettings: 'nav.appSettings',
    operations: 'nav.operations',
    shipments: 'nav.shipments',
  },
}

const scrollToFirstError = () => {
  // Find all elements with the Mui-error class
  const errorElements = document.querySelectorAll('.Mui-error')

  if (errorElements.length > 0) {
    // Scroll to the first found error element
    errorElements[0].scrollIntoView({
      behavior: 'smooth', // Optional for smooth scrolling
      block: 'center', // Adjust the scroll positioning
    })
  }
}

export default scrollToFirstError

import {
  Box,
  Card,
  Container,
  Stack,
  Typography,
  Tabs,
  Tab,
} from '@mui/material'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { StickyFooter } from 'src/common/components/sticky-footer'
import { paths } from 'src/navigator/paths'
import * as Yup from 'yup'
import RoleForm from './form/roleForm'
import { useCreateRoleMutation } from './useRolesRequests'
import { useRouter } from 'src/common/hooks/use-router'
import { RolesFormValues as Values } from 'src/common/types/Role'
import Button from 'src/common/components/loadingButton'
import { useState } from 'react'

const defaultValues: Values = {
  name: '',
  permissions: [],
  submit: null,
}

const validationSchema = Yup.object({
  name: Yup.string().max(255).required('Name is required'),
  permissions: Yup.array()
    .min(1, 'Roles is required')
    .required('Roles is required'),
})

const CreateModal = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const navigate = useNavigate()
  const router = useRouter()

  const createMutation = useCreateRoleMutation({
    callback: () => {
      formik.resetForm()
      router.push('/roles')
    },
  })

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      const submitValues = {
        name: values.name,
        permissions: values.permissions,
      }
      createMutation.mutate({
        values: submitValues,
        setFieldError: formik.setFieldError,
      })
    },
  })

  const handleTabChange = (_: React.ChangeEvent<object>, newValue: number) => {
    setSelectedTab(newValue)
  }

  return (
    <Box component="main" py={4}>
      <Container maxWidth="xl">
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Create Role</Typography>
          </Stack>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="role type tabs"
          >
            <Tab label="Rulewave User Roles" />
            <Tab label="Customer Roles" />
          </Tabs>
          <Card>
            <Box p={2}>
              {selectedTab === 0 && (
                <RoleForm formik={formik} roleType="backoffice" />
              )}
              {selectedTab === 1 && (
                <RoleForm formik={formik} roleType="customer-portal" />
              )}
            </Box>
          </Card>
        </Stack>
      </Container>
      <StickyFooter
        rightComponent={
          <>
            <Button
              id="usersRolesPermissions-roles-create-save"
              variant="contained"
              disabled={formik.isSubmitting || createMutation.isLoading}
              isLoading={createMutation.isLoading}
              fullWidth
              onClick={async () => {
                formik.handleSubmit()
              }}
              type="submit"
            >
              Save
            </Button>
          </>
        }
        leftComponent={
          <>
            <Button
              id="usersRolesPermissions-roles-create-cancel"
              variant="outlined"
              onClick={() => {
                formik.resetForm()
                navigate(paths.roles.index)
              }}
            >
              Cancel
            </Button>
          </>
        }
      />
    </Box>
  )
}

export default CreateModal

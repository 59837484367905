/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { INDEXES } from 'src/common/apis/constants'
import { get, post, postDelete, put } from 'src/common/utils/api'
import {
  CustomersSubmitValues as SubmitValues,
  CustomersMutation as mutationProp,
} from 'src/common/types/Customer'
import { invalidateQueriesWithDelay } from 'src/common/utils/wait'

// export const useGetCustomersQuery = () => {
//   return useQuery(['crm/customers'])
// }

export const useGetCustomerDetails = (id: string) => {
  return useQuery({
    queryKey: ['customer', id],
    queryFn: () => get(`crm/customers/${id}`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      return {
        ...data,
        entities: data.entities?.map((entity: any) => ({
          ...entity,
          ...entity?.address,
        })),
        contacts: data.contacts?.map((contact: any) => ({
          label: contact.name,
          value: contact.id,
          ...contact,
        })),
      }
    },
  })
}

export const useCreateCustomerMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) =>
      //TODO: remove contacts: [] when backend is ready
      post(
        'crm/customers',
        {
          ...values,
          contacts: [],
        },
        setFieldError,
      ),
    onSuccess: (data, variables) => {
      toast.success(`Customer was created successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.CUSTOMER])

      callback && callback({ id: data?.id, ...variables })
    },
    onError: () => {
      toast.error(`Customer could not be created! Please try again`)
    },
  })
}

export const useUpdateCustomerMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => put(`crm/customers/${values.id}`, values, setFieldError),
    onSuccess: () => {
      toast.success(`Success! Customer was updated`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.CUSTOMER])
      callback && callback()
    },
    onError: () => {
      toast.error(`Customer could not be updated! Please try again`)
    },
  })
}

export const useDeleteCustomerMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (customersId: string) =>
      postDelete(`crm/customers/${customersId}`),
    onSuccess: () => {
      toast.success(`Customer was deleted successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.CUSTOMER])
      callback && callback()
    },
    onError: () => {
      toast.error(`Customer could not be deleted! Please try again`)
    },
  })
}

/* eslint-disable react/jsx-max-props-per-line */
import type { Theme } from '@mui/material'
import { Box, IconButton, Stack, SvgIcon, useMediaQuery } from '@mui/material'
import { alpha } from '@mui/material/styles'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { AccountButton } from '../navigation/account-button'
import { OfficesButton } from '../navigation/office-button'
// import { LanguageSwitch } from '../navigation/language-switch'
import { useContext } from 'react'
import { SettingsContext } from 'src/common/contexts/settings-context'
import { NormalUserGuard } from 'src/common/guards/role-guard'
import { NotificationsButton } from '../navigation/notifications-button'
import { SearchButton } from '../navigation/search-button'

const TOP_NAV_HEIGHT = 64
const SIDE_NAV_WIDTH = 280
const SIDE_NAV_COLLAPSED_WIDTH = 82

interface TopNavProps {
  onMobileNavOpen?: () => void
}

export const TopNav: FC<TopNavProps> = (props) => {
  const { onMobileNavOpen, ...other } = props
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const settings = useContext(SettingsContext)
  const { openMenu } = settings

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: openMenu ? SIDE_NAV_WIDTH : SIDE_NAV_COLLAPSED_WIDTH,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${
            openMenu ? SIDE_NAV_WIDTH : SIDE_NAV_COLLAPSED_WIDTH
          }px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          <NormalUserGuard>
            <OfficesButton />
          </NormalUserGuard>
          <SearchButton />
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          {/* <LanguageSwitch /> */}
          <NotificationsButton />
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  )
}

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
}

import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles((theme) => ({
  menuPaper: {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? theme.palette.grey[700]
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  },
  menuList: {
    padding: '4px 0',
  },
  menuItem: {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      marginRight: theme.spacing(1.5),
    },
    '&:active': {
      backgroundColor: theme.palette.primary.main,
      opacity: theme.palette.action.selectedOpacity,
    },
  },
}))

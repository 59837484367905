// import MenuIcon from '@mui/icons-material/Menu'
import { Box, Drawer, IconButton, Button, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useContext, useMemo } from 'react'
import { SettingsContext } from 'src/common/contexts/settings-context'
import { Logo } from '../../common/components/logo'
import { RouterLink } from '../../common/components/router-link'
import { Scrollbar } from '../../common/components/scrollbar'
import { usePathname } from '../../common/hooks/use-pathname'
import type { NavColor } from '../../common/types/Settings'
import { paths } from '../../navigator/paths'
import type { Section } from '../navigation/menuConfig'
import { TenantSwitch } from '../navigation/tenant-switch'
import { SideNavSection } from './side-nav-section'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'

const SIDE_NAV_WIDTH = 280
const SIDE_NAV_COLLAPSED_WIDTH = 82

const useCssVars = (color: NavColor): Record<string, string> => {
  const theme = useTheme()

  return useMemo((): Record<string, string> => {
    switch (color) {
      case 'blend-in':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          }
        } else {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.neutral[100],
            '--nav-logo-border': theme.palette.neutral[100],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.text.secondary,
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          }
        }

      case 'discreet':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[900],
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          }
        } else {
          return {
            '--nav-bg': theme.palette.neutral[50],
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.divider,
            '--nav-logo-border': theme.palette.neutral[200],
            '--nav-section-title-color': theme.palette.neutral[500],
            '--nav-item-color': theme.palette.neutral[500],
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900],
          }
        }

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          }
        } else {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400],
          }
        }

      default:
        return {}
    }
  }, [theme, color])
}

interface SideNavProps {
  color?: NavColor
  sections?: Section[]
}

export const SideNav: FC<SideNavProps> = (props) => {
  const { color = 'evident', sections = [] } = props
  const pathname = usePathname()
  const cssVars = useCssVars(color)

  const settings = useContext(SettingsContext)
  const { openMenu, handleMenuClose, handleMenuOpen } = settings

  const toggleDrawer = () => {
    if (openMenu) {
      handleMenuClose()
    } else {
      handleMenuOpen()
    }
  }

  return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          width: openMenu ? SIDE_NAV_WIDTH : SIDE_NAV_COLLAPSED_WIDTH,
        },
      }}
      variant="permanent"
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            minHeight: '100%',
            overflowY: 'auto',
          },
          '& .simplebar-placeholder': {
            display: 'none',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        <Stack sx={{ height: '100%', width: '100%' }}>
          {/* eslint-disable-next-line react/jsx-max-props-per-line */}
          {
            openMenu ? (
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                sx={{ p: 1 }}
              >
                <Box
                  component={RouterLink}
                  href={paths.index}
                  sx={{
                    borderColor: 'var(--nav-logo-border)',
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    display: 'flex',
                    height: 40,
                    p: '4px',
                    width: 40,
                    justifyContent: 'center',
                  }}
                >
                  <Logo />
                </Box>
                <TenantSwitch sx={{ flexGrow: 1 }} />
                {/* <IconButton onClick={toggleDrawer}>
                  <MenuIcon />
                </IconButton> */}
              </Stack>
            ) : (
              <Stack alignItems="center" sx={{ p: 1 }}>
                <Box
                  component={RouterLink}
                  href={paths.index}
                  sx={{
                    borderColor: 'var(--nav-logo-border)',
                    borderRadius: 1,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    display: 'flex',
                    height: 40,
                    p: '4px',
                    width: 40,
                    justifyContent: 'center',
                  }}
                >
                  <Logo />
                </Box>
              </Stack>
            )
            // <IconButton onClick={toggleDrawer}>
            //   <MenuIcon />
            // </IconButton>
          }
        </Stack>
        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            px: 2,
            mb: '60px',
          }}
        >
          {sections.map((section, index) => (
            <SideNavSection
              collapse={section.collapse}
              items={section.items}
              key={index + pathname}
              pathname={pathname}
              subheader={section.subheader}
              isDrawerOpen={openMenu}
            />
          ))}
        </Stack>
      </Scrollbar>
      <Box
        sx={{
          position: 'fixed',
          bottom: '0px',
          left: '16px',
          background: 'var(--nav-bg)',
        }}
      >
        {openMenu ? (
          <Button
            sx={{ width: '260px', color: '#eee' }}
            onClick={toggleDrawer}
            startIcon={<KeyboardDoubleArrowLeftIcon />}
          >
            Collapse sidebar
          </Button>
        ) : (
          <IconButton onClick={toggleDrawer}>
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
        )}
      </Box>
    </Drawer>
  )
}

SideNav.propTypes = {
  color: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident']),
  sections: PropTypes.array,
}

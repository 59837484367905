/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react'
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01'
import { Badge, IconButton, SvgIcon, Tooltip } from '@mui/material'
import { usePopover } from 'src/common/hooks/use-popover'
import { NotificationsPopover } from './notifications-popover'
import { useDispatch, useSelector } from 'react-redux'
import { removeAllNotifications as onMarkAllAsRead } from 'src/slices/NotificationSlice'

export const NotificationsButton: FC = () => {
  const popover = usePopover<HTMLButtonElement>()
  const { notificationList, unreadCount } = useSelector(
    (state: any) => state.notifications,
  )
  const dispatch = useDispatch()

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton ref={popover.anchorRef} onClick={popover.handleOpen}>
          <Badge color="error" badgeContent={unreadCount}>
            <SvgIcon>
              <Bell01Icon />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={popover.anchorRef.current}
        notifications={notificationList}
        onClose={() => {
          popover.handleClose()
          dispatch(onMarkAllAsRead())
        }}
        open={popover.open}
      />
    </>
  )
}

import { format, parseISO, isValid } from 'date-fns'
import React from 'react'

/* eslint-disable @typescript-eslint/no-explicit-any */
export function handleNumberKeyDown(
  event: React.KeyboardEvent,
  input_value: number | string,
  otherFieldProps: any,
  formik: any,
  field: any,
  onChange: any,
) {
  //  todo consider field.min not to allow event.key === 'ArrowDown'
  //  todo consider field.max not to allow event.key === 'ArrowUp'
  if (event.key === 'ArrowUp') {
    event.preventDefault()
    return changeValueBy(
      1,
      input_value,
      otherFieldProps,
      formik,
      field,
      onChange,
    )
  } else if (event.key === 'ArrowDown') {
    event.preventDefault()
    return changeValueBy(
      -1,
      input_value,
      otherFieldProps,
      formik,
      field,
      onChange,
    )
  }
}

export function changeValueBy(
  amount: number,
  input_value: number | string,
  otherFieldProps: any,
  formik: any,
  field: any,
  onChange: any,
) {
  const currentValue =
    typeof input_value === 'number' ? input_value : parseFloat(input_value) || 0
  const newValue = currentValue + amount
  // Ensure the new value respects the min and max constraints
  if (field.min !== undefined && newValue < field.min) {
    return
  }
  if (field.max !== undefined && newValue > field.max) {
    return
  }

  formik.setFieldValue(field?.name, newValue)
  onChange &&
    onChange(otherFieldProps.name, {
      target: { name: otherFieldProps.name, value: newValue },
    })

  otherFieldProps.onChange &&
    otherFieldProps.onChange({
      target: {
        name: otherFieldProps.name,
        value: newValue,
      },
    })
}
export const formatDateValue = (value: any) => {
  // case 3: null
  // case 4: undefined
  if (!value) return ''

  let date

  // Ensure value is a string before checking for 'T'
  if (typeof value === 'string' && value.includes('T')) {
    // case 1: ISO 8601 format (e.g., 2024-06-07T11:04:00.000000Z)
    date = parseISO(value)
  } else {
    // case 2: Date string format (e.g., Fri Jun 14 2024 13:04:00 GMT+0200 (Central European Summer Time))
    date = new Date(value)
  }

  // case 5: invalid date
  if (!isValid(date)) return ''

  return format(date, 'dd/MM/yyyy HH:mm')
}

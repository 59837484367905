import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface Notification {
  id: string
  status: 'success' | 'error'
  message: string
  type: string
  objectType: string
  isPersistent: boolean
  from: 'system' | 'userId'
  data: {
    objectId: string
    pdfUrl?: string
    createdAt?: number
  }
}

interface NotificationState {
  notificationList: Notification[]
  unreadCount: number
}

type GetNotificationAction = PayloadAction<Notification>

const initialState: NotificationState = {
  notificationList: [],
  unreadCount: 0,
}

const reducers = {
  addNotification(state: NotificationState, action: GetNotificationAction) {
    state.notificationList = [action.payload, ...state.notificationList]
    state.unreadCount += 1
  },
  removeAllNotifications(state: NotificationState) {
    state.unreadCount = 0
  },
}

export const slice = createSlice({
  name: 'notification',
  initialState,
  reducers,
})

export const { addNotification, removeAllNotifications } = slice.actions

export const { reducer } = slice

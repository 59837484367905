import { Stack } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import CustomerAutocomplete from 'src/common/components/autocomplete/customer'
import RenderField from 'src/common/components/form/RenderField'

interface FormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
}

const ContactForm = ({ formik }: FormProps) => {
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <RenderField
          field={{
            label: 'Name *',
            name: 'name',
            type: 'text',
          }}
          formik={formik}
        />
        <MuiTelInput
          error={!!(formik.touched.phone && formik.errors.phone)}
          fullWidth
          InputLabelProps={{ shrink: true }}
          helperText={formik.touched.phone && formik.errors.phone}
          label="Phone Number *"
          name="phone"
          onBlur={formik.handleBlur}
          onChange={(newValue: string) =>
            formik.setFieldValue('phone', newValue)
          }
          value={formik.values.phone}
          defaultCountry="NL"
          variant="outlined"
        />
        <RenderField
          field={{
            label: 'Email *',
            name: 'email',
            type: 'text',
          }}
          formik={formik}
        />

        <CustomerAutocomplete name="customerId" formik={formik} />
      </Stack>
    </form>
  )
}

export default ContactForm

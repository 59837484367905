import CloseIcon from '@mui/icons-material/Close'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'

import { FC, ReactNode } from 'react'

interface DialogHeaderProps {
  id: string
  onClose: () => void
  children: ReactNode
  onFullScreen?: (data: unknown) => void
}
const DialogHeader: FC<DialogHeaderProps> = (props: DialogHeaderProps) => {
  const { children, onClose, onFullScreen, ...other } = props
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}

      {onFullScreen ? (
        <IconButton
          aria-label="Full Screen"
          title="Full Screen"
          onClick={onFullScreen}
          sx={{
            position: 'absolute',
            right: 50,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FullscreenIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

interface DialogProps {
  id: string
  open: boolean
  dividers?: boolean
  onClose: () => void
  onFullScreen?: (data: unknown) => void
  children: ReactNode
  style?: object
  footer?: ReactNode
  title?: ReactNode | string
  hideFooter?: boolean
  fullScreen?: boolean
  fullWidth?: boolean
  inline?: boolean
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  variant?: 'persistent' | 'temporary'
}

const Modal: FC<DialogProps> = (props) => {
  // const theme = useTheme()
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  // const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const {
    id,
    open,
    onClose,
    children,
    title = '',
    footer,
    dividers = true,
    onFullScreen,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    variant,
    maxWidth = 'md',
    ...otherProps
  } = props

  return (
    <Drawer
      // variant="persistent"
      anchor="right"
      open={open}
      onClose={onClose}
      id={id}
      // variant={variant || 'persistent'}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
        BackdropProps: {
          // invisible: false,
        },
        sx: {
          minWidth: '40%',
        },
      }}
      PaperProps={{
        sx: {
          minWidth: '40%',
          maxWidth: maxWidth,
          // flexShrink: 0,
        },
      }}
      aria-labelledby={`modal-title${id}`}
      aria-describedby={`modal-description${id}`}
      {...otherProps}
    >
      <DialogHeader
        onClose={onClose}
        id={'Dialog-header' + id}
        onFullScreen={onFullScreen}
      >
        {title}
      </DialogHeader>
      <DialogContent dividers={dividers}>{children}</DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Drawer>
  )
}

export default Modal

// import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { Layout } from '../modules/frontPages/index'
import { Navigate } from 'react-router-dom'
// import Dashboard from 'pages'
import Error401Page from '../pages/401'
import Error404Page from '../pages/404'
import Error500Page from '../pages/500'
import { addressesRoutes } from './routes/backoffice/addresses'
import { airportsRoutes } from './routes/backoffice/airports'
import { authRoutes } from './routes/auth'
import { notificationsRoutes } from './routes/backoffice/notifications'
import { privacyRoutes } from './routes/backoffice/privacy'
import { purchaseOrdersRoutes } from './routes/backoffice/purchaseorders'
import { vendorsRoutes } from './routes/backoffice/vendors'
import { carriersRoutes } from './routes/backoffice/carriers'
import { contactsRoutes } from './routes/backoffice/contacts'
import { contractsRoutes } from './routes/backoffice/contracts'
import { customersRoutes } from './routes/backoffice/customers'
import { dashboardRoutes } from './routes/backoffice/dashboard'
import { filesRoutes } from './routes/backoffice/files'
import { leadRoutes } from './routes/backoffice/leads'
import { officesRoutes } from './routes/backoffice/offices'
import { opportunitiesRoutes } from './routes/backoffice/opportunities'
import { productsRoutes } from './routes/backoffice/products'
import { quotesRoutes } from './routes/backoffice/quotes'
import { rolesRoutes } from './routes/backoffice/roles'
import { seaportsRoutes } from './routes/backoffice/seaports'
import { termsAndConditionsRoutes } from './routes/backoffice/termsandconditions'
import { userRoutes } from './routes/backoffice/user'
import { vesselsRoutes } from './routes/backoffice/vessels'
import { invoiceRoutes } from './routes/backoffice/invoices'

import { dashboardRoutes as customerDashboardRoutes } from './routes/customer/dashboard'
import { quotesRoutes as customerQuotesRoutes } from './routes/customer/quotes'
import { orderRoutes as customerOrdersdRoutes } from './routes/customer/orders'
import { invoicesRoutes as customerInvoicesRoutes } from './routes/customer/invoices'
import { shipmentsRoutes as customershipmentsRoutes } from './routes/customer/shipments'
import RoleGuard from 'src/common/guards/role-guard'

import type { RouteObject } from 'react-router-dom'
import { CustomRouteObject } from './routes/types'

const wrapRoutesWithRoleGuard = (
  routes: CustomRouteObject[],
  defaultScopes: string[] = [],
) => {
  return routes.map((route) => {
    const { element, scopes = defaultScopes, ...rest } = route
    if (scopes?.length === 0) return route

    return {
      ...rest,
      element: (
        <RoleGuard
          scopes={scopes}
          RenderError={() => <Navigate to="/dashboard" replace />}
        >
          {element}
        </RoleGuard>
      ),
    }
  })
}

// back office routes
export const backOfficeRoutes: RouteObject[] = wrapRoutesWithRoleGuard([
  {
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        index: true,
        element: <div>Work in progress</div>,
      },
    ],
  },
  ...privacyRoutes,
  ...authRoutes,
  ...notificationsRoutes,
  ...purchaseOrdersRoutes,
  ...vendorsRoutes,
  ...productsRoutes,
  ...addressesRoutes,
  ...vesselsRoutes,
  ...filesRoutes,
  ...carriersRoutes,
  ...rolesRoutes,
  ...termsAndConditionsRoutes,
  ...seaportsRoutes,
  ...airportsRoutes,
  ...quotesRoutes,
  ...customersRoutes,
  ...dashboardRoutes,
  ...userRoutes,
  ...officesRoutes,
  ...leadRoutes,
  ...opportunitiesRoutes,
  ...contactsRoutes,
  ...contractsRoutes,
  ...invoiceRoutes,
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <Error404Page />,
  },
])

// customer portal routes
export const customerRoutes: RouteObject[] = wrapRoutesWithRoleGuard([
  {
    element: (
      <Layout>
        <Outlet />
      </Layout>
    ),
    children: [
      {
        index: true,
        element: <div>Work in progress</div>,
      },
    ],
  },
  ...authRoutes,
  ...notificationsRoutes,
  ...customerDashboardRoutes,
  ...customerQuotesRoutes,
  ...customerOrdersdRoutes,
  ...customerInvoicesRoutes,
  ...customershipmentsRoutes,
  {
    path: '401',
    element: <Error401Page />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '500',
    element: <Error500Page />,
  },
  {
    path: '*',
    element: <Error404Page />,
  },
])

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { ENTITIES, INDEXES } from 'src/common/apis/constants'
import { get, post, postDelete, put } from 'src/common/utils/api'
import {
  QuotesMutationProp as mutationProp,
  PreviewQuote,
  Quote,
  QuotePdfsMutationProp,
} from 'src/common/types/Quote'
import { invalidateQueriesWithDelay } from 'src/common/utils/wait'

export const useGetQuoteQuery = (id: string) => {
  return useQuery({
    queryKey: ['quote', id],
    queryFn: () => get(`sales/quotes/${id}`),
    retry: false,
    enabled: !!id,
    select: (result: any) => {
      return {
        ...result,
        businessEntityId: result.businessEntityId?.id,
        customerId: {
          ...result.customerId,
          value: result.customerId?.id,
          label: result.customerId.name,
        },
        contactId: result?.contactId?.id || '',
        pickUpPort: {
          ...result.pickUpPort,
          value: result.pickUpPort?.id,
          label: result.pickUpPort?.name,
        },
        currency: result.currency.code,
        deliveryPort: {
          ...result.deliveryPort,
          value: result.deliveryPort?.id,
          label: result.deliveryPort?.name,
        },
        pickupAddress: result?.pickupAddress
          ? {
              ...result.pickupAddress,
              country: result?.pickupAddress?.country?.code,
            }
          : null,
        deliveryAddress: result?.deliveryAddress
          ? {
              ...result.deliveryAddress,
              country: result?.deliveryAddress?.country?.code,
            }
          : null,
        terms: Object.values(result.terms || {})?.map((x: any) => x.id),
      } as Quote
    },
  })
}

export const useGetPreviewQuoteQuery = (id: string, customerId?: string) => {
  return useQuery({
    queryKey: ['quote', id],
    queryFn: () =>
      get(
        customerId
          ? `customers/${customerId}/quotes/${id}`
          : `sales/quotes/${id}`,
      ),
    retry: false,
    enabled: !!id,
    select: (result: any) => {
      return {
        ...result,
        businessEntity: {
          ...result.businessEntityId,
          ...result.businessEntityId?.address,
        },
        customer: result.customerId,
        contact: result.contactId,
        pickUpPortObj: result.pickUpPort,
        pickUpPort: result.pickUpPort?.code,
        deliveryPortObj: result.deliveryPort,
        deliveryPort: result.deliveryPort?.code,
        currencyObj: result.currency,
        currency: result.currency.code,
        terms: Object.values(result.terms || {}),
        pickupAddress: result?.pickupAddress
          ? {
              ...result.pickupAddress,
              country: result?.pickupAddress?.country?.name
                ? result?.pickupAddress?.country?.name
                : result?.pickupAddress?.country,
            }
          : null,
        deliveryAddress: result?.deliveryAddress
          ? {
              ...result.deliveryAddress,
              country: result?.deliveryAddress?.country?.name
                ? result?.deliveryAddress?.country?.name
                : result?.deliveryAddress?.country,
            }
          : null,
      } as PreviewQuote
    },
  })
}

export const useCreateQuoteMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      if (values.mode === 'air') {
        delete values.deliveryAddress
        delete values.pickupAddress
      }
      return post('sales/quotes', values, setFieldError)
    },

    onSuccess: (data, variables) => {
      toast.success(`Quote was created successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.QUOTE])
      console.log(data, variables)
      callback && callback({ ...data?.data, ...variables?.values })
    },
    onError: () => {
      toast.error(`Quote could not be created! Please try again`)
      // callback && callback()
    },
  })
}

export const useCreateQuotePDFMutation = ({
  quoteId,
  callback,
}: QuotePdfsMutationProp) => {
  return useMutation({
    mutationFn: ({ isSilent = false }: any) => {
      !isSilent &&
        toast.success(
          'Quote PDF is being prepared! You will get  notified when it is ready.',
        )
      return get(`sales/quotes/${quoteId}/generate-pdf`)
    },
    onSuccess: async () => {
      callback && callback()
    },
  })
}

export const useSendQuoteMutation = ({ id, callback }: any) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ entityId }: { entityId: string }) => {
      return post(`sales/quotes/${entityId}/send`)
    },

    onSuccess: () => {
      toast.success(`Quote was sent successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.QUOTE, id])
      callback && callback()
    },
    onError: () => {
      toast.error(`Quote could not be sent! Please try again`)
    },
  })
}

export const useUpdateQuoteMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => any
    }) => {
      if (values.mode === 'air') {
        delete values.deliveryAddress
        delete values.pickupAddress
      }

      return put(`sales/quotes/${values.id}`, values, setFieldError)
    },
    onSuccess: () => {
      toast.success(`Success! Quote was updated`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.QUOTE])

      callback && callback()
    },
    onError: () => {
      toast.error(`Quote could not be updated! Please try again`)
    },
  })
}

export const useDeleteQuoteMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (quotesId: string) => postDelete(`sales/quotes/${quotesId}`),
    onSuccess: () => {
      toast.success(`Quote was deleted successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.QUOTE])

      callback && callback()
    },
    onError: () => {
      toast.error(`Quote could not be deleted! Please try again`)
    },
  })
}

export const useHideQuoteMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (quotesId: string) => post(`sales/quotes/${quotesId}/hide`),
    onSuccess: () => {
      toast.success(`Quote was hidden successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.QUOTE])
      callback && callback()
    },
    onError: () => {
      toast.error(`Quote could not be hidden! Please try again`)
    },
  })
}

// export const useHideQuoteMutation = ({
//   callback,
//   id,
// }: mutationProp & { id: string }) => {
//   const queryClient = useQueryClient()
//   return useMutation({
//     mutationFn: () => {
//       return post(`sales/quotes/${id}/hide`)
//     },
//     onSuccess: () => {
//       toast.success(`Quote was hidden successfully`)
//       invalidateQueriesWithDelay(queryClient, [INDEXES.QUOTE, id])
//       callback && callback()
//     },
//     onError: () => {
//       toast.error(`Quote could not be hidden! Please try again`)
//     },
//   })
// }

export const useRejectQuoteMutation = ({
  callback,
  id,
}: mutationProp & { id: string }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: { rejectReason: any }
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      return post(`sales/quotes/${id}/reject`, values, setFieldError)
    },

    onSuccess: () => {
      toast.success(`Quote was rejected successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.QUOTE, id])

      callback && callback()
    },
    onError: () => {
      toast.error(`Quote could not be rejected! Please try again`)
    },
  })
}
export const useAcceptQuoteMutation = ({
  callback,
  id,
}: mutationProp & { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => {
      return post(`sales/quotes/${id}/accept`)
    },
    onSuccess: () => {
      toast.success(`Quote was accepted successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.QUOTE, id])
      callback && callback()
    },
    onError: () => {
      toast.error(`Quote could not be accepted! Please try again`)
    },
  })
}

export const useGetQuotesRequests = () => {
  return useQueries({
    queries: ['service-types'].map((item) => {
      return {
        queryKey: [item],
        queryFn: () => get('common/' + item),
        select: (data: any) => {
          if (data && data.data) {
            return (
              data &&
              data.data.map((item: any) => ({
                value: item.id,
                label: item.name,
                ...item,
              }))
            )
          } else {
            return (
              data &&
              data.map((item: any) => ({
                value: item.id,
                label: item.name,
                ...item,
              }))
            )
          }
        },
      }
    }),
  })
}

export const useGetTermsConditionsQuotes = () => {
  return useQuery([ENTITIES.TERMS_AND_CONDITIONS], {
    queryFn: () => get('common/terms-and-conditions'),
    select: (data: any) => {
      return Object.values(data?.data).map((item: any) => ({
        value: item.id,
        label: item.name,
        ...item,
      }))
    },
  })
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { MenuItem, FormHelperText } from '@mui/material'
import { ReactElement, useRef } from 'react'
import { StatusKey, StatusValue } from '../statuses'
import { Option } from '../grid/types'
import { styled } from '@mui/material/styles'

const InputLabelStyled = styled(InputLabel)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: '0px 4px',
  borderRadius: '10px',
}))

export type StatusItem = {
  key: string | StatusKey
  id?: string
  value: StatusValue
  label: ReactElement
}

export interface FormProps {
  touched: any
  errors: any
  handleBlur: any
  handleChange: any
  value: any
  label: string
  name: string
  readOnly?: boolean
  disabled?: boolean
  required?: boolean
  statusOptions: Option[]
  isLoading?: boolean
  defaultValue?: any
  size?: 'small' | 'medium'
}

const SelectStatusInput = ({
  touched,
  errors,
  handleBlur,
  handleChange,
  value,
  readOnly = false,
  statusOptions = [],
  name,
  label,
  required = false,
  isLoading,
  size = 'small',
  disabled = false,
}: FormProps) => {
  const menuContainerRef = useRef(null)
  const requiredStr = required ? ' *' : ''
  return (
    <>
      <FormControl
        fullWidth
        error={!!(touched[name] && errors[name])}
        variant="outlined"
        id={name + '-select-container'}
        disabled={disabled}
        size={size}
      >
        <InputLabelStyled shrink={!!value} id="label">
          {label + requiredStr}
        </InputLabelStyled>
        <Select
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          value={isLoading ? null : value}
          id={name + '-select'}
          disabled={disabled}
          readOnly={readOnly}
          MenuProps={{
            container: menuContainerRef.current,
          }}
          sx={{ paddingTop: '10px' }}
          size={size}
        >
          {statusOptions.map((option: Option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {touched[name] && errors[name] && (
          <FormHelperText>{errors[name]}</FormHelperText>
        )}
        <div ref={menuContainerRef} id={'menu-container-' + name}></div>
      </FormControl>
    </>
  )
}

export default SelectStatusInput

export enum INDEXES {
  AIRPORT = 'airport',
  BUSINESS_ENTITY = 'business_entity',
  SEAPORT = 'seaport',
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
  CONTACT = 'contact',
  CUSTOMER = 'customer',
  OFFICE = 'office',
  OFFICES = 'offices',
  USER = 'user',
  CONTRACT = 'contract',
  LEAD = 'lead',
  OPPORTUNITY = 'opportunity',
  QUOTE = 'quote',
  FILE = 'file',
  PURCHASE_ORDERS = 'purchase_order',
  PRODUCT = 'product',
  VESSEL = 'vessel',
  ADDRESS = 'address',
  CARRIER = 'carrier',
  SHIPMENT = 'shipment',
  INVOICE = 'invoice',
  PURCHASE = 'purchase',
  VENDOR = 'vendor',
  NOTIFICATION = 'notification',
}
export enum ENTITIES {
  AIRPORTS = 'airports',
  ADDRESSES = 'addresses',
  INVOICES = 'finance/invoices',
  BUSINESS_ENTITIES = 'business_entities',
  SEAPORTS = 'seaports',
  TERMS_AND_CONDITIONS = 'terms_and_conditions',
  CONTACTS = 'contacts',
  CUSTOMERS = 'customers',
  OFFICES = 'officess',
  USERS = 'users',
  CONTRACTS = 'contracts',
  LEADS = 'leads',
  OPPORTUNITIES = 'opportunities',
  QUOTES = 'quotes',
  CURRENCIES = 'currencies',
  COUNTRIES = 'countries',
  ROLES = 'acl/roles',
  FILES = 'files',
  VENDORS = 'vendors',
  NOTIFICATIONS = 'notifications',
  PURCHASE_ORDERS = 'purchase_orders',
}

export const DEPARTAMENTS = [
  { id: 'IB', label: 'IB - Inbound' },
  { id: 'OB', label: 'OB - Outbound' },
  { id: 'AIR', label: 'AIR - Airfreight' },
  { id: 'FW', label: 'Fazant - Fazantweg' },
  { id: 'FF', label: 'FF - Freight Forwarding' },
  { id: 'PH', label: 'PH - Phillipines' },
  { id: 'CU', label: 'CU- Customs' },
  { id: 'FI', label: 'FI - Finance' },
  { id: 'OVH', label: 'OVH - Overhead' },
  { id: 'WAY', label: 'WAY - Ruleway' },
].map((x) => ({ ...x, value: x.id }))

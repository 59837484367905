import { Address } from 'src/common/types/Quote'

const defaultValue = {
  fullAddress: null,
  country: null,
  city: null,
  zipCode: null,
  street: null,
}
interface AddressProps {
  address?: Address
}
export const hasEmptyAddressFields = ({
  address = defaultValue,
}: AddressProps) => {
  if (!address) return true

  const { fullAddress, country, city, zipCode, street } = address
  return !fullAddress && !country && !city && !zipCode && !street
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertEnumToOptions = (enumObj: any, useKeyValue?: boolean) => {
  return Object.keys(enumObj).map((key) => ({
    id: useKeyValue ? key : enumObj[key],
    value: useKeyValue ? key : enumObj[key],
    label: enumObj[key],
  }))
}

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DescriptionIcon from '@mui/icons-material/Description'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import FlightIcon from '@mui/icons-material/Flight'
import HomeIcon from '@mui/icons-material/Home'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PointOfSaleIconIcon from '@mui/icons-material/PointOfSale'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import SailingIcon from '@mui/icons-material/Sailing'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import { SvgIcon } from '@mui/material'

export const iconsMap: { [key: string]: JSX.Element } = {
  product: (
    <SvgIcon>
      <AccountBoxOutlinedIcon />
    </SvgIcon>
  ),
  airport: (
    <SvgIcon>
      <FlightIcon />
    </SvgIcon>
  ),
  seaport: (
    <SvgIcon>
      <DirectionsBoatIcon />
    </SvgIcon>
  ),
  termsAndCondition: (
    <SvgIcon>
      <DescriptionIcon />
    </SvgIcon>
  ),
  vessel: (
    <SvgIcon>
      <SailingIcon />
    </SvgIcon>
  ),
  addresse: (
    <SvgIcon>
      <HomeIcon />
    </SvgIcon>
  ),
  carrier: (
    <SvgIcon>
      <ConnectingAirportsIcon />
    </SvgIcon>
  ),
  vendor: (
    <SvgIcon>
      <PointOfSaleIconIcon />
    </SvgIcon>
  ),

  dashboard: (
    <SvgIcon>
      <DashboardIcon />
    </SvgIcon>
  ),
  sale: (
    <SvgIcon>
      <PersonAddIcon />
    </SvgIcon>
  ),
  opportunitie: (
    <SvgIcon>
      <BusinessCenterIcon />
    </SvgIcon>
  ),
  quote: (
    <SvgIcon>
      <RequestQuoteIcon />
    </SvgIcon>
  ),
  contract: (
    <SvgIcon>
      <DescriptionIcon />
    </SvgIcon>
  ),
  contact: (
    <SvgIcon>
      <ContactMailIcon />
    </SvgIcon>
  ),
  customer: (
    <SvgIcon>
      <PeopleAltIcon />
    </SvgIcon>
  ),
  file: (
    <SvgIcon>
      <InsertDriveFileIcon />
    </SvgIcon>
  ),
  purchaseOrder: (
    <SvgIcon>
      <ShoppingCartIcon />
    </SvgIcon>
  ),
  invoice: (
    <SvgIcon>
      <InsertDriveFileIcon />
    </SvgIcon>
  ),
  user: (
    <SvgIcon>
      <PersonIcon />
    </SvgIcon>
  ),
  role: (
    <SvgIcon>
      <SupervisorAccountIcon />
    </SvgIcon>
  ),
}

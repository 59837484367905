/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { post, put, get } from 'src/common/utils/api'
import {
  CustomersBusinessEntitiesEntity as Entity,
  CustomersMutation as mutationProp,
  CustomersBusinessEntitiesQuery as queryProp,
  CustomersBusinessEntitiesQueryDetails as queryDetailsProp,
} from 'src/common/types/Customer'

// api/crm/customers/4d1a1e71-ae63-4ed6-83be-96949849ec25/entities

export const useGetBusinessEntityQuery = ({ id }: queryDetailsProp) => {
  return useQuery(['entity', id], {
    queryFn: () => get(`crm/entities/`),
    enabled: !!id,
    select: (rawData) => {
      const { address, ...other } = rawData?.data?.businessEntity || {}
      return {
        ...other,
        ...address,
      }
    },
  })
}

export const useGetBusinessEntitiesQuery = ({ customerId }: queryProp) => {
  return useQuery(['entities', customerId], {
    queryFn: () => get(`crm/customers/${customerId}/entities`),
    enabled: !!customerId,
    select: (rawData) => {
      const transformedData = Object.values(rawData.data).map((item: any) => ({
        ...item,
        ...item.address,
        address: undefined,
      }))

      return transformedData
    },
  })
}

export const useCreateBusinessEntityMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: Entity
      setFieldError?: (field: string, value: string | undefined) => void
    }) =>
      post(
        `crm/customers/${values.customerId}/entities`,
        values,
        setFieldError,
      ),
    onSuccess: (data, variables) => {
      toast.success(`Business Entity was created successfully`)
      queryClient.invalidateQueries(['customer', variables?.values?.customerId])
      callback && callback()
    },
    onError: () => {
      toast.error(`Business Entity could not be created! Please try again`)
    },
  })
}

interface updatemutationProps {
  customerId: string
  callback: () => void
}
export const useUpdateBusinessEntityMutation = ({
  customerId,
  callback,
}: updatemutationProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: Entity
      setFieldError?: (field: string, value: string | undefined) => void
    }) => put(`crm/entities/${values.id}`, values, setFieldError),
    onSuccess: () => {
      toast.success(`Success! BusinessEntity was updated`)
      queryClient.invalidateQueries(['customer', customerId])

      callback && callback()
    },
    onError: () => {
      toast.error(`BusinessEntity could not be updated! Please try again`)
    },
  })
}

export const useDeleteBusinessEntityMutation = ({
  customerId,
  callback,
}: updatemutationProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (businessentitiesId: string) =>
      post(`crm/entities/${businessentitiesId}/disable`),
    onSuccess: () => {
      toast.success(`BusinessEntity was deleted successfully`)
      queryClient.invalidateQueries(['customer', customerId])

      callback && callback()
    },
    onError: () => {
      toast.error(`BusinessEntity could not be deleted! Please try again`)
    },
  })
}

import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { withAuthGuard } from '../common/hocs/with-auth-guard'
import { useSettings } from '../common/hooks/use-settings'
import { HorizontalLayout } from './horizontal-layout'
import { useFilteredSections } from './navigation/menuConfig'
import { VerticalLayout } from './vertical-layout'

interface LayoutProps {
  children?: ReactNode
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings()
  const sections = useFilteredSections()

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    )
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  )
})

Layout.propTypes = {
  children: PropTypes.node,
}

import React from 'react'
import type { FC, ReactNode } from 'react'
import { Box, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import { useSettings } from '../hooks/use-settings'
import { useMediaQuery } from '@mui/material'
import type { Theme } from '@mui/material'

interface StickyFooterProps {
  className?: string
  leftComponent?: ReactNode
  middleComponent?: ReactNode
  rightComponent?: ReactNode
}
const SIDE_NAV_WIDTH = '280px'
const SIDE_NAV_COLLAPSED_WIDTH = '82px'

export const StickyFooter: FC<StickyFooterProps> = ({
  className,
  leftComponent,
  middleComponent,
  rightComponent,
}) => {
  const theme = useTheme()

  // Import necessary hooks and constants
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const { openMenu } = useSettings()

  // Determine the paddingLeft value based on the menu state and screen size
  const paddingLeft = lgUp
    ? openMenu
      ? SIDE_NAV_WIDTH
      : SIDE_NAV_COLLAPSED_WIDTH
    : '24px'

  return (
    <Grid
      item
      xs={12}
      style={{
        position: 'fixed',
        bottom: '0',
        // left: '0',
        right: '0',
        backgroundColor: theme.palette.background.paper,
        padding: '1rem',
        zIndex: 100,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        paddingLeft: paddingLeft,
      }}
      className={className}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'start',
          marginLeft: '16px',
          gap: '8px',
        }}
      >
        {leftComponent}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
        {middleComponent}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          marginRight: '16px',
          gap: '8px',
        }}
      >
        {rightComponent}
      </Box>
    </Grid>
  )
}

StickyFooter.propTypes = {
  /** The component to be shown left */
  leftComponent: PropTypes.node,
  /** The component to be shown in the middle */
  middleComponent: PropTypes.node,
  /** The component to be shown right */
  rightComponent: PropTypes.node,
}

StickyFooter.defaultProps = {
  leftComponent: null,
  middleComponent: null,
  rightComponent: null,
}

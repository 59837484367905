/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { createContext, useCallback, useEffect, useReducer } from 'react'
import { Office } from 'src/common/types/Office'
import { authApi } from 'src/pages/Auth/Login/services/auth/apis'
import { RoleWithPermissions } from '../../types/Login'
import type { User } from '../../types/User'
import {
  deleteJwtCookie,
  getJwtFromCookie,
  setJwtCookie,
} from '../../utils/auth-cookie'

export enum Issuer {
  JWT = 'JWT',
}
interface State {
  isInitialized: boolean
  isAuthenticated: boolean
  user: User | null
  office: Office | null
  permissions: string[]
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}

type InitializeAction = {
  type: ActionType.INITIALIZE
  payload: {
    isAuthenticated: boolean
    user: User | null
    office: Office | null
    permissions: string[]
  }
}

type SignInAction = {
  type: ActionType.SIGN_IN
  payload: {
    user: User
    office: Office | null
    permissions: string[]
  }
}

type SignOutAction = {
  type: ActionType.SIGN_OUT
}

type Action = InitializeAction | SignInAction | SignOutAction

type Handler = (state: State, action: any) => State

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  office: null,
  permissions: [],
}

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, office, permissions } = action.payload

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      office,
      permissions,
    }
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user, office, permissions } = action.payload

    return {
      ...state,
      isAuthenticated: true,
      user,
      office,
      permissions,
    }
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    office: null,
    permissions: [],
  }),
}

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state

export interface AuthContextType extends State {
  issuer: Issuer.JWT
  signIn: (email: string, password: string) => void
  signOut: () => Promise<void>
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
})

interface AuthProviderProps {
  children: ReactNode
}

function extractUniquePermissions(roles: RoleWithPermissions[]) {
  const allPermissions = roles?.flatMap(
    (role: RoleWithPermissions) => role.permissions,
  )

  return [...new Set(allPermissions)]
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  const initialize = useCallback(async (): Promise<void> => {
    try {
      const accessToken = getJwtFromCookie()

      if (accessToken) {
        const { user, office, rolesWithPermissions } = await authApi.me({
          accessToken: accessToken,
        })

        const permissions = extractUniquePermissions(
          Object.values(rolesWithPermissions),
        )
        user &&
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: user,
              permissions,
              office,
            },
          })
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            office: null,
            permissions: [],
          },
        })
      }
    } catch (err) {
      console.error(err)
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
          office: null,
          permissions: [],
        },
      })
    }
  }, [])

  useEffect(
    () => {
      initialize()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const signIn = useCallback(
    async (email: string, password: string): Promise<void> => {
      const data = await authApi.signIn({ email, password })
      if (data && data.accessToken) {
        setJwtCookie(data.accessToken)

        const { user, office, rolesWithPermissions } = await authApi.me({
          accessToken: data.accessToken,
        })

        const permissions = extractUniquePermissions(
          Object.values(rolesWithPermissions),
        )
        console.log('permissions', permissions)

        if (user) {
          dispatch({
            type: ActionType.SIGN_IN,
            payload: {
              user,
              office,
              permissions,
            },
          })
        }
      }
    },
    [dispatch],
  )

  const signOut = useCallback(async (): Promise<void> => {
    deleteJwtCookie()
    dispatch({ type: ActionType.SIGN_OUT })
  }, [dispatch])

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const AuthConsumer = AuthContext.Consumer

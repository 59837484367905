//  holds constants for all app paths or urls
export const paths = {
  index: '/',
  privacy: {
    terms: 'privacy/terms',
  },
  auth: {
    login: '/auth/login',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/reset-password',
    register: '/auth/register',
  },
  user: {
    index: '/users',
    details: '/users/details',
    edit: '/users/edit',
    // usersRoles: '/roles',
    usersPermissions: '/permissions',
    profile: '/users/editProfile',
  },
  offices: {
    index: '/offices',
  },
  finance: {
    invoices: '/invoices',
    print: '/invoices/print',
  },
  dashboard: {
    index: '/dashboard',
  },
  leads: {
    index: '/leads',
    details: '/leads/details',
    overview: '/overview',
    pipeline: '/pipeline',
  },
  opportunities: {
    index: '/opportunities',
    details: '/opportunities/details',
    overview: '/overview',
    pipeline: '/pipeline',
  },
  contacts: {
    index: '/contacts',
    details: '/contacts/details',
  },
  customers: {
    index: '/customers',
    details: '/customers/details',
    overview: '/overview',
  },

  quotes: {
    index: '/quotes',
    details: '/quotes/details',
    overview: '/overview',
    pipeline: '/pipeline',
  },
  contracts: {
    index: '/contracts',
    details: '/contracts/details',
  },
  roles: {
    index: '/roles',
    details: '/roles/details',
    overview: '/overview',
  },
  currencies: {
    index: '/currencies',
    details: '/currencies/details',
    overview: '/overview',
  },
  airports: {
    index: '/airports',
    details: '/airports/details',
    overview: '/overview',
  },
  seaports: {
    index: '/seaports',
    details: '/seaports/details',
    overview: '/overview',
  },
  terms: {
    index: '/termsAndConditions',
    details: '/termsAndConditions/details',
    overview: '/overview',
  },
  carriers: {
    index: '/carriers',
    details: '/carriers/details',
    overview: '/overview',
  },
  files: {
    index: '/files',
    details: '/files/details',
    createInvoice: '/invoices/new',
    overview: '/overview',
  },
  invoices: {
    index: '/invoices',
    details: '/invoices/details',
    new: '/invoices/new',
    overview: '/overview',
  },
  products: {
    index: '/products',
    details: '/products/details',
    overview: '/overview',
  },
  vessels: {
    index: '/vessels',
    details: '/vessels/details',
    overview: '/overview',
  },

  addresses: {
    index: '/addresses',
    details: '/addresses/details',
    overview: '/overview',
  },

  purchaseOrders: {
    index: '/purchaseOrders',
    details: '/purchaseOrders/details',
    overview: '/overview',
  },

  vendors: {
    index: '/vendors',
    details: '/vendors/details',
    overview: '/overview',
    pipeline: '/pipeline',
  },
  notifications: {
    index: '/notifications',
    details: '/notifications/details',
    overview: '/overview',
  },
  shipments: {
    index: '/shipments',
    details: '/shipments/details',
    overview: '/overview',
  },

  401: '/401',
  404: '/404',
  500: '/500',
}

import React from 'react'
import type { FC, ReactNode } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { paths } from '../../navigator/paths'

// const LayoutRoot = styled('div')(({ theme }) => ({
//   backgroundColor: theme.palette.background.default,
//   height: '100%',
//   textAlign: 'center',
//   padding: '80px',
// }))

interface LayoutProps {
  children?: ReactNode
}
// in case we are going to havea website or front page for the app (before going to  login page)
export const Layout: FC<LayoutProps> = () => {
  return <Navigate to={paths.auth.login} />
}
// <>
// <LayoutRoot>
//   {children}
//   <Navigate to={paths.auth.login} />
// </LayoutRoot>
// </>
Layout.propTypes = {
  children: PropTypes.node,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useFlattenSections } from 'src/common/hooks/use-flatten-menu-items'
import type { Settings } from '../types'
import type { User } from '../types/User'

const RULEWAVE_STORAGE_KEY = 'app.settings.rulewaveUser'
const CUSTOMER_STORAGE_KEY = 'app.settings.customerUser'

const getStorageKey = (user: User) => {
  if (!user) {
    return 'no_user_loaded_yet'
  }
  return user?.customerId ? CUSTOMER_STORAGE_KEY : RULEWAVE_STORAGE_KEY
}

const restoreSettings = (user: User): Settings | null => {
  let value = null
  const STORAGE_KEY = getStorageKey(user)

  try {
    const restored: string | null = window.localStorage.getItem(STORAGE_KEY)

    if (restored) {
      value = JSON.parse(restored)
    }
  } catch (err) {
    console.error(err)
  }

  return value
}

const deleteSettings = (user: User): void => {
  const STORAGE_KEY = getStorageKey(user)

  try {
    window.localStorage.removeItem(STORAGE_KEY)
  } catch (err) {
    console.error(err)
  }
}

const storeSettings = (value: Record<string, any>, user: User): void => {
  const STORAGE_KEY = getStorageKey(user)

  try {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value))
  } catch (err) {
    console.error(err)
  }
}
const initialSettings: Settings = {
  colorPreset: 'indigo',
  contrast: 'normal',
  direction: 'ltr',
  layout: 'vertical',
  navColor: 'evident',
  paletteMode: 'light',
  responsiveFontSizes: true,
  stretch: false,
  checkedMenuItems: [],
}

const intialSettingsIsCustomer: Settings = {
  colorPreset: 'cyan',
  layout: 'horizontal',
  navColor: 'discreet',
}

interface State extends Settings {
  openDrawer: boolean
  openMenu: boolean
  isInitialized: boolean
  stretch?: boolean
  containerWidth?: number
  isCustom: boolean
}

const getMenuOpenState = (openMenu: string | null) => {
  if (openMenu === 'true') {
    return true
  }
  if (openMenu === 'false') {
    return false
  }
  return window.innerWidth >= 1280
}

const initialState: State = {
  ...initialSettings,
  isInitialized: false,
  openMenu: getMenuOpenState(localStorage.getItem('menuOpenState')),
  openDrawer: false,
  containerWidth: 0,
  isCustom: false,
}

export interface SettingsContextType extends State {
  handleDrawerClose: () => void
  handleDrawerOpen: () => void
  handleMenuClose: () => void
  handleMenuOpen: () => void
  handleReset: () => void
  handleUpdate: (settings: Settings) => void
  isCustom: boolean
  setContainerWidth: (width: number) => void
  handleMenuItemClick: (tenant: string) => void
}

export const SettingsContext = createContext<SettingsContextType>({
  ...initialState,
  handleDrawerClose: () => {},
  handleDrawerOpen: () => {},
  handleMenuClose: () => {},
  handleMenuOpen: () => {},
  handleReset: () => {},
  handleUpdate: () => {},
  isCustom: false,
  setContainerWidth: () => {},
  handleMenuItemClick: () => {},
})

interface SettingsProviderProps {
  children?: ReactNode
  user?: User | null
}

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children, user } = props
  const checkMenuItems = useFlattenSections()

  const [state, setState] = useState<State>({
    ...initialState,
    checkedMenuItems: checkMenuItems,
  })

  useEffect(() => {
    const restored = restoreSettings(user as User)

    if (restored) {
      setState((prevState) => ({
        ...prevState,
        ...restored,
        isInitialized: true,
      }))
    }
  }, [user])

  useEffect(() => {
    if (!state.isInitialized) {
      const restored = restoreSettings(user as User)

      if (restored) {
        setState((prevState) => ({
          ...prevState,
          ...restored,
          isInitialized: true,
        }))
      } else if (user?.customerId) {
        setState((prevState) => ({
          ...prevState,
          ...intialSettingsIsCustomer,
          checkedMenuItems: checkMenuItems,
          isInitialized: true,
        }))
      } else {
        setState((prevState) => ({
          ...prevState,
          ...initialSettings,
          checkedMenuItems: checkMenuItems,
          isInitialized: true,
        }))
      }
    } else if (user?.customerId && !state.isCustom) {
      setState((prevState) => ({
        ...prevState,
        checkedMenuItems: checkMenuItems,
        isCustom: true,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.customerId, state.isInitialized, state.isCustom, checkMenuItems])

  const handleReset = useCallback((): void => {
    deleteSettings(user as User)
    setState((prevState) => ({
      ...prevState,
      ...initialSettings,
    }))
  }, [user])

  const handleUpdate = useCallback(
    (settings: Settings): void => {
      setState((prevState) => {
        const updatedState = {
          ...prevState,
          ...settings,
          isCustom: true,
        }

        storeSettings(updatedState, user as User)

        return updatedState
      })
    },
    [user],
  )

  const handleMenuItemClick = (tenant: string) => {
    const updatedCheckedMenuItems = [...(state.checkedMenuItems || [])]
    const index = updatedCheckedMenuItems?.indexOf(tenant)

    if (index === -1) {
      updatedCheckedMenuItems.push(tenant)
    } else {
      updatedCheckedMenuItems.splice(index, 1)
    }

    handleUpdate({ checkedMenuItems: updatedCheckedMenuItems })
  }

  const handleDrawerOpen = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      openDrawer: true,
    }))
  }, [])

  const handleDrawerClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      openDrawer: false,
    }))
  }, [])

  const handleMenuOpen = useCallback(() => {
    localStorage.setItem('menuOpenState', 'true')

    setState((prevState) => ({
      ...prevState,
      openMenu: true,
    }))
  }, [])

  const handleMenuClose = useCallback(() => {
    localStorage.setItem('menuOpenState', 'false')

    setState((prevState) => ({
      ...prevState,
      openMenu: false,
    }))
  }, [])

  const setContainerWidth = useCallback((width: number) => {
    setState((prevState) => ({
      ...prevState,
      containerWidth: width,
    }))
  }, [])

  const isCustom = useMemo(() => {
    return !isEqual(initialSettings, {
      colorPreset: state.colorPreset,
      contrast: state.contrast,
      direction: state.direction,
      layout: state.layout,
      navColor: state.navColor,
      paletteMode: state.paletteMode,
      responsiveFontSizes: state.responsiveFontSizes,
      stretch: state.stretch,
    })
  }, [state])

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        handleDrawerClose,
        handleDrawerOpen,
        handleMenuClose,
        handleMenuOpen,
        handleReset,
        handleUpdate,
        isCustom,
        setContainerWidth,
        handleMenuItemClick,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const SettingsConsumer = SettingsContext.Consumer

import { useEffect, useState } from 'react'
import nProgress from 'nprogress'
import { useMounted } from './use-mounted'
import { usePathname } from './use-pathname'

/* This is a custom hook that uses nprogress library to show progress indicator
    in response to page navigation events. It takes the current pathname and
    a boolean flag for mounted status and uses them to trigger and update the
    progress indicator. The hook returns nothing and simply runs on mount and unmount. */

export function useNprogress() {
  const isMounted = useMounted()
  const pathname = usePathname()
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!visible) {
      nProgress.start()
      setVisible(true)
    }

    if (visible) {
      nProgress.done()
      setVisible(false)
    }

    if (!visible && isMounted()) {
      setVisible(false)
      nProgress.done()
    }

    return () => {
      nProgress.done()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, isMounted])
}

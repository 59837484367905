/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode, cloneElement } from 'react'
import { useAuth } from '../hooks/use-auth'

interface PermissionsChecker {
  permissions: string[]
  scopes: string[]
}
interface RoleGuardProps {
  children: ReactNode
  scopes: string[]
  RenderError?: () => ReactElement
  errorProps?: any
}
interface RoleGuardHook {
  scopes: string[]
}

export const hasPermission = ({ permissions, scopes }: PermissionsChecker) => {
  const scopesMap: any = {}
  scopes.forEach((scope: string) => {
    scopesMap[scope] = true
  })

  return permissions.some((permission) => scopesMap[permission])
}

function RoleGuard({
  children,
  RenderError = () => <></>,
  errorProps = null,
  scopes = [],
}: RoleGuardProps) {
  const { permissions } = useAuth()
  const permissionGranted = hasPermission({ permissions, scopes })
  if (!permissionGranted && !errorProps) return <RenderError />
  if (!permissionGranted && errorProps) {
    const ChildrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return cloneElement(child, { ...errorProps })
      }
      return child
    })
    return <>{ChildrenWithProps}</>
  }

  return <>{children}</>
}

export function useRoleGuard({ scopes = [] }: RoleGuardHook) {
  const { permissions } = useAuth()
  return hasPermission({ permissions, scopes })
}

//This is a guard for normal users. It will render the children if the user is not a customer
export function NormalUserGuard({ children }: { children: ReactNode }) {
  const { user } = useAuth()

  if (!user?.customerId) return <>{children}</>
  return null
}

//we can use this hook to filter tabs later
// export const useFilteredTabs = (items: any) => {
//   const { permissions } = useAuth()

//   return React.useMemo(() => {
//     return items.filter((item: any) => {
//       if (item?.scopes) {
//         return hasPermission({ permissions, scopes: item.scopes })
//       }
//       return true
//     })
//   }, [items, permissions])
// }

export default RoleGuard

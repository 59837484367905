import CloseIcon from '@mui/icons-material/Close' // Import the close icon
import { Box, Checkbox, IconButton, MenuItem, Popover } from '@mui/material'
import type { FC } from 'react'
import { useContext } from 'react'
import { SettingsContext } from 'src/common/contexts/settings-context'
import { useFlattenSections } from 'src/common/hooks/use-flatten-menu-items'

interface TenantPopoverProps {
  anchorEl: null | Element
  onChange?: (tenant: string) => void
  onClose?: () => void
  open?: boolean
}

export const TenantPopover: FC<TenantPopoverProps> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { anchorEl, onClose, onChange, open = false, ...other } = props
  const { handleMenuItemClick, checkedMenuItems } = useContext(SettingsContext)
  const tenants = useFlattenSections()

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 250, opacity: 0.2 } }}
      {...other}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          pr: 1,
        }}
      >
        <IconButton edge="end" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      {tenants.map((tenant: string) => (
        <MenuItem key={tenant} onClick={() => handleMenuItemClick(tenant)}>
          <Checkbox
            size="small"
            checked={checkedMenuItems?.includes(tenant)}
            color="primary"
            sx={{ mr: 1 }}
          />{' '}
          {tenant}
        </MenuItem>
      ))}
    </Popover>
  )
}

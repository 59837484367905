import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import React from 'react'
import Button from 'src/common/components/loadingButton'

interface ConfirmDialogProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
  open: boolean
  isLoading?: boolean
  disabled?: boolean
  message: string
  buttonId: string
  type?: 'delete' | 'edit' | 'finalize' | 'unlock'
  onCancel: () => void
}

export const rejectReasons = [
  {
    value: 'Too Expensive',
    label: 'Too Expensive',
    description: 'The cost is higher than we are willing to pay.',
  },
  {
    value: 'Unfavorable Service Terms',
    label: 'Unfavorable Service Terms',
    description:
      'The proposed service levels, delivery times, or terms do not meet our needs.',
  },
  {
    value: 'Insufficient Capacity or Capability',
    label: 'Insufficient Capacity or Capability',
    description:
      'The provider cannot meet our volume, expertise, or technological needs.',
  },
  {
    value: 'Compliance Concerns',
    label: 'Compliance Concerns',
    description:
      'The provider does not meet necessary regulatory or certification requirements.',
  },
  {
    value: 'Poor Communication or Support',
    label: 'Poor Communication or Support',
    description: 'Lack of responsiveness or inadequate customer service.',
  },
  {
    value: 'Other',
    label: 'Other',
    description: '',
  },
]

const ConfirmRejectDialog: React.FC<ConfirmDialogProps> = ({
  open,
  isLoading = false,
  disabled = false,
  message = 'Are you sure?',
  buttonId,
  onCancel,
  formik,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {message}
      </DialogTitle>

      <DialogContent dividers>
        <FormControl component="fieldset">
          <RadioGroup
            name="rejectReason"
            value={formik.values.rejectReason}
            onChange={formik.handleChange}
          >
            {rejectReasons.map((reason) => (
              <FormControlLabel
                key={reason.value}
                value={reason.value}
                control={<Radio />}
                label={
                  <>
                    <small style={{ fontWeight: 'bold' }}>{reason.label}</small>
                    {reason.description && (
                      <small> - {reason.description}</small>
                    )}
                  </>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
        {formik.errors.rejectReason && !!formik.touched.rejectReason && (
          <small style={{ color: 'red' }}>{formik.errors.rejectReason}</small>
        )}
        {formik.values.rejectReason === 'Other' && (
          <TextField
            name="customReason"
            label="Please specify"
            value={formik.values.customReason}
            onChange={formik.handleChange}
            fullWidth
            margin="normal"
            helperText={
              formik.errors.customReason && !!formik.touched.customReason
                ? formik.errors.customReason
                : ''
            }
            error={formik.errors.customReason && !!formik.touched.customReason}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          id={`${buttonId}-cancel`}
          onClick={() => {
            onCancel()
            formik.resetForm()
          }}
          color="primary"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          id={`${buttonId}-confirm`}
          type="submit"
          color={'error'}
          variant="contained"
          disabled={disabled}
          isLoading={isLoading}
          onClick={formik.handleSubmit}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmRejectDialog

import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Office } from '../common/types/Office'

interface OfficeState {
  officesList: Office[]
  activeOfficeId: string
}

type GetOfficeAction = PayloadAction<string>
type GetOfficesAction = PayloadAction<Office[]>

const initialState: OfficeState = {
  officesList: [],
  activeOfficeId: '',
}

const reducers = {
  selectOffice(state: OfficeState, action: GetOfficeAction): void {
    state.activeOfficeId = action.payload
    localStorage.setItem('activeOfficeId', action.payload)
  },
  setOfficesList(state: OfficeState, action: GetOfficesAction): void {
    state.officesList = action.payload
  },
}

export const slice = createSlice({
  name: 'office',
  initialState,
  reducers,
})

export const { reducer } = slice

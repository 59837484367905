import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'

const AddressesOverviewPage = lazy(
  () => import('src/pages/backoffice/Addresses/overview'),
)

export const addressesRoutes: CustomRouteObject[] = [
  {
    path: 'addresses',
    scopes: ['address.read', 'address.write'],
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <AddressesOverviewPage />,
        scopes: ['address.read', 'address.write'],
      },
    ],
  },
]

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { lazy } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { GuestGuard } from '../../common/guards/guest-guard'
import { Layout as AuthLayout } from '../../modules/auth/classic-layout'

export enum Issuer {
  JWT = 'JWT',
}

// JWT
const JwtLoginPage = lazy(() => import('../../pages/Auth/Login/login'))
const ForgotPasswordPage = lazy(
  () => import('../../pages/Auth/ForgotPassword/forgot-password'),
)
const ResetPasswordPage = lazy(
  () => import('../../pages/Auth/ResetPassword/reset-password'),
)

const queryClient = new QueryClient()

export const authRoutes: RouteObject[] = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <QueryClientProvider client={queryClient}>
            <GuestGuard>
              <AuthLayout>
                <Outlet />
                <JwtLoginPage />
              </AuthLayout>
            </GuestGuard>
          </QueryClientProvider>
        ),
      },
      {
        path: 'forgot-password',
        element: (
          <QueryClientProvider client={queryClient}>
            <GuestGuard>
              <AuthLayout>
                <Outlet />
                <ForgotPasswordPage />
              </AuthLayout>
            </GuestGuard>
          </QueryClientProvider>
        ),
      },
    ],
  },
  {
    path: 'reset-password',
    element: (
      <QueryClientProvider client={queryClient}>
        <GuestGuard>
          <AuthLayout>
            <Outlet />
            <ResetPasswordPage />
          </AuthLayout>
        </GuestGuard>
      </QueryClientProvider>
    ),
  },
]

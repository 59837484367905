import type { FC, ReactNode } from 'react'
import { Typography, Link } from '@mui/material'

import { paths } from '../../navigator/paths'
import { GuestGuard } from 'src/common/guards/guest-guard'
import { Divider, Grid, Paper } from '@mui/material'
import { RouterLink } from 'src/common/components/router-link'

interface LayoutProps {
  children?: ReactNode
}

const TermsAndConditions = () => {
  return (
    <div>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ width: '100%', textAlign: 'center' }}
      >
        Privacy policy
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <Typography variant="subtitle1" gutterBottom sx={{ color: 'grey' }}>
        Effective Date: 01.06.2024
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ color: 'grey' }}>
        Last Updated: 01.06.2024
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ mt: 3 }}>
        Welcome to Rulewave EVO
      </Typography>
      <Typography paragraph>
        By accessing or using the Rulewave EVO application , you agree to comply
        with and be bound by the following Terms and Conditions . These Terms
        govern your use of the App and its services. If you do not agree to
        these Terms, please do not use the App.
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      <Typography paragraph>
        By creating an account or using the App, you accept these Terms and our
        Privacy Policy. If you do not agree to these Terms, please do not use
        the App.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. User Data and Privacy
      </Typography>
      <Typography paragraph>
        Your privacy is important to us. Our Privacy Policy outlines how we
        collect, use, and protect your personal data in compliance with the
        General Data Protection Regulation (GDPR). By using the App, you agree
        to the collection and use of your data as described in our Privacy
        Policy.
      </Typography>

      <Typography paragraph>
        <strong>Key Points:</strong>
      </Typography>
      <Typography component="ul">
        <Typography component="li">
          <strong>Data Collection:</strong> We collect personal data such as
          your name, email address, and usage data to provide and improve our
          Services.
        </Typography>
        <Typography component="li">
          <strong>Data Use:</strong> Your data is used to personalize your
          experience, improve our Services, and comply with legal obligations.
        </Typography>
        <Typography component="li">
          <strong>Data Sharing:</strong> We do not sell your personal data to
          third parties. We may share data with service providers who assist in
          delivering our Services under strict confidentiality agreements.
        </Typography>
        <Typography component="li">
          <strong>Your Rights:</strong> You have the right to access, rectify,
          erase, restrict, and object to the processing of your data. You also
          have the right to data portability and to lodge a complaint with a
          supervisory authority.
        </Typography>
        <Typography component="li">
          <strong>Data Security:</strong> We implement appropriate technical and
          organizational measures to protect your personal data against
          accidental or unlawful destruction, loss, alteration, unauthorized
          disclosure, or access.
        </Typography>
        <Typography component="li">
          <strong>Retention Period:</strong> We will retain your personal data
          only as long as necessary to fulfill the purposes for which it was
          collected, comply with legal obligations, resolve disputes, and
          enforce our agreements.
        </Typography>
        <Typography component="li">
          <strong>International Transfers:</strong> Your personal data may be
          transferred to and processed in countries other than the country in
          which you are resident. These countries may have data protection laws
          that are different from the laws of your country.
        </Typography>
        <Typography component="li">
          <strong>Children`s Privacy:</strong> Our Services are not intended for
          use by children under the age of 16, and we do not knowingly collect
          personal data from children under 16. If we become aware that a child
          under 16 has provided us with personal data, we will take steps to
          delete such information.
        </Typography>
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. User Obligations
      </Typography>
      <Typography paragraph>
        - <strong>Accurate Information:</strong> You agree to provide accurate
        and complete information when creating an account and using the App.
      </Typography>
      <Typography paragraph>
        - <strong>Compliance:</strong> You agree to use the App in compliance
        with all applicable laws and regulations, including GDPR.
      </Typography>
      <Typography paragraph>
        - <strong>Prohibited Actions:</strong> You agree not to engage in any
        activity that disrupts or interferes with the App, infringes on the
        rights of others, or violates any law.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Account Security
      </Typography>
      <Typography paragraph>
        You are responsible for maintaining the confidentiality of your account
        information and for all activities that occur under your account. Notify
        us immediately of any unauthorized use of your account.
      </Typography>
      <Typography paragraph>
        You agree to use the email services provided by Rulewave EVO in a
        responsible manner. This includes:
      </Typography>
      <Typography paragraph>
        - <strong>No Spam:</strong> You agree not to send unsolicited emails,
        also known as spam. All email communications must comply with applicable
        laws, including the GDPR and the EU Directive on Privacy and Electronic
        Communications.
      </Typography>
      <Typography paragraph>
        - <strong>Email Content:</strong> You agree to ensure that all email
        content is appropriate, does not contain harmful or misleading
        information, and respects the privacy and rights of recipients.
      </Typography>
      <Typography paragraph>
        - <strong>Opt-Out Mechanism:</strong> You agree to include a clear and
        easy-to-use opt-out mechanism in all marketing emails, allowing
        recipients to unsubscribe from future communications.
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Intellectual Property
      </Typography>
      <Typography paragraph>
        All content and materials within the App, including but not limited to
        text, graphics, logos, and software, are the property of Rulewave EVO or
        its licensors and are protected by intellectual property laws.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Modifications to Terms
      </Typography>
      <Typography paragraph>
        We reserve the right to modify these Terms at any time. We will notify
        you of any changes by posting the updated Terms on the App and updating
        the &ldquo;Last Updated&ldquo; date. Your continued use of the App after
        any modifications indicates your acceptance of the new Terms.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Termination
      </Typography>
      <Typography paragraph>
        We may terminate or suspend your access to the App at any time, without
        prior notice, for conduct that we believe violates these Terms or is
        harmful to other users of the App or us.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Limitation of Liability
      </Typography>
      <Typography paragraph>
        To the fullest extent permitted by law, Rulewave EVO shall not be liable
        for any indirect, incidental, special, consequential, or punitive
        damages, or any loss of profits or revenues, whether incurred directly
        or indirectly, or any loss of data, use, goodwill, or other intangible
        losses, resulting from
      </Typography>
      <Typography component="ul">
        <Typography component="li">
          Your use or inability to use the App;
        </Typography>
        <Typography component="li">
          Any unauthorized access to or use of our servers and/or any personal
          information stored therein;
        </Typography>
        <Typography component="li">
          Any interruption or cessation of transmission to or from the App;
        </Typography>
        <Typography component="li">
          Any bugs, viruses, trojan horses, or the like that may be transmitted
          to or through the App by any third party; or
        </Typography>
        <Typography component="li">
          Any errors or omissions in any content or for any loss or damage
          incurred as a result of the use of any content posted, emailed,
          transmitted, or otherwise made available through the App.
        </Typography>
      </Typography>
      <Typography variant="h6" gutterBottom>
        9. Governing Law
      </Typography>
      <Typography paragraph>
        These Terms shall be governed by and construed in accordance with the
        laws of Netherlands, without regard to its conflict of law principles.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. Contact Us
      </Typography>
      <Typography paragraph>
        If you have any questions about these Terms or the Privacy Policy,
        please contact us at info@rulewave.com.
      </Typography>
    </div>
  )
}

// in case we are going to havea website or front page for the app (before going to  login page)
export const Layout: FC<LayoutProps> = () => {
  return (
    <GuestGuard>
      <Grid container display={'flex'} justifyContent={'center'}>
        <Grid item sm={8}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Link
              color="primary"
              component={RouterLink}
              href={paths.purchaseOrders.index}
              sx={{
                alignItems: 'center',
                display: 'inline-flex',
              }}
              underline="hover"
            >
              <Typography variant="subtitle2">Back to Login</Typography>
            </Link>
            <TermsAndConditions />
          </Paper>
        </Grid>
      </Grid>
    </GuestGuard>
  )
}

export default Layout

import { Box } from '@mui/material'
import { NumericFormatText } from './form/RenderField'
import { useGetCurrencySymbol } from '../hooks/use-currency-sign'

interface InvoiceComponentProps {
  total: number
  invoiceCurrency: string
  conversionRate: number
}

const TotalWithDifferentCurrency = ({
  total,
  invoiceCurrency,
  conversionRate = 1,
}: InvoiceComponentProps) => {
  const officeData = JSON.parse(localStorage.getItem('activeOffice') || '{}')
  const defaultCurrency = officeData?.currency
  const totalInDefaultCurrency = total * conversionRate
  const currencySymbol = useGetCurrencySymbol(invoiceCurrency)
  const defaultCurrencySymbol = useGetCurrencySymbol(defaultCurrency)

  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignContent={'right'}
      justifyContent={'right'}
      flexDirection={'column'}
    >
      <NumericFormatText
        value={total}
        prefix={' ' + currencySymbol}
        fixedDecimalScale={true}
      />
      {defaultCurrency !== invoiceCurrency && (
        <NumericFormatText
          value={totalInDefaultCurrency}
          prefix={' ' + defaultCurrencySymbol}
          fixedDecimalScale={true}
          sx={{ color: 'grey' }}
        />
      )}
    </Box>
  )
}

export default TotalWithDifferentCurrency

import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'

const PurchaseOrdersOverviewPage = lazy(
  () => import('src/pages/backoffice/Operations/PurchaseOrders/overview'),
)
const PurchaseOrdersDetailsPage = lazy(
  () => import('src/pages/backoffice/Operations/PurchaseOrders/detail'),
)

export const purchaseOrdersRoutes: CustomRouteObject[] = [
  {
    path: 'purchaseOrders',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <PurchaseOrdersOverviewPage />,
        scopes: ['purchaseorder.read', 'purchaseorder.write'],
      },
      {
        path: 'details/:id',
        element: <PurchaseOrdersDetailsPage />,
        scopes: ['purchaseorder.read', 'purchaseorder.write'],
      },
    ],
  },
]

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { StaticInputDatePicker } from 'src/common/components/datepicker'
import Button from 'src/common/components/loadingButton'

interface ConfirmDialogProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
  open: boolean
  isLoading?: boolean
  disabled?: boolean
  message: string
  buttonId: string
  type?: 'delete' | 'edit' | 'finalize' | 'unlock'
  onCancel: () => void
  handleConfirm: () => void
}

const ConfirmExtendDateDialog: React.FC<ConfirmDialogProps> = ({
  open,
  message = 'Are you sure?',
  onCancel,
  formik,
  buttonId,
  isLoading,
  disabled,
  handleConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 600 } }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {message}
      </DialogTitle>

      <DialogContent dividers>
        <StaticInputDatePicker
          formik={formik}
          field={{
            name: 'validUntil',
            label: 'Valid Until',
            type: 'staticDate',
            allowPastDates: false,
            allowFutureDates: true,
          }}
          onClose={onCancel}
        />
      </DialogContent>
      <DialogActions>
        <Button
          id={`${buttonId}-cancel`}
          onClick={() => {
            onCancel()
            formik.resetForm()
          }}
          color="primary"
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          id={`${buttonId}-confirm`}
          type="submit"
          color={'error'}
          variant="contained"
          disabled={disabled}
          isLoading={isLoading}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmExtendDateDialog

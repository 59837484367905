import { lazy, Suspense } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'
const IndexPage = lazy(() => import('src/pages/customer/Shipments/overview'))

export const shipmentsRoutes: RouteObject[] = [
  {
    path: 'shipments',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
    ],
  },
]

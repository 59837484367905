import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'

const LeadsOverviewPage = lazy(
  () => import('src/pages/backoffice/Sales/Leads/overview'),
)
const LeadsDeailsPage = lazy(
  () => import('src/pages/backoffice/Sales/Leads/details'),
)

export const leadRoutes: CustomRouteObject[] = [
  {
    path: 'leads',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <LeadsOverviewPage />,
        scopes: ['lead.read', 'lead.write'],
      },
      {
        path: ':id',
        element: <LeadsDeailsPage />,
        scopes: ['lead.read', 'lead.write'],
      },
    ],
  },
]

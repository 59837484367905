/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-hot-toast'
import {
  MeRequest,
  MeResponse,
  OfficeRequest,
  LoginValues as SignInRequest,
  SignInResponse,
} from 'src/common/types/Login'
import {
  fogotPasswordPost,
  get,
  resetPasswordPost,
  signInPost,
} from 'src/common/utils/api'

export type RoleWithPermissions = {
  id: string
  name: string
  permissions: string[]
  createdBy: string
  createAt: string
}

type ResetPasswordRequest = {
  password: string
  token: string
}

type ResetPasswordResponse = Promise<
  | {
      email: string
    }
  | any
>

class AuthApi {
  async signIn(request: SignInRequest): SignInResponse {
    const { email, password } = request

    return signInPost('auth/login', {
      email,
      password,
    })
      .then(async function (res) {
        return {
          status: res.status,
          headers: res.headers,
          body: await res.json(),
        }
      })
      .then(async (res: any) => {
        if (res.status === 200)
          return {
            accessToken: res.headers.get('x-auth-token'),
          }
        else
          throw {
            ...res,
            message: res.body.errors[0],
          }
      })
      .catch((e) => {
        throw {
          message: e.message || 'Server error, not able to log in right now!',
          details: e,
        }
      })
  }

  me(request: MeRequest): MeResponse {
    const { accessToken } = request
    if (!accessToken) return Promise.reject('Not Authorised')

    return get(`users/me`).then((response) => {
      const { user, office, rolesWithPermissions } = response.data

      return {
        user: {
          ...user,
          token: accessToken,
        },
        office: office,
        rolesWithPermissions,
      }
    })
  }
  getOfficeDetails({ id }: OfficeRequest) {
    return get(`offices/${id}`).then((response) => {
      return response.data
    })
  }

  async forgotPassword(email: string): Promise<void> {
    fogotPasswordPost('auth/recover-password', {
      email,
    })
      .then((res: any) => {
        res.ok
          ? toast.success(
              'Password reset successful. Reset link will be recieved within 10 minutes on your email.',
            )
          : toast.error('Failed to send password reset link!')
      })
      .catch((e) => {
        console.error(e)
        toast.error('Failed to send password reset link!')
      })
  }

  async resetPassword({
    password,
    token,
  }: ResetPasswordRequest): ResetPasswordResponse {
    resetPasswordPost('users/password-reset', {
      password,
      token,
    })
      .then((res: any) => {
        res.ok
          ? toast.success('Password was reset succesfuly.', {
              duration: 6000,
            })
          : toast.error('Failed to set new password!', {
              duration: 6000,
            })
      })
      .catch((e) => {
        console.error(e)
        toast.error('Failed to set new password!', {
          duration: 6000,
        })
      })
  }
}

export const authApi = new AuthApi()

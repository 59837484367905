import { useState, useEffect } from 'react'

const dateFormatMapper: { [key: string]: string } = {
  'd/m/Y': 'dd-MM-yyyy',
  'm/d/Y': 'MM-dd-yyyy',
  'Y/m/d': 'yyyy-MM-dd',
}

export function useGetOfficeDateFormat() {
  const [dateFormat, setDateFormat] = useState('')

  useEffect(() => {
    const handleStorageChange = () => {
      const activeOffice = JSON.parse(
        localStorage.getItem('activeOffice') || '{}',
      )

      if (activeOffice && activeOffice.dateFormat) {
        setDateFormat(dateFormatMapper[activeOffice?.dateFormat])
      } else {
        setDateFormat('dd-MM-yyyy')
      }
    }

    handleStorageChange()
    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  return { dateFormat }
}

import Cookies from 'js-cookie'

const COOKIE_NAME = 'jwt'

export const setJwtCookie = (token: string) => {
  Cookies.set(COOKIE_NAME, token, { expires: 7 })
}

export const deleteJwtCookie = () => {
  Cookies.remove(COOKIE_NAME)
}

export const getJwtFromCookie = () => {
  return Cookies.get(COOKIE_NAME)
}

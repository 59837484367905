import { Chip } from '@mui/material'

type ReferenceTypes =
  | 'file'
  | 'invoice'
  | 'quote'
  | 'shipment'
  | 'purchase_number'
  | 'other'

type Colors =
  | 'secondary'
  | 'info'
  | 'warning'
  | 'default'
  | 'primary'
  | 'error'
  | 'success'

interface ReferenceProps {
  type: ReferenceTypes
  reference: string
  hideTitle?: boolean
  color?: Colors
  size?: 'small' | 'medium'
  onClick?: (event: string) => void
}

const colors: Record<ReferenceTypes, Colors> = {
  file: 'secondary',
  invoice: 'info',
  quote: 'warning',
  shipment: 'primary',
  purchase_number: 'success',
  other: 'default',
}

export const Reference = (props: ReferenceProps) => {
  const { type, reference, hideTitle = false, color, size, onClick } = props
  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1)

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Reference copied to clipboard')
      },
      (err) => {
        console.error('Failed to copy the reference to clipboard: ', err)
      },
    )
  }

  return (
    <Chip
      color={color || colors[type]}
      variant="filled"
      label={`${hideTitle ? '' : capitalizeFirstLetter(type)} #${reference}`}
      onClick={() =>
        onClick ? onClick(reference) : copyToClipboard(reference)
      }
      title="Click to copy reference"
      style={{ cursor: 'pointer' }}
      size={size}
    />
  )
}

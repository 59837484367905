import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'

const ContactsOverviewPage = lazy(
  () => import('src/pages/backoffice/CRM/Contacts/overview'),
)
// const ContactsDeailsPage = lazy(
//   () => import('src/pages/CRM/Contacts/details'),
// )

export const contactsRoutes: CustomRouteObject[] = [
  {
    path: 'contacts',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <ContactsOverviewPage />,
        scopes: ['contact.read', 'contact.write'],
      },
      // {
      //   path: ':id',
      //   element: <ContactsDeailsPage />,
      // },
    ],
  },
]

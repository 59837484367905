import { Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'
import InvoicesList from 'src/pages/backoffice/Finance/Invoices/overview'
import InvoicePage from 'src/modules/invoices/details-page'
import BatchPrint from 'src/modules/invoices/batch-print'
import PreviewPage from 'src/modules/invoices/preview-page'

export const invoiceRoutes: CustomRouteObject[] = [
  {
    path: 'invoices',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        scopes: ['invoice.read', 'invoice.write'],
        element: <InvoicesList />,
      },
      {
        path: 'new',
        element: <InvoicePage />,
        scopes: ['invoice.read', 'invoice.write'],
      },
      {
        path: ':id',
        element: <InvoicePage />,
        scopes: ['invoice.read', 'invoice.write'],
      },
      {
        path: 'print',
        element: <BatchPrint />,
        scopes: ['invoice.read', 'invoice.write'],
      },
      {
        path: 'preview/:fileId/:invoiceId',
        element: <PreviewPage />,
        scopes: ['invoice.read'],
      },
    ],
  },
]

import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'
import CreateRole from '../../../modules/roles/create-role'
import EditRole from '../../../modules/roles/edit-role'

const RolesOverviewPage = lazy(
  () => import('src/pages/backoffice/Roles/overview'),
)

export const rolesRoutes: CustomRouteObject[] = [
  {
    path: 'roles',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <RolesOverviewPage />,
        scopes: ['role.read', 'role.write'],
      },
      {
        path: 'details/:roleId',
        scopes: ['role.write'],
        element: <EditRole />,
      },
      {
        path: 'create',
        element: <CreateRole />,
        scopes: ['role.write'],
      },
    ],
  },
]

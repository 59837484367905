import React from 'react'
import { Box, Container, Typography } from '@mui/material'
//import SignalCellularNoSimOutlinedIcon from '@mui/icons-material/SignalCellularNoSimOutlined'
//import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'
//import SubtitlesOffOutlinedIcon from '@mui/icons-material/SubtitlesOffOutlined'
//import WebAssetOffOutlinedIcon from '@mui/icons-material/WebAssetOffOutlined'

interface NoRecordsFoundProps {
  message?: string
}

export const NoRecordsFound = ({ message }: NoRecordsFoundProps) => {
  message = message || 'No records found'

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',

              mb: 2,
            }}
          >
            {/* <Box
              alt="Not found"
              component="img"
              src="/assets/errors/error-404.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 50,
              }}
            /> */}

            {/* <SignalCellularNoSimOutlinedIcon
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 50,
              }}
            /> */}

            {/* <CancelPresentationOutlinedIcon
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 50,
              }}
            /> */}

            {/* <SubtitlesOffOutlinedIcon
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 50,
              }}
            /> */}

            {/* <WebAssetOffOutlinedIcon
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 50,
              }}
            /> */}
          </Box>
          <Typography align="center" variant={'body1'}>
            {message}
          </Typography>
        </Container>
      </Box>
    </>
  )
}

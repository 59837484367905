/* eslint-disable @typescript-eslint/no-explicit-any */
import { useInfiniteQuery } from '@tanstack/react-query'
import { SortingState } from '@tanstack/react-table'

interface InfiniteQuery {
  key: string
  fetchData: (
    start: number,
    fetchSize: number,
    sorting?: SortingState,
    filters?: string,
    generalSearch?: string,
    useSearch?: boolean,
  ) => Promise<{ data: any[]; totalRowCount: number }>
  fetchSize: number
  sorting: SortingState
  filters?: string
  generalSearch: string
  useSearch?: boolean
}
interface QueryKeyesIndexes {
  key: string
  sorting: any
  filters?: string
  generalSearch: string
}
interface Response {
  data: any[]
  totalRowCount: number
  totalFilteredItems?: number
  totalPages?: number
  currentPage?: number
  useSearch?: boolean
}
function createQueryKey({
  key,
  sorting,
  filters,
  generalSearch,
}: QueryKeyesIndexes) {
  const queryKey = [key]

  if (sorting && sorting.length > 0) {
    queryKey.push(sorting[0])
  } else {
    queryKey.push('')
  }

  if (filters && filters !== '' && filters !== '&') {
    queryKey.push(filters)
  } else {
    queryKey.push('')
  }

  if (generalSearch && generalSearch !== '') {
    queryKey.push(generalSearch)
  } else {
    queryKey.push('')
  }

  return queryKey
}

export const useInfiniteData = ({
  key,
  fetchData,
  fetchSize = 10,
  sorting,
  filters,
  generalSearch,
  useSearch = true,
}: InfiniteQuery) => {
  const queryKey = createQueryKey({ key, sorting, filters, generalSearch })
  // Use the useInfiniteQuery hook to fetch data in a paginated manner.
  const { data, fetchNextPage, isFetching, isLoading, refetch } =
    useInfiniteQuery<Response>(
      queryKey,
      async ({ pageParam }) => {
        // if (typeof pageParam === Boolean && !pageParam)
        //   return Promise.resolve({ data: [], totalRowCount: 0 })
        // Fetch data using the provided fetchData function and pass the start, fetchSize, and sorting parameters.
        return fetchData(
          pageParam,
          fetchSize,
          sorting,
          filters && filters !== '&' ? encodeURIComponent(filters) : undefined,
          generalSearch,
          useSearch,
        )
      },
      {
        getNextPageParam: (lastPage) => {
          if (!lastPage.currentPage) return 1
          if (lastPage.currentPage < (lastPage.totalPages || 0))
            return lastPage.currentPage + 1
          return false
        },

        // Keep the previous data while fetching new data to avoid UI glitches.
        keepPreviousData: true,
        // Disable refetching data when the window gets focus.
        refetchOnWindowFocus: true,
      },
    )
  // Return the data, fetchNextPage function, isFetching state, and isLoading state.
  return {
    data,
    fetchNextPage,
    isFetching,
    isLoading,
    refetch,
    sorting,
    filters,
    generalSearch,
  }
}

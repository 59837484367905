import React from 'react'
import type { FC } from 'react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import {
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import { Scrollbar } from 'src/common/components/scrollbar'
import { Notification } from 'src/slices/NotificationSlice'
import { iconsMap } from './notifications-icons'
import { RouterLink } from 'src/common/components'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import { useAuth } from 'src/common/hooks/use-auth'
import { useGetNotificationsQuery } from 'src/modules/notifications/useNotificationsRequests'

export const renderContent = (
  notification: Notification,
): JSX.Element | null => {
  const createdAt = format(new Date(), 'MMM dd, h:mm a')

  switch (notification.status) {
    case 'success': {
      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              {notification.type === 'mention' ? (
                <SvgIcon>
                  <ChatBubbleOutlineIcon />
                </SvgIcon>
              ) : (
                iconsMap[notification.objectType]
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box>
                <Typography
                  sx={{ mr: 0.5 }}
                  variant="subtitle2"
                  fontWeight="bold"
                  display="block"
                >
                  {notification.objectType?.charAt(0)?.toUpperCase() +
                    notification?.objectType?.slice(1)}
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ mr: 0.5 }} variant="body2">
                    {notification.message}
                  </Typography>
                  {notification.type === 'pdf-generation' && (
                    <Link
                      href={notification.data.pdfUrl}
                      underline="always"
                      variant="body2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SvgIcon fontSize="small">
                        <DownloadIcon />
                      </SvgIcon>
                    </Link>
                  )}
                </Stack>
              </Box>
            }
            secondary={
              <Typography color="text.secondary" variant="caption">
                {createdAt}
              </Typography>
            }
            sx={{ my: 0 }}
          />
        </>
      )
    }
    case 'error': {
      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              <SvgIcon>
                {notification.type === 'mention' ? (
                  <SvgIcon>
                    <ChatBubbleOutlineIcon />
                  </SvgIcon>
                ) : (
                  iconsMap[notification.objectType]
                )}
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Box>
                <Typography
                  sx={{ mr: 0.5 }}
                  variant="subtitle2"
                  fontWeight="bold"
                  display="block"
                >
                  {notification.objectType?.charAt(0)?.toUpperCase() +
                    notification.objectType?.slice(1)}
                </Typography>
                <Typography sx={{ mr: 0.5 }} variant="body2">
                  {notification.message}
                </Typography>
              </Box>
            }
            secondary={
              <Typography color="text.secondary" variant="caption">
                {createdAt}
              </Typography>
            }
            sx={{ my: 0 }}
          />
        </>
      )
    }
    default:
      return null
  }
}

interface NotificationsPopoverProps {
  anchorEl: null | Element
  notifications: Notification[]
  onClose?: () => void
  open?: boolean
}

export const NotificationsPopover: FC<NotificationsPopoverProps> = (props) => {
  const { anchorEl, onClose, open = false, ...other } = props
  const { user } = useAuth()
  const activeOfficeId = localStorage.getItem('activeOfficeId')
  const notifications = useGetNotificationsQuery({
    officeId: activeOfficeId,
    userid: user?.id,
  })

  const isEmpty = notifications?.data?.length ? false : true

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 380 } }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Typography color="inherit" variant="h6">
          Notifications
        </Typography>
        <Link
          color="primary.main"
          component={RouterLink}
          href={`/notifications`}
          variant="subtitle2"
        >
          View all
        </Link>
      </Stack>
      {isEmpty ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" textAlign="center">
            There are no notifications
          </Typography>
        </Box>
      ) : (
        <Scrollbar
          sx={{
            maxHeight: 400,
            '& .simplebar-placeholder': {
              display: 'none',
            },
          }}
        >
          <List disablePadding>
            {notifications?.data?.map(
              (notification: Notification, index: number) => (
                <ListItem
                  divider={index !== notifications?.data?.length - 1}
                  key={notification.data?.objectId + notification.data?.pdfUrl}
                  sx={{
                    backgroundColor:
                      notification.status === 'success' ? '' : 'error.light',
                    alignItems: 'flex-start',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                    '& .MuiListItemSecondaryAction-root': {
                      top: '24%',
                    },
                  }}
                >
                  {renderContent(notification)}
                </ListItem>
              ),
            )}
          </List>
        </Scrollbar>
      )}
    </Popover>
  )
}

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

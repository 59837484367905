import { Box, IconButton, Stack, SvgIcon, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { usePopover } from 'src/common/hooks/use-popover'
import { TenantPopover } from './tenant-popover'
import { useAuth } from 'src/common/hooks/use-auth'

interface TenantSwitchProps {
  sx?: SxProps
}

export const TenantSwitch: FC<TenantSwitchProps> = (props) => {
  const popover = usePopover<HTMLButtonElement>()
  const { user } = useAuth()

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} {...props}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography color="inherit" variant="h6">
            Rulewave Evo
          </Typography>
        </Box>
        {!user?.customerId && (
          <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
            <SvgIcon sx={{ fontSize: 16 }}>
              <ChevronDownIcon />
            </SvgIcon>
          </IconButton>
        )}
      </Stack>
      <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={popover.handleClose}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  )
}

TenantSwitch.propTypes = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sx: PropTypes.object,
}

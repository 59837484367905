/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useCustomerSections,
  useSections,
} from 'src/layout/navigation/menuConfig'
import { useAuth } from './use-auth'

export function useFlattenSections() {
  const sections = useSections()
  const customerSections = useCustomerSections()
  const { user } = useAuth()

  const extractTitles = (sections: any[]) =>
    sections?.flatMap((section) =>
      section?.items?.map((item: any) => item.title),
    ) ?? []

  return user?.customerId
    ? extractTitles(customerSections)
    : extractTitles(sections)
}

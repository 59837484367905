/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Locale,
  format,
  isAfter,
  isBefore,
  isSameDay,
  isValid,
  subDays,
} from 'date-fns'
import locale from 'date-fns/locale/en-US'

const formatDistanceLocale: Record<string, string> = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y',
}

export const customLocale: Locale = {
  ...locale,
  formatDistance: (token, count, options) => {
    options = options || {}

    const result = formatDistanceLocale[token].replace('{{count}}', count)

    if (options.addSuffix) {
      if (options.comparison > 0) {
        return 'in ' + result
      } else {
        return result + ' ago'
      }
    }

    return result
  },
}

export const formatDateTime = (occurredAt?: string): string => {
  const dateTime = occurredAt ? new Date(occurredAt) : new Date()
  return format(dateTime, "yyyy-MM-dd'T'HH:mm")
}

export const formatDate = (
  occurredAt?: string,
  dateFormat?: string,
): string => {
  const dateTime = occurredAt ? new Date(occurredAt) : new Date()
  return format(dateTime, dateFormat || 'yyyy-MM-dd')
}

export const formatDateOrNull = (
  occurredAt?: string | null,
  dateFormat?: string,
): string => {
  if (!occurredAt || occurredAt === 'N/A') {
    return ''
  }
  const dateTime = new Date(occurredAt)
  return format(dateTime, dateFormat || 'yyyy-MM-dd')
}

export const formatDateMonthYear = (occurredAt?: string): string => {
  try {
    const dateTime = occurredAt ? new Date(occurredAt) : new Date()

    if (!isValid(dateTime)) {
      throw new Error('Invalid date')
    }

    return format(dateTime, 'MM/yyyy')
  } catch (error: any) {
    console.error(error.message)
    return 'Invalid date format'
  }
}

type Color =
  | 'warning'
  | 'error'
  | 'disabled'
  | 'action'
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'info'

interface DateStatus {
  color: Color
  message: string
  isValid: boolean
}

export const calculateDateStatus = (date: string): DateStatus => {
  const currentDate = new Date()
  const targetDate = new Date(date)

  if (
    isBefore(targetDate, currentDate) &&
    !isSameDay(targetDate, currentDate)
  ) {
    return { color: 'error', message: 'Not valid anymore', isValid: false }
  }

  const warningDate = subDays(targetDate, 2)
  if (
    isSameDay(targetDate, currentDate) ||
    (isBefore(currentDate, targetDate) && isAfter(currentDate, warningDate))
  ) {
    return { color: 'warning', message: 'About to expire', isValid: true }
  }

  return { color: 'disabled', message: 'Active', isValid: true }
}

export const isValidDate = (dateString: string) => {
  // Create a regular expression to match the ISO 8601 date format
  const isoDatePattern =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\+(\d{2}):(\d{2})$/

  // Test the input string against the regular expression
  if (!isoDatePattern.test(dateString)) {
    return false // If it doesn't match the format, return false
  }

  // Attempt to create a Date object from the string
  const date = new Date(dateString)

  // Check if the Date object is valid
  return !isNaN(date.getTime())
}

export const isInThePast = (date: Date): boolean => {
  return isBefore(new Date(date), new Date())
}

import { Stack } from '@mui/material'
import { useGetUsers } from 'src/common/apis'
import AutocompleteWithCreate from 'src/common/components/autocomplete/common/autocomplete-formik'
import SelectStatusInput from 'src/common/components/autocomplete/status'
import RenderField from 'src/common/components/form/RenderField'
import { Option } from 'src/common/components/grid/types'
import {
  CUSTOMER_STATUS,
  Status,
  StatusKey,
  StatusValue,
} from 'src/common/components/statuses'
import { CustomersOptionType as OptionType } from 'src/common/types/Customer'
import { FormProps } from 'src/common/types/common'

const CustomerForm = ({ formik }: FormProps) => {
  const accountsQuery = useGetUsers({})
  const statusArray: Option[] = Object.entries(CUSTOMER_STATUS).map(
    ([key, value]) => ({
      id: key,
      key: key,
      value: key as StatusValue,
      label: <Status key={key as StatusKey} status={value as StatusValue} />,
    }),
  )
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <RenderField
          field={{
            label: 'Name *',
            name: 'name',
            type: 'text',
          }}
          formik={formik}
        />
        <SelectStatusInput
          {...formik}
          label="Status *"
          name="status"
          value={formik.values.status || ''}
          statusOptions={statusArray}
        />

        <RenderField
          field={{
            label: 'Website',
            name: 'website',
            type: 'text',
          }}
          formik={formik}
        />
        <AutocompleteWithCreate
          formik={formik}
          options={(accountsQuery?.data as OptionType[]) || []}
          multiple={false}
          readOnly={false}
          name="accountManager"
          label="Account Manager"
          isLoading={accountsQuery.isLoading}
        />
        <RenderField
          field={{
            label: 'Payment terms *',
            name: 'paymentTerms',
            type: 'number',
          }}
          formik={formik}
          suffix="days"
        />
      </Stack>
    </form>
  )
}

export default CustomerForm

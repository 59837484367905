/* eslint-disable @typescript-eslint/no-explicit-any */
import PlusIcon from '@mui/icons-material/Add'
import { CircularProgress, MenuItem, Popper, TextField } from '@mui/material'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useTheme } from '@mui/material/styles'
import { get } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useModal } from 'src/common/hooks/use-modal'
import { Option, OptionKey } from '../../grid/types'
import { StaticAutocompleteWithCreateProps } from './types'

// This autocomplete expects value as id or array of ids
const StaticAutocomplete = ({
  identifier = 'id' as OptionKey,
  formik,
  name = '',
  label,
  options = [],
  readOnly = false,
  allowCreate = true,
  isLoading = false,
  size = 'medium',
  ModalComponent,
  modalProps,
  multiple = false,
  placeholder,
  onModalSubmit,
  variant = 'outlined',
  onChange,
  ...otherProps
}: StaticAutocompleteWithCreateProps) => {
  const value = get(formik.values, name)
  const theme = useTheme()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const allOptions = allowCreate
    ? [
        {
          value: 'create-new-option',
          label: 'Add new',
          id: 'autocomplete-new',
        },
        ...options,
      ]
    : options

  const inputRef = useRef<HTMLInputElement | null>(null)

  const { open, handleOpen, handleClose: handleModalClose } = useModal()

  const [newItem, setNewItem] = useState<Option | null>(null)
  const [isDownOrUpKeyPressed, setisDownOrUpKeyPressed] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([])

  const submitCallback = async (newSavedItem: any) => {
    if (multiple) {
      formik.setFieldValue(name, [...value, newSavedItem[identifier]])
    } else {
      formik.setFieldValue(name, newSavedItem[identifier])
    }
    onModalSubmit && onModalSubmit(newSavedItem)
  }

  const handleOpenCreateNewItem = () => {
    setNewItem(null)
    handleOpen()
    inputRef.current && inputRef.current.blur()
  }

  const filterOptionsFn = createFilterOptions({
    stringify: (option: Option) =>
      typeof option?.label === 'string'
        ? option?.label
        : option?.value?.toString(),
    matchFrom: 'any',
    limit: Infinity,
  })

  useEffect(() => {
    if (!newItem) return

    if (multiple) {
      const valuesArray = []

      if (value) {
        valuesArray.push(value)
      }
      if (newItem.value) valuesArray.push(newItem.value)

      formik.setFieldValue(name, valuesArray)
    } else {
      formik.setFieldValue(name, newItem.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.length, isLoading])

  const showValidationError = !!(
    (formik.submitCount || get(formik.touched, name)) &&
    get(formik.errors, name)
  )

  // Utility function to compare two arrays of options
  const areOptionsEqual = (optionsA: Option[], optionsB: Option[]) => {
    if (optionsA.length !== optionsB.length) return false
    return optionsA.every(
      (option, index) => option.value === optionsB[index].value,
    )
  }

  return (
    <div ref={containerRef}>
      {open && ModalComponent && (
        <ModalComponent
          open={open}
          handleClose={handleModalClose}
          submitCallback={submitCallback}
          multiple={multiple}
          {...modalProps}
        />
      )}
      <Autocomplete
        id={name + '-autocomplete'}
        PopperComponent={(props: any) => (
          <div id={'autocomplete-container-' + name}>
            <Popper
              {...props}
              container={containerRef.current}
              onMouseEnter={() => setisDownOrUpKeyPressed(false)}
            />
          </div>
        )}
        placeholder={placeholder}
        disabled={isLoading || readOnly}
        multiple={multiple}
        options={allOptions}
        getOptionLabel={(option: Option) =>
          typeof option?.label === 'string'
            ? option?.label
            : option?.value?.toString()
        }
        isOptionEqualToValue={(option, selectedOption) => {
          return option?.value === selectedOption?.value
        }}
        autoSelect={isDownOrUpKeyPressed}
        autoHighlight
        openOnFocus
        value={
          multiple
            ? (value || [])?.map((value: string) =>
                options?.find((option: Option) => option.value === value),
              ) ?? []
            : options?.find((option: Option) => option.value === value) || null
        }
        size={size}
        onChange={(_, newValue: Option[] | Option | null) => {
          if (multiple) {
            const validItems = Array.isArray(newValue)
              ? newValue?.filter(
                  (item: Option) => item.value !== 'create-new-option',
                )
              : []

            formik.setFieldValue(
              name,
              validItems.map((item: Option) => item[identifier]),
            )
            onChange && onChange(multiple ? validItems : validItems[0] || null)
          } else {
            if (
              newValue &&
              !Array.isArray(newValue) &&
              newValue.value !== 'create-new-option'
            ) {
              formik.setFieldValue(name, newValue[identifier])
              onChange && onChange(newValue[identifier] || null)
            } else {
              formik.setFieldValue(name, '')
              onChange && onChange(null)
            }
          }
          setisDownOrUpKeyPressed(false)
        }}
        onBlur={() => {
          setisDownOrUpKeyPressed(false)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            setisDownOrUpKeyPressed(true)
          }
          if (e.key === 'Tab') {
            // Filter out the object with id "autocomplete-new"
            const filteredOptionsWithoutNew = filteredOptions.filter(
              (option) => option.id !== 'autocomplete-new',
            )

            // Ensure there is only one element left
            if (filteredOptionsWithoutNew.length === 1) {
              if (multiple) {
                const selectedItems = [
                  ...(value || []),
                  filteredOptionsWithoutNew[0][identifier],
                ]
                formik.setFieldValue(name, selectedItems)
                onChange && onChange(selectedItems)
              } else {
                formik.setFieldValue(
                  name,
                  filteredOptionsWithoutNew[0][identifier],
                )
                onChange && onChange(filteredOptionsWithoutNew[0][identifier])
              }
            }
          }
        }}
        renderOption={(props, option, { selected }) =>
          option.value === 'create-new-option' ? (
            <MenuItem
              {...props}
              onClick={handleOpenCreateNewItem}
              key="create-new-option"
              id="create-new-option"
              disabled={readOnly}
            >
              <PlusIcon />
              {option.label}
            </MenuItem>
          ) : (
            <MenuItem
              {...props}
              selected={selected}
              key={option.id}
              id={option.id}
            >
              {option.label}
            </MenuItem>
          )
        }
        renderInput={(params) => (
          <TextField
            {...params}
            id={name + '-autocomplete-input'}
            fullWidth
            label={label}
            name={name}
            helperText={showValidationError ? get(formik.errors, name) : ''}
            placeholder={placeholder}
            error={showValidationError}
            onBlur={formik.handleBlur}
            variant={variant}
            value={value}
            inputRef={inputRef}
            disabled={isLoading}
            size={size}
            autoComplete="off"
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              readOnly: readOnly,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress
                      size={18}
                      style={{
                        position: 'absolute',
                        right: 10,
                        top: 20,
                        color: theme.palette.grey[500],
                      }}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
        filterOptions={(options, params) => {
          try {
            const filtered = filterOptionsFn(options, params)

            if (params.inputValue !== '') {
              const existsNew =
                filtered.findIndex(
                  (item: Option) => item.value === 'create-new-option',
                ) !== -1

              if (!existsNew && allowCreate) {
                filtered.unshift({
                  value: 'create-new-option',
                  label: 'Add new',
                })
              }
            }
            // Compare the new filtered options with the existing state to avoid unnecessary updates
            if (!areOptionsEqual(filtered, filteredOptions)) {
              setFilteredOptions(filtered) // Only update state if the options have changed
            }

            return filtered
          } catch (e) {
            console.log({ e, params })
            return options
          }
        }}
        {...otherProps}
      />
    </div>
  )
}

export default StaticAutocomplete

import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'

const OpportunitiesOverviewPage = lazy(
  () => import('src/pages/backoffice/Sales/Oppurtunities/overview'),
)
const OpportunitiesDeailsPage = lazy(
  () => import('src/pages/backoffice/Sales/Oppurtunities/details'),
)

export const opportunitiesRoutes: CustomRouteObject[] = [
  {
    path: 'opportunities',
    scopes: ['opportunity.read', 'opportunity.write'],
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        scopes: ['opportunity.read', 'opportunity.write'],
        element: <OpportunitiesOverviewPage />,
      },
      {
        path: ':id',
        element: <OpportunitiesDeailsPage />,
        scopes: ['opportunity.read', 'opportunity.write'],
      },
    ],
  },
]

/* eslint-disable @typescript-eslint/no-explicit-any */
const transformToFormData = (values: any) => {
  const data = new FormData()
  Object.keys(values).forEach((key) => {
    const value = values[key as keyof any]
    if (value) {
      if (Array.isArray(value)) {
        value.forEach((v, index) => {
          data.append(`${key}${index}`, v)
        })
      } else {
        if (value instanceof File) {
          data.append(key, value, value.name)
        } else {
          data.append(key, value.toString())
        }
      }
    }
  })
  return data
}

export const transformToFormData2 = (values: any, parentKey = ''): FormData => {
  const data = new FormData()

  const appendFormData = (data: FormData, key: string, value: any) => {
    if (value instanceof File) {
      data.append(key, value, value.name)
    } else if (
      typeof value === 'object' &&
      value !== null &&
      !Array.isArray(value)
    ) {
      Object.keys(value).forEach((nestedKey) => {
        appendFormData(data, `${key}[${nestedKey}]`, value[nestedKey])
      })
    } else if (Array.isArray(value)) {
      value.forEach((v, index) => {
        appendFormData(data, `${key}[${index}]`, v)
      })
    } else {
      data.append(key, value?.toString())
    }
  }

  Object.keys(values).forEach((key) => {
    const value = values[key]
    if (parentKey) {
      appendFormData(data, `${parentKey}[${key}]`, value)
    } else {
      appendFormData(data, key, value)
    }
  })

  return data
}

export default transformToFormData

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Button from './loadingButton'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ListItemIcon from '@mui/material/ListItemIcon'
import DownloadIcon from '@mui/icons-material/Download'
import { makeStyles } from '@mui/styles'

interface GenereatePDFButtonProps {
  generatePdf: () => void
  links?: { name: string; url: string }[]
  title?: string
  showLinkFirst?: boolean
  hideLinks?: boolean
  isLoading?: boolean
}
const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})

const GenereatePDFButton: React.FC<GenereatePDFButtonProps> = ({
  title = 'Create PDF',
  generatePdf,
  links = [],
  showLinkFirst,
  hideLinks = false,
  isLoading,
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleMenuItemClick = (_event: any, index: number) => {
    window.open(links[index]?.url, '_blank', 'noopener,noreferrer')
    setOpen(false)
  }

  const handleGenerate = () => {
    generatePdf()
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      {links.length > 0 ? (
        <React.Fragment>
          <ButtonGroup
            variant="outlined"
            ref={anchorRef}
            aria-label="Button group with a nested menu"
          >
            {showLinkFirst && links.length > 0 ? (
              <Button
                variant="outlined"
                key={links[0].name}
                onClick={(event: any) => handleMenuItemClick(event, 0)}
                className={classes.menuItem}
                isLoading={isLoading}
              >
                <ListItemIcon>
                  <DownloadIcon />
                </ListItemIcon>
                {links[0].name}
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={generatePdf}
                isLoading={isLoading}
              >
                <ListItemIcon>
                  <SettingsSuggestIcon />
                </ListItemIcon>
                {title}
              </Button>
            )}
            <Button
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select file"
              aria-haspopup="menu"
              onClick={handleToggle}
            >
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{
              zIndex: 1,
            }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {!(showLinkFirst && links.length > 0) ? (
                        <></>
                      ) : (
                        <MenuItem
                          key={title}
                          onClick={handleGenerate}
                          className={classes.menuItem}
                          disabled={isLoading}
                        >
                          <ListItemIcon>
                            <SettingsSuggestIcon />
                          </ListItemIcon>{' '}
                          {title}
                        </MenuItem>
                      )}

                      {!hideLinks &&
                        links?.map((link, index) => (
                          <MenuItem
                            key={link.name}
                            onClick={(event) =>
                              handleMenuItemClick(event, index)
                            }
                            className={classes.menuItem}
                          >
                            <ListItemIcon>
                              <DownloadIcon />
                            </ListItemIcon>
                            {link.name}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </React.Fragment>
      ) : (
        <Button variant="outlined" onClick={generatePdf} isLoading={isLoading}>
          {title}
        </Button>
      )}
    </React.Fragment>
  )
}

export default GenereatePDFButton

/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Button from './loadingButton'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ListItemIcon from '@mui/material/ListItemIcon'
import { makeStyles } from '@mui/styles'
interface Option {
  title: string
  handleClick: (e: any) => void
  hideThisOption?: boolean
  disabled?: boolean
  isLoading?: boolean
  icon?: any
}

interface ButtonWithOptionsProps {
  options?: Option[]
  isLoading?: boolean
  variant?: 'outlined' | 'contained'
  size?: 'small' | 'medium'
}
const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})

const ButtonWithOptions = ({
  options = [],
  isLoading,
  variant = 'outlined',
  size = 'medium',
}: ButtonWithOptionsProps) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }
  const filteredOptions = options.filter((x) => !x.hideThisOption)

  if (filteredOptions.length === 0) return <></>
  if (filteredOptions.length === 1)
    return (
      <Button
        variant={variant}
        isLoading={isLoading || filteredOptions[0].isLoading}
        onClick={filteredOptions[0].handleClick}
        disabled={filteredOptions[0].disabled}
        size={size}
      >
        {filteredOptions[0].icon} {filteredOptions[0].title}
      </Button>
    )

  return (
    <>
      <ButtonGroup
        variant={variant}
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <Button
          variant={variant}
          isLoading={isLoading || filteredOptions[0].isLoading}
          onClick={filteredOptions[0].handleClick}
          disabled={filteredOptions[0].disabled}
          size={size}
        >
          {filteredOptions[0].icon} {filteredOptions[0].title}
        </Button>
        {filteredOptions.length > 0 ? (
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select file"
            aria-haspopup="menu"
            onClick={handleToggle}
            variant={variant}
          >
            <ArrowDropDownIcon />
          </Button>
        ) : (
          ''
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {filteredOptions.slice(1)?.map((option) => (
                    <MenuItem
                      key={option.title}
                      onClick={option.handleClick}
                      className={classes.menuItem}
                      disabled={
                        option.disabled || option.isLoading || isLoading
                      }
                    >
                      {option.icon ? (
                        <ListItemIcon>{option.icon}</ListItemIcon>
                      ) : (
                        ''
                      )}
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default ButtonWithOptions

/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress, MenuItem, TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Popper } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useRef, useState } from 'react'
import { SimplifiedAutocompleteProps, OptionType } from './types'

const SimplifiedAutocomplete = ({
  formik,
  name,
  label,
  options,
  readOnly = false,
  isLoading = false,
  multiple = false,
  size = 'medium',
  ...otherProps
}: SimplifiedAutocompleteProps) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [isDownOrUpKeyPressed, setisDownOrUpKeyPressed] = useState(false)

  return (
    <div ref={containerRef}>
      <Autocomplete
        id={name + '-autocomplete'}
        PopperComponent={(props: any) => (
          <div id={'autocomplete-container-' + name}>
            <Popper
              {...props}
              container={containerRef.current}
              onMouseEnter={() => setisDownOrUpKeyPressed(false)}
            />
          </div>
        )}
        disabled={isLoading}
        multiple={multiple}
        options={options}
        getOptionLabel={(option: OptionType) => option?.label}
        isOptionEqualToValue={(option, selectedOption) => {
          return option?.value === selectedOption?.value
        }}
        autoSelect={isDownOrUpKeyPressed}
        autoHighlight
        openOnFocus
        value={
          multiple
            ? formik?.values[name]?.map((value: string) =>
                options?.find((option: OptionType) => option.value === value),
              ) ?? []
            : options.find(
                (option: OptionType) => option.value === formik.values[name],
              ) || null
        }
        onChange={(event, newValue: OptionType[] | OptionType | null) => {
          if (multiple) {
            const validItems = Array.isArray(newValue) ? newValue : []
            formik.setFieldValue(
              name,
              validItems.map((item: OptionType) => item.value),
            )
          } else {
            if (newValue && !Array.isArray(newValue)) {
              formik.setFieldValue(name, newValue.value)
            } else {
              formik.setFieldValue(name, '')
            }
          }
          setisDownOrUpKeyPressed(false)
        }}
        onBlur={() => {
          setisDownOrUpKeyPressed(false)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            setisDownOrUpKeyPressed(true)
          }
          if (e.key === 'Tab') {
            // Ensure there is only one element left
            if (options.length === 1) {
              if (multiple) {
                const selectedItems = [
                  ...(formik.values[name] || []),
                  options[0].value,
                ]
                formik.setFieldValue(name, selectedItems)
              } else {
                formik.setFieldValue(name, options[0].value)
              }
            }
          }
        }}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props} selected={selected} key={option.value}>
            {option.label}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={label}
            name={name}
            helperText={formik.touched[name] && formik.errors[name]}
            error={!!(formik.touched[name] && formik.errors[name])}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values[name]}
            inputRef={inputRef}
            disabled={isLoading || readOnly}
            size={size}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress
                      size={16}
                      style={{
                        position: 'absolute',
                        right: 10,
                        top: 20,
                        color: theme.palette.grey[500],
                      }}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
            variant="outlined"
          />
        )}
        {...otherProps}
      />
    </div>
  )
}

export default SimplifiedAutocomplete

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { get } from 'lodash'
import { ReactElement, useState } from 'react'
import { enGB } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

interface RenderFieldProps {
  field: {
    type: string
    label: string | ReactElement
    name: string
    readOnly?: boolean
    allowPastDates?: boolean
    allowFutureDates?: boolean
    disabled?: boolean
  }
  value?: any
  minDate?: any
  maxDate?: any
  formik: any
  error?: boolean
  helperText?: string
  size?: 'small' | 'medium'
  prefix?: string
  suffix?: string
  variant?: string
  format?: string
  sx?: any
  noDecimal?: boolean
  disabled?: boolean
  rows?: number
  onChange?: (name: string, value: any) => void
  onClose?: () => void
}
const adjustToLocalDate = (dateString: string) => {
  const date = new Date(dateString)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}

export const InputDatePicker = ({
  field,
  formik,
  size,
  value,
  error,
  helperText,
  onChange,
  format,
  variant = 'outlined',
  disabled,
  minDate,
  maxDate,
  sx,
  ...other
}: RenderFieldProps) => {
  const isError = !!(
    get(formik.touched, field.name) && get(formik.errors, field.name)
  )

  const inputValue = value
    ? adjustToLocalDate(value)
    : get(formik.values, field.name)

  const getTextFieldProps = () => ({
    label: field.label,
    fullWidth: true,
    name: field.name,
    format: format,
    value: value || get(formik.values, field.name),
    onChange: formik.handleChange,
    error: error ?? isError,
    helperText: helperText ?? (isError && get(formik.errors, field.name)),
    allowPastDates:
      typeof field.allowPastDates == 'boolean' ? field.allowPastDates : true,
    allowFutureDates:
      typeof field.allowFutureDates == 'boolean'
        ? field.allowFutureDates
        : true,
  })

  const shrink = !!(
    get(formik.values, field.name) === 0 ||
    getTextFieldProps()?.value === 0 ||
    get(formik.values, field.name) ||
    getTextFieldProps()?.value ||
    value !== undefined
  )
  const [open, setOpen] = useState(false)

  let maxDateProps = {}

  if (!getTextFieldProps()?.allowFutureDates)
    maxDateProps = {
      maxDate: new Date(),
    }

  if (maxDate)
    maxDateProps = {
      maxDate: maxDate,
    }

  let minDateProps = {}

  if (!getTextFieldProps()?.allowPastDates) {
    minDateProps = {
      minDate: new Date(),
    }
  }
  if (minDate) {
    minDateProps = {
      minDate: minDate,
    }
  }

  const disableOpen = field.readOnly || field.disabled || disabled

  const handleElementChange = async (newValue: any) => {
    if (disableOpen) return

    // Adjust the date to local time before setting it
    const localDate = newValue
      ? new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000)
      : null

    await formik.setFieldValue(field.name, localDate)
    onChange && onChange(field.name, localDate)

    const syntheticEvent = {
      target: {
        name: field.name,
        value: localDate,
      },
      type: 'blur',
    }

    await formik.handleBlur(syntheticEvent)
    await formik.validateField(field.name)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <DatePicker
        {...other}
        {...getTextFieldProps()}
        value={inputValue}
        onChange={handleElementChange}
        inputFormat={format || 'dd/MM/yyyy'}
        {...minDateProps}
        {...maxDateProps}
        open={open}
        onOpen={() => !disableOpen && setOpen(true)}
        onClose={() => setOpen(false)}
        readOnly={disableOpen}
        disabled={field.disabled || disabled}
        renderInput={(props: any) => (
          <TextField
            {...props}
            name={field.name}
            onBlur={formik.handleBlur}
            fullWidth
            size={size}
            error={!!(formik.touched[field.name] && formik.errors[field.name])}
            readOnly={disableOpen}
            disabled={field.disabled || disabled}
            helperText={formik.touched[field.name] && formik.errors[field.name]}
            InputLabelProps={{
              shrink,
            }}
            onClick={() => (disableOpen ? null : setOpen(true))}
            variant={variant}
            sx={{ ...sx }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

export const StaticInputDatePicker = ({
  field,
  formik,
  value,
  error,
  helperText,
  onChange,
  format,
  disabled,
  minDate,
  maxDate,
}: RenderFieldProps) => {
  const isError = !!(
    get(formik.touched, field.name) && get(formik.errors, field.name)
  )

  const inputValue = value
    ? adjustToLocalDate(value)
    : get(formik.values, field.name)

  const getTextFieldProps = () => ({
    label: field.label,
    fullWidth: true,
    name: field.name,
    format: format,
    value: value || get(formik.values, field.name),
    onChange: formik.handleChange,
    error: error ?? isError,
    helperText: helperText ?? (isError && get(formik.errors, field.name)),
    allowPastDates:
      typeof field.allowPastDates == 'boolean' ? field.allowPastDates : true,
    allowFutureDates:
      typeof field.allowFutureDates == 'boolean'
        ? field.allowFutureDates
        : true,
  })

  // const shrink = !!(
  //   get(formik.values, field.name) === 0 ||
  //   getTextFieldProps()?.value === 0 ||
  //   get(formik.values, field.name) ||
  //   getTextFieldProps()?.value ||
  //   value !== undefined
  // )

  let maxDateProps = {}

  if (!getTextFieldProps()?.allowFutureDates)
    maxDateProps = {
      maxDate: new Date(),
    }

  if (maxDate)
    maxDateProps = {
      maxDate: maxDate,
    }

  let minDateProps = {}

  if (!getTextFieldProps()?.allowPastDates) {
    minDateProps = {
      minDate: new Date(),
    }
  }
  if (minDate) {
    minDateProps = {
      minDate: minDate,
    }
  }

  const disableOpen = field.readOnly || field.disabled || disabled

  const handleElementChange = async (newValue: any) => {
    if (disableOpen) return
    console.log(newValue)

    // Adjust the date to local time before setting it
    const localDate = newValue
      ? new Date(newValue.getTime() - newValue.getTimezoneOffset() * 60000)
      : null
    console.log(localDate, formik.values, field.name)

    await formik.setFieldValue(field.name, localDate)
    onChange && onChange(field.name, localDate)

    const syntheticEvent = {
      target: {
        name: field.name,
        value: localDate,
      },
      type: 'blur',
    }
    await formik.handleBlur(syntheticEvent)
    // await formik.validateField(field.name)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <StaticDatePicker
        {...getTextFieldProps()}
        value={inputValue}
        onChange={handleElementChange}
        inputFormat={format || 'dd/MM/yyyy'}
        {...minDateProps}
        {...maxDateProps}
        readOnly={disableOpen}
        disabled={field.disabled || disabled}
        disablePast={!field.allowPastDates}
        disableFuture={!field.allowFutureDates}
        reduceAnimations
        displayStaticWrapperAs="desktop"
        showToolbar={false}
        renderInput={() => (
          <TextField
            name={field.name}
            onBlur={formik.handleBlur}
            fullWidth
            error={!!(formik.touched[field.name] && formik.errors[field.name])}
          />
        )}
      />
    </LocalizationProvider>
  )
}

import { useGetCurrencyValues } from '../apis'

export const useGetCurrencySymbol = (currencyCode: string) => {
  const currencyQuery = useGetCurrencyValues()
  if (currencyQuery.isLoading) {
    return currencyCode
  }
  const currency = currencyQuery?.data?.find(
    (item: { code: string }) => item.code === currencyCode,
  )
  return currency ? currency.sign : currencyCode
}

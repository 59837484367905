/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputAdornment, TextField } from '@mui/material'
import { NumericFormatCustom } from './RenderField'

const RenderReadOnlyNumberField = ({
  label,
  value,
  name = 'noop',
  prefix,
  suffix,
  size,
  allowNegative,
}: any) => {
  return (
    <TextField
      id={'input' + label || name}
      value={value}
      label={label}
      name={name}
      size={size}
      InputProps={{
        readOnly: true,
        inputComponent: NumericFormatCustom as any,
        endAdornment: suffix ? (
          <InputAdornment position="end">{suffix}</InputAdornment>
        ) : null,
        startAdornment: prefix ? (
          <InputAdornment position="start">{prefix}</InputAdornment>
        ) : null,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        allowNegative: allowNegative,
      }}
      sx={{ width: '100%' }}
      variant="outlined"
    />
  )
}

export default RenderReadOnlyNumberField

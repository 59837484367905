/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { get_plain } from 'src/common/utils/api'

export interface Place {
  id?: string
  name: string
  street: string
  city: string
  state: string
  country?: string
  countryCode?: string
  postcode?: string
  formatted?: string
}
export interface PlaceApi {
  id: string
  description: string
  terms?: string
  firstPart?: string
  secondPart?: string
}

export const key = process.env.REACT_APP_RULEWAVE_MAPS_KEY

export const useGetPlaces = (query: string) => {
  const activeOfficeId = localStorage.getItem('activeOfficeId')
  return useQuery(['places', query], {
    queryFn: () =>
      get_plain(
        `common/geo/places/search?query=${query}&officeId=${activeOfficeId}`,
      ),
    enabled: query !== '' && !!query,
    select: (data: any) => {
      return data?.predictions?.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ({ description, terms, place_id, structured_formatting }: any) => ({
          id: place_id,
          description,
          terms,
          firstPart: structured_formatting.main_text,
          secondPart: structured_formatting.secondary_text,
        }),
      )
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function extractAddressFields(addressComponents: any) {
  let country, countryCode, city, zipCode, street

  for (const component of addressComponents) {
    if (component.types.includes('country')) {
      country = component.long_name
      countryCode = component.short_name
    } else if (component.types.includes('locality')) {
      city = component.long_name
    } else if (component.types.includes('postal_code')) {
      zipCode = component.long_name
    } else if (component.types.includes('route')) {
      street = component.long_name
    }
  }

  return {
    country,
    countryCode,
    city,
    zipCode,
    street,
  }
}

export const useGetPlace = (placeId: string) => {
  return useQuery(['place', placeId], {
    queryFn: () => get_plain(`common/geo/places/details?placeId=${placeId}`),
    enabled: !!placeId,
    select: (data: any) => {
      return extractAddressFields(data?.result?.address_components) || null
    },
  })
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { get } from 'lodash'
import React, { ReactElement } from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { Option } from 'src/common/components/grid/types'
import { InputDatePicker } from '../datepicker'
import { formatDateValue, handleNumberKeyDown } from './utils'
import SimpleEditor from '../simple-editor'
import { useGetOfficeDateFormat } from 'src/common/hooks/use-get-office-date-format'

type views = 'day' | 'month' | 'year'

interface RenderFieldProps {
  field: {
    type: string
    label: string | ReactElement
    name: string
    placeholder?: string
    options?: Option[]
    readOnly?: boolean
    disabled?: boolean
    min?: number
    max?: number
    allowPastDates?: boolean
    allowFutureDates?: boolean
    views?: views[]
    format?: string
  }
  isLoading?: boolean
  value?: any
  formik: any
  error?: boolean
  disabled?: boolean
  helperText?: string
  size?: 'small' | 'medium'
  prefix?: string
  suffix?: string
  noDecimal?: boolean
  hideBorders?: boolean
  rows?: number
  onChange?: (name: string, value: any) => void
  variant?: 'filled' | 'outlined' | 'standard'
  sx?: any
  minDate?: any
  maxDate?: any
  decimalScale?: number
  fixedDecimalScale?: boolean
  allowNegative?: boolean
}

interface CustomProps {
  prefix?: string
  suffix?: string
  name: string
  value?: any
  decimalScale?: number
  thousandSeparator?: string | boolean
  fixedDecimalScale?: boolean
  decimalSeparator: string
  allowNegative?: boolean
  onChange: (event: {
    target: { name: string; value: number | string }
  }) => void
}

export const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const {
    prefix,
    suffix,
    name,
    onChange,
    value,
    decimalScale = 2,
    thousandSeparator = ',',
    decimalSeparator = '.',
    fixedDecimalScale = true,
    allowNegative = false,
    ...other
  } = props
  const handleChange = (values: any) => {
    onChange &&
      onChange({
        target: {
          name: name,
          value: Number(values.value),
        },
      })
  }

  return (
    <NumericFormat
      {...other}
      value={value}
      getInputRef={ref}
      onValueChange={handleChange}
      thousandSeparator={thousandSeparator}
      decimalSeparator={decimalSeparator}
      decimalScale={decimalScale}
      allowNegative={allowNegative}
      fixedDecimalScale={fixedDecimalScale}
      prefix={prefix}
      suffix={suffix}
    />
  )
})

export const NumericFormatText = (props: any) => {
  const {
    prefix,
    suffix,
    name,
    onChange,
    value,
    decimalScale = 2,
    thousandSeparator = ',',
    fixedDecimalScale,
    sx,
    ...other
  } = props

  const handleChange = (values: any) => {
    onChange &&
      onChange({
        target: {
          name: name,
          value: Number(values.value),
        },
      })
  }

  return (
    <NumericFormat
      {...other}
      value={value}
      onValueChange={handleChange}
      thousandSeparator={thousandSeparator}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      prefix={prefix}
      displayType="text"
      suffix={suffix}
      style={{ ...sx, textAlign: 'right', textWrap: 'nowrap' }}
    />
  )
}

const RenderField = React.memo(
  ({
    field,
    formik,
    size = 'medium',
    rows,
    value,
    error,
    prefix,
    suffix,
    decimalScale,
    fixedDecimalScale,
    helperText,
    variant = 'outlined',
    hideBorders = false,
    onChange,
    sx,
    minDate,
    maxDate,
    isLoading = false,
    disabled,
    ...other
  }: RenderFieldProps) => {
    const { dateFormat } = useGetOfficeDateFormat()
    const isError = !!(
      (formik.submitCount > 0 && get(formik.errors, field.name)) ||
      (get(formik.touched, field.name) && get(formik.errors, field.name))
    )

    const input_value = get(formik.values, field.name)
    const { type, ...otherFieldProps } = field
    const getTextFieldProps = () => ({
      readOnly: field.readOnly,
      label: field.label,
      fullWidth: true,
      name: field.name,
      value: input_value,
      onBlur: formik.handleBlur,
      onChange: formik.handleChange,
      error: error ?? isError,
      helperText: helperText ?? (isError && get(formik.errors, field.name)),
    })
    const getDateFieldProps = () => ({
      readOnly: field.readOnly,
      label: field.label,
      fullWidth: true,
      name: field.name,
      placeholder: field.placeholder,
      value: input_value,
      onBlur: formik.handleBlur,
      onChange: formik.handleChange,
      error: error ?? isError,
      helperText: helperText ?? (isError && get(formik.errors, field.name)),
      allowPastDates: field.allowPastDates,
      allowFutureDates: field.allowFutureDates,
      views: field.views,
      format: field.format,
    })
    const getDateTimeFieldProps = () => ({
      readOnly: field.readOnly,
      label: field.label,
      fullWidth: true,
      name: field.name,
      placeholder: field.placeholder,
      value: input_value,
      onBlur: formik.handleBlur,
      onChange: formik.handleChange,
      error: error ?? isError,
      helperText: helperText ?? (isError && get(formik.errors, field.name)),
      allowPastDates: field.allowPastDates,
      allowFutureDates: field.allowFutureDates,
    })
    const shrink =
      !!(
        input_value === 0 ||
        getTextFieldProps()?.value === 0 ||
        input_value ||
        getTextFieldProps()?.value ||
        value !== undefined
      ) || !!field.placeholder
    const valueNullOrUndefined =
      input_value === undefined || input_value === null
    if (isLoading)
      return <Skeleton variant="rounded" width={'100%'} height={40} />

    switch (field.type) {
      case 'hidden':
        return <input {...getTextFieldProps()} type="hidden" />
      case 'number': {
        return (
          <TextField
            {...otherFieldProps}
            {...getTextFieldProps()}
            {...other}
            variant={variant}
            value={input_value || ''}
            size={size}
            InputProps={{
              readOnly: field.readOnly,
              inputComponent: NumericFormatCustom as any,
              endAdornment: suffix ? (
                <InputAdornment position="end">{suffix}</InputAdornment>
              ) : null,
              startAdornment:
                prefix && !valueNullOrUndefined ? (
                  <InputAdornment position="start">{prefix}</InputAdornment>
                ) : null,
            }}
            InputLabelProps={{
              shrink,
            }}
            inputProps={{
              allowNegative: other?.allowNegative ?? false,
              autoComplete: 'new-password',
              decimalScale,
              fixedDecimalScale,
              onChange: (newValue: any) => {
                if (field.readOnly) {
                  return
                }
                formik.setFieldValue(field.name, newValue)
                onChange && onChange(field.name, newValue)
              },
              onKeyDown: (event: any) => {
                !field.readOnly &&
                  handleNumberKeyDown(
                    event,
                    input_value,
                    otherFieldProps,
                    formik,
                    field,
                    onChange,
                  )
              },
            }}
            disabled={field.disabled || disabled}
            sx={{ width: '100%', ...sx }}
          />
        )
      }
      case 'text':
      case 'textarea':
        return (
          <TextField
            {...field}
            {...getTextFieldProps()}
            {...other}
            fullWidth
            multiline={field.type === 'textarea'}
            rows={field.type === 'textarea' ? rows || 2 : undefined}
            type={field.type}
            size={size}
            variant={variant}
            error={isError}
            helperText={
              isError ? get(formik.errors, field.name) : helperText || ''
            }
            InputProps={{
              readOnly: field.readOnly,
              endAdornment: suffix ? (
                <InputAdornment position="end">{suffix}</InputAdornment>
              ) : null,
              startAdornment: prefix ? (
                <InputAdornment position="start">{prefix}</InputAdornment>
              ) : null,
              style: {
                border: hideBorders ? 'none' : '',
                // resize: 'vertical',
              },
            }}
            disabled={field.disabled || disabled}
            style={{
              width: '100%',
              border: hideBorders ? 'none' : '',
            }}
            InputLabelProps={{
              shrink,
            }}
            inputProps={{
              autoComplete: 'new-password',
            }}
            sx={{ ...sx }}
          />
        )
      case 'editor':
        return (
          <SimpleEditor
            {...field}
            {...getTextFieldProps()}
            {...other}
            onChangeHandler={(e: string) => {
              formik.setFieldValue(field.name, e)
            }}
            defaultValue={input_value}
            fullWidth
            size={size}
            variant={variant}
            error={isError}
            helperText={
              isError ? get(formik.errors, field.name) : helperText || ''
            }
            disabled={field.disabled}
            style={{
              width: '100%',
              border: hideBorders ? 'none' : '',
            }}
            InputLabelProps={{
              shrink,
            }}
            inputProps={{
              autoComplete: 'new-password',
            }}
            sx={{ ...sx }}
          />
        )

      case 'select':
        return (
          <TextField
            {...getTextFieldProps()}
            select
            size={size}
            variant={variant}
            InputProps={{
              readOnly: field.readOnly,
            }}
            error={isError}
            helperText={
              isError ? get(formik.errors, field.name) : helperText || ''
            }
            disabled={field.disabled}
            InputLabelProps={{
              shrink,
            }}
            inputProps={{
              autoComplete: 'new-password',
            }}
            {...other}
            sx={{ ...sx }}
          >
            {field?.options?.map((option: Option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )
      case 'dateTime':
      case 'dateTimePicker':
        return (
          <DateTimePicker
            {...other}
            {...getDateTimeFieldProps()}
            // value={get(formik.values, field.name)}
            ampm={false}
            onChange={(newValue: any) => {
              if (field.readOnly) {
                return
              }
              formik.setFieldValue(field.name, newValue)
              onChange && onChange(field.name, newValue)
            }}
            renderInput={(props: any) => (
              <TextField
                {...props}
                name={field.name}
                onBlur={formik.handleBlur}
                fullWidth
                size={size}
                variant={variant}
                error={isError}
                value={formatDateValue(get(formik.values, field.name))}
                helperText={
                  isError ? get(formik.errors, field.name) : helperText || ''
                }
                readOnly={field.readOnly}
                disabled={field.disabled || disabled}
                InputLabelProps={{
                  shrink,
                }}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                sx={{ ...sx }}
              />
            )}
            minDate={minDate}
            maxDate={maxDate}
          />
        )
      case 'date':
      case 'datePicker':
        return (
          <InputDatePicker
            {...getTextFieldProps()}
            {...getDateFieldProps()}
            field={field}
            size={size}
            formik={formik}
            onChange={onChange}
            value={
              value !== undefined
                ? value
                : get(formik.values, field.name) !== undefined
                ? get(formik.values, field.name)
                : getTextFieldProps().value
            }
            error={isError}
            helperText={
              isError ? get(formik.errors, field.name) : helperText || ''
            }
            disabled={field.disabled || disabled}
            minDate={minDate}
            maxDate={maxDate}
            {...other}
            format={field.format || dateFormat}
            variant={variant}
            sx={sx}
          />
        )

      case 'checkbox':
        return (
          <FormControlLabel
            {...other}
            control={
              <Checkbox
                checked={get(formik.values, field.name)}
                onChange={formik.handleChange}
                name={field.name}
                disabled={field.disabled}
              />
            }
            label={field.label}
            sx={{ ...sx }}
          />
        )
      case 'checkboxGroup': {
        const hasError =
          helperText ||
          (formik.touched[field.name] && formik.errors[field.name])

        return (
          <FormControl error={hasError} component="fieldset" variant="standard">
            <FormGroup sx={{ ...sx }}>
              <Typography>{field.label}</Typography>
              {field?.options &&
                field.options?.map((option: Option) => (
                  <FormControlLabel
                    {...other}
                    key={option.value}
                    control={
                      <Checkbox
                        checked={(formik?.values && formik.values[field.name]
                          ? formik.values[field.name]
                          : []
                        ).includes(option.value)}
                        onChange={formik.handleChange}
                        name={field.name}
                        value={option.value}
                        disabled={field.disabled}
                      />
                    }
                    label={option.label}
                    sx={{ ...sx }}
                  />
                ))}
              <FormHelperText>
                {helperText || (formik.errors && formik.errors[field.name])}
              </FormHelperText>
            </FormGroup>
          </FormControl>
        )
      }
      default:
        return <span> {field.type} not supported </span>
    }
  },
)

export default RenderField

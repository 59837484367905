import { createContext, useState, FC, ReactNode } from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
// Define Modal type
interface ModalType {
  id: string
  inline: boolean
  open: boolean
  objectId?: string
}

// Define Modal Context type
interface ModalContextType {
  modals: ModalType[]
  openModal: (id: string, inline: boolean, objectId?: string) => void
  closeModal: (id: string) => void
  isOpenedInlineModal: (ids: string[]) => boolean
  modalWidth?: string
}

// Define Modal Context
export const ModalContext = createContext<ModalContextType | null>(null)

// Define Modal Provider
interface ModalProviderProps {
  children: ReactNode
}

const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [modals, setModals] = useState<ModalType[]>([])

  const openModal = (id: string, inline: boolean, objectId?: string) => {
    setModals((modals) => [
      ...modals,
      {
        id,
        inline: isSmallScreen ? false : inline,
        open: true,
        objectId,
      },
    ])
  }
  const modalWidth = isSmallScreen ? '100%' : isMediumScreen ? '65%' : '45%'

  const closeModal = (id: string) => {
    setModals((modals) => modals.filter((modal) => modal.id !== id))
  }
  const isOpenedInlineModal = (ids: string[]) =>
    modals.filter((x: ModalType) => ids.includes(x.id) && x.open && x.inline)
      .length > 0

  return (
    <ModalContext.Provider
      value={{
        modals,
        openModal,
        closeModal,
        isOpenedInlineModal,
        modalWidth,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@tanstack/react-query'
import { get } from 'src/common/utils/api'

export const useGetNotificationsQuery = ({ officeId, userid }: any) => {
  return useQuery(['notifications'], {
    queryFn: () =>
      get(
        `common/search?term=&index=notification&page=1&perPage=100&officeId=${officeId}&filters="user_id=${userid}"`,
        {},
        false,
      ),
    select: (data: any) => data?.data,
  })
}

import {
  BE_OPERATORS,
  OperatorKeys,
} from 'src/common/constants/operatorConstants'
import { Filter, FitlerColumn } from './types'

export function convertFiltersToString(
  filters: { column: string; value: string }[],
  useSearch = true,
): string {
  const convertedFilters: string[] = filters.map((filter) => {
    const strValue = filter?.value || ''

    const operator = '' // strValue?.includes(',') ? 'and:=' : ''
    const value = strValue?.includes(',')
      ? strValue?.split(',').join(';')
      : strValue
    return useSearch
      ? `${filter.column}=${operator}${value}`
      : `${filter.column}=eq:${value}`
  })

  if (!convertedFilters || convertedFilters.length === 0) return ''
  if (convertedFilters.length > 1) {
    return convertedFilters.join('&')
  } else return convertedFilters[0]
}

export function generateFilterString(filters: Filter[]): string {
  let filterStr = ''

  for (let i = 0; i < filters.length; i++) {
    const filter = filters[i]
    if (
      !filter.column ||
      !filter.operator ||
      !filter.value ||
      !Object.prototype.hasOwnProperty.call(BE_OPERATORS, filter.operator)
    ) {
      continue
    }

    const filterOperator = filter.filterOperator || 'and'

    const backendOperator = BE_OPERATORS[filter.operator as OperatorKeys]

    if (i > 0) {
      filterStr += `${filter.column}=${filterOperator}:${backendOperator}:${filter.value}`
    } else {
      filterStr += `${filter.column}=${backendOperator}:${filter.value}`
    }

    if (i < filters.length - 1) {
      filterStr += '&'
    }
  }

  return filterStr
}

export function describeFilters(
  filters: Filter[],
  columns: FitlerColumn[],
): string {
  if (filters.length === 0) return 'No filters applied'
  // Map over each filter to create a sentence
  const filterSentences = filters.map((filter, index) => {
    let sentence = ''

    // If this is not the first filter, add an 'and' at the start
    if (index > 0) {
      sentence += ' and '
    }
    const filterName = columns
      .find((x) => x.name === filter.column)
      ?.displayName.toLowerCase()

    sentence += `the ${filterName} `

    // Append the appropriate verb based on the operator

    sentence += `${filter.operator} "${filter.value}"`

    return sentence
  })

  return `You are seeing data where ${filterSentences.join('')}.`
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react'
import { Centrifuge } from 'centrifuge'
import { getJwtFromCookie } from '../utils/auth-cookie'
import { useDispatch } from 'react-redux'
import { addNotification } from 'src/slices/NotificationSlice'
import { toast } from 'react-hot-toast'
// import FileSaver from 'file-saver'

import { useQueryClient } from '@tanstack/react-query'

const url = process.env.REACT_APP_RULEWAVE_WEBSOCKET as string
// const downloadPDF = (url: string, objectType: string) => {
//   get_plain(url)
//     .then((response) => response.blob())
//     .then((blob) => {
//       console.log(objectType, blob)
//       FileSaver.saveAs(blob, `${objectType}-pdf-generated.pdf`)
//     })
//     .catch((error) => {
//       console.error('Error downloading the PDF file', error)
//     })
// }

export const useWebSocket = () => {
  const accessToken = getJwtFromCookie()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  useEffect(() => {
    console.log('Connecting to WebSocket server:', url)
    const centrifuge = new Centrifuge(url, {
      data: {
        authToken: accessToken,
      },
    })

    centrifuge.on('connected', function () {
      // console.log('Connected to WebSocket server', ctx)
    })

    centrifuge.on('disconnected', function (ctx: any) {
      console.log('Disconnected from WebSocket server', ctx)
    })

    centrifuge.on('message', function (ctx: any) {
      console.log('Received message:', ctx)
    })

    centrifuge.on('publication', function (ctx: any) {
      console.log(
        'publication receive from server-side channel',
        ctx.channel,
        ctx.data,
      )

      if (ctx.data.type === 'pdf-generation' && ctx.data.data.pdfUrl) {
        window.open(ctx.data.data?.pdfUrl)
        // downloadPDF(ctx.data.data.pdfUrl, ctx.data.objectType)
      }
      if (ctx.data && !ctx.data.isPersistent) {
        if (ctx.data.status === 'success') {
          toast.success(ctx.data.message, { duration: 5000 })
        } else toast.error(ctx.data.message)
      }
      ctx.data.objectType &&
        ctx.data.data.objectId &&
        queryClient.invalidateQueries([
          ctx.data.objectType,
          ctx.data.data.objectId,
        ])
      dispatch(addNotification(ctx.data))
    })

    centrifuge.on('error', function (error: any) {
      console.error('WebSocket error:', error)
    })

    centrifuge.connect()

    return () => {
      centrifuge.disconnect()
    }
  }, [accessToken, dispatch, queryClient])
}

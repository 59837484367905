import { useCallback, useEffect, useRef } from 'react'

// This function returns a callback that checks if the component is currently mounted
export const useMounted = (): (() => boolean) => {
  // Create a reference to a boolean called isMounted and initialize it as false
  const isMounted = useRef<boolean>(false)

  // Use useEffect to set isMounted to true when the component mounts
  // Return a cleanup function which sets isMounted back to false when the component unmounts
  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  // Define a useCallback function that returns the current value of isMounted
  // This allows the function to be memoized and not be recreated every render cycle
  return useCallback((): boolean => isMounted.current, [])
}

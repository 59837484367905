import React from 'react'
import type { Theme } from '@mui/material'
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { RouterLink } from '../common/components/router-link'
import { Seo } from '../common/components/seo'

import { paths } from '../navigator/paths'
import type { Page as PageType } from '../common/types/page'

const Page: PageType = () => {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <>
      <Seo title="Error: Server Error" />
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 6,
            }}
          >
            <Box
              alt="Internal server error"
              component="img"
              src="/assets/errors/error-500.png"
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
          <Typography align="center" variant={mdUp ? 'h1' : 'h4'}>
            500: Internal Server Error
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <Button component={RouterLink} href={paths.index}>
              Back to Home
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  )
}

export default Page

import React from 'react'
import { lazy, Suspense } from 'react'
import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'

const OfficesListPage = lazy(() => import('src/pages/backoffice/Offices/list'))

export const officesRoutes: CustomRouteObject[] = [
  {
    path: 'offices',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <OfficesListPage />,
        scopes: ['office.read', 'office.write'],
      },
    ],
  },
]

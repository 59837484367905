import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { CustomRouteObject } from '../types'
import { Outlet } from 'react-router-dom'
import { GuestGuard } from '../../../common/guards/guest-guard'
import { Layout as AuthLayout } from '../../../modules/auth/classic-layout'

import PrivacyTermsPage from 'src/modules/frontPages/privacy-policy'

const queryClient = new QueryClient()

export const privacyRoutes: CustomRouteObject[] = [
  {
    path: 'privacy',
    children: [
      {
        path: 'terms',
        element: (
          <QueryClientProvider client={queryClient}>
            <GuestGuard>
              <AuthLayout>
                <Outlet />
                <PrivacyTermsPage />
              </AuthLayout>
            </GuestGuard>
          </QueryClientProvider>
        ),
      },
    ],
  },
]

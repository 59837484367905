import { lazy, Suspense } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'
import PreviewPage from 'src/modules/invoices/preview-page'
const IndexPage = lazy(() => import('src/pages/customer/Invoices/index'))

export const invoicesRoutes: RouteObject[] = [
  {
    path: 'invoices',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: ':fileId/:invoiceId',
        element: <PreviewPage />,
      },
    ],
  },
]

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { get, isEqual } from 'lodash'
import { useCallback } from 'react'
import { INDEXES } from 'src/common/apis/constants'
import DynamicAutocomplete from 'src/common/components/autocomplete/common/dynamic-autocomplete-with-create'
import CreateCustomerModal from 'src/modules/customers/create-customer-modal'
import { Option } from '../grid/types'

interface CustomerAutocompleteProps {
  formik: any
  readOnly?: boolean
  name?: string
  multiple?: boolean
  isLoading?: boolean
  size?: 'small' | 'medium'
  allowCreate?: boolean
  onChange?: (value: any) => void
}

const CustomerAutocomplete = ({
  formik,
  readOnly = false,
  name = 'customer',
  multiple = false,
  isLoading,
  size = 'medium',
  allowCreate = true,
  onChange,
}: CustomerAutocompleteProps) => {
  const value = get(formik.values, name)
  const error = get(formik.errors, name)
  const touched = !!get(formik.touched, name)

  const { setFieldValue, setFieldTouched } = formik

  const setFormikFieldValue = (
    inputName: string,
    newValue: Option | Option[] | null,
  ) => {
    if (!isEqual(value, newValue)) {
      setFieldValue(inputName, newValue)
    }
  }
  const renderInput = useCallback(() => {
    return (
      <DynamicAutocomplete
        readOnly={readOnly}
        setFieldTouched={setFieldTouched}
        name={name}
        label="Customer"
        index={INDEXES.CUSTOMER}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onModalSubmit={(newItem: any) => {
          return {
            ...newItem,
            id: newItem?.id,
            value: newItem?.id,
            label: newItem?.name,
          }
        }}
        afterFilter={(item) => item.status === 'active'}
        ModalComponent={CreateCustomerModal}
        multiple={multiple}
        value={value || []}
        error={error}
        touched={touched}
        handleBlur={formik.handleBlur}
        setFieldValue={setFormikFieldValue}
        size={size}
        allowCreate={allowCreate}
        onChange={onChange}
        variant="outlined"
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly, isLoading, value, touched, error])

  return renderInput()
}

export default CustomerAutocomplete

type InvoiceRow = {
  unitPrice: number
  quantity: number
}

type Invoice = {
  lineItems: InvoiceRow[]
  discount: number
  taxtPercentage: number
}

// Calculate total for a single row
export function calculateRowTotal(row: InvoiceRow): number {
  return row.unitPrice * row.quantity
}

// Calculate subtotal of the invoice (sum of all row totals)
export function calculateSubtotal(invoice: Invoice): number {
  return invoice.lineItems.reduce(
    (subtotal, row) => subtotal + calculateRowTotal(row),
    0,
  )
}

// Apply discount to the subtotal
export function applyDiscount(subtotal: number, discount: number): number {
  return subtotal - (subtotal * discount) / 100
}

// Apply tax to the subtotal
export function applyTax(subtotal: number, taxtPercentage: number): number {
  const tax = subtotal * taxtPercentage
  return subtotal + tax
}

// Calculate final total of the invoice
export function calculateFinalTotal(invoice: Invoice): number {
  const subtotal = calculateSubtotal(invoice)
  const subtotalAfterDiscount = applyDiscount(subtotal, invoice.discount)

  return applyTax(subtotalAfterDiscount, invoice.taxtPercentage)
}

import { lazy, Suspense } from 'react'
import type { RouteObject } from 'react-router'
import { Outlet } from 'react-router-dom'
import { Layout as DashboardLayout } from '../../../layout'

const PreviewPage = lazy(() => import('src/modules/invoices/preview-page'))
const OrdersOverviewPage = lazy(
  () => import('src/pages/customer/Orders/overview'),
)
const OrdersDetailsPage = lazy(() => import('src/pages/customer/Orders/detail'))

export const orderRoutes: RouteObject[] = [
  {
    path: 'files',
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <OrdersOverviewPage />,
      },
      {
        path: ':id',
        element: <OrdersDetailsPage />,
      },
      {
        path: ':fileId/invoices/:invoiceId',
        element: <PreviewPage />,
      },
    ],
  },
]

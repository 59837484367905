/* eslint-disable @typescript-eslint/no-explicit-any */
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Badge,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  SvgIcon,
  TextField,
  Tooltip,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import FilterFunnel01Icon from '@untitled-ui/icons-react/build/esm/FilterFunnel01'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DATE_OPERATORS,
  NUMBER_OPERATORS,
  SELECT_OPERATORS,
  TEXT_OPERATORS,
} from 'src/common/constants/operatorConstants'
import {
  addAdvancedFilter,
  removeAdvancedFilter,
  removeAllAdvancedFilters,
  updateAdvancedFilter,
} from 'src/slices/FiltersSlice'
import { Filter, FitlerColumn, Option } from './types'
import { describeFilters } from './utils'
import { format } from 'date-fns'

interface AdvancedFilter {
  column: string
  operator: string
  value: string
}

interface FilterParam {
  columns: FitlerColumn[]
  filterKey: string
}

const renderSelect = (
  filter: Filter,
  index: number,
  columns: FitlerColumn[],
  handleChange: any,
) => {
  const column = columns.find((column) => column.name === filter.column)
  return (
    <Select
      size="small"
      variant="standard"
      value={filter.value}
      onChange={(event) => handleChange(index, 'value', event)}
      style={{ minWidth: '170px' }}
    >
      <MenuItem value="" disabled>
        <em>Filter value</em>
      </MenuItem>
      {column?.options?.map(({ value, label }: Option) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}

const renderTextField = (filter: Filter, index: number, handleChange: any) => (
  <TextField
    size="small"
    variant="standard"
    value={filter.value}
    placeholder="Filter value"
    onChange={(event) => handleChange(index, 'value', event)}
    sx={{ justifyContent: 'end', minWidth: '170px' }}
  />
)

const renderDatePicker = (filter: Filter, index: number, handleChange: any) => (
  <DatePicker
    value={filter.value}
    inputFormat={'dd/MM/yyyy'}
    onChange={(
      value: string | null,
      keyboardInputValue?: string | undefined,
    ) => {
      console.log(value, keyboardInputValue)

      value
        ? handleChange(index, 'value', format(new Date(value), 'dd/MM/yyyy'))
        : handleChange(index, 'value', null)
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        size="small"
        variant="standard"
        sx={{ justifyContent: 'end', minWidth: '170px' }}
      />
    )}
  />
)

const renderFilterInput = (
  filter: Filter,
  index: number,
  columns: FitlerColumn[],
  handleChange: any,
) => {
  if (filter.operator === 'is empty' || filter.operator === 'is not empty') {
    return <div style={{ minWidth: '170px' }} />
  }

  const column = columns.find((column) => column.name === filter.column)
  if (!column) return null

  switch (column.type) {
    case 'select':
      return renderSelect(filter, index, columns, handleChange)
    case 'date':
      return renderDatePicker(filter, index, handleChange)
    default:
      return renderTextField(filter, index, handleChange)
  }
}

const FilterButton: React.FC<FilterParam> = ({
  columns,
  filterKey,
}: FilterParam) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'filters-popover' : undefined

  const dispatch = useDispatch()
  const filters = useSelector(
    (state: {
      filters: { advancedFilters: { [key: string]: AdvancedFilter[] } }
    }) => state.filters.advancedFilters[filterKey] || [],
  )

  const handleAddFilter = () => {
    dispatch(
      addAdvancedFilter({
        filterKey,
        filter: { column: '', operator: 'equals', value: '' },
      }),
    )
  }

  const handleRemoveFilter = (index: number) => {
    dispatch(removeAdvancedFilter({ filterKey, index }))
  }

  const handleRemoveAllFilters = () => {
    dispatch(removeAllAdvancedFilters(filterKey))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (index: number, field: keyof Filter, event: any) => {
    console.log({ [field]: event?.target?.value || event })
    const newFilter = {
      ...filters[index],
      [field]: event?.target?.value || event,
    }
    dispatch(updateAdvancedFilter({ filterKey, index, filter: newFilter }))
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    if (filters.length === 0) {
      handleAddFilter()
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const filtersCounter = filters.filter(
    (x: Filter) => x.column && x.operator && x.value,
  )
  const getOperatorsByColumnType = (columnType: string | undefined) => {
    switch (columnType) {
      case 'text':
        return TEXT_OPERATORS
      case 'number':
        return NUMBER_OPERATORS
      case 'date':
        return DATE_OPERATORS
      case 'select':
        return SELECT_OPERATORS
      default:
        return TEXT_OPERATORS
    }
  }

  return (
    <Box>
      <Tooltip
        title={describeFilters(filtersCounter, columns)}
        arrow
        followCursor
        placement="left-start"
      >
        <Button
          id="infinitegrid-filters"
          color="inherit"
          startIcon={
            <SvgIcon>
              <FilterFunnel01Icon />
            </SvgIcon>
          }
          onClick={handleClick}
          aria-describedby={id}
        >
          <Badge badgeContent={filtersCounter.length} color="secondary">
            Filters
          </Badge>
        </Button>
      </Tooltip>
      <Popover
        open={open}
        onClose={handleClose}
        id={id}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ minWidth: '1700px' }}
        title="Filters"
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', pr: 1, pt: 1 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {filters.map((filter: Filter, index: number) => {
          // const selectedColumns = filters.map((filter: Filter) => filter.column)
          const column: FitlerColumn | null =
            columns.find((x) => x.name === filter.column) || null

          const customOperators = getOperatorsByColumnType(column?.type)

          return (
            <Box key={index} gap={2} p={1} display={'flex'}>
              <FormControl variant="standard" sx={{ minWidth: '150px' }}>
                <InputLabel id="simple-select-standard-label">
                  Column
                </InputLabel>
                <Select
                  label="Column"
                  value={filter.column}
                  size="small"
                  variant="standard"
                  onChange={(event) => handleChange(index, 'column', event)}
                >
                  {columns.map((column) => (
                    <MenuItem
                      key={column.name}
                      value={column.name}
                      // disabled={selectedColumns.includes(column.name)}
                    >
                      {column.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ minWidth: '150px' }}>
                <InputLabel id="simple-select-standard-label"></InputLabel>
                <Select
                  value={filter.operator}
                  size="small"
                  variant="standard"
                  placeholder="operator"
                  onChange={(event) => handleChange(index, 'operator', event)}
                  sx={{ minWidth: '100px' }}
                >
                  {customOperators.map((operator) => (
                    <MenuItem key={operator} value={operator}>
                      {operator}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {renderFilterInput(filter, index, columns, handleChange)}
              <IconButton
                onClick={() => handleRemoveFilter(index)}
                sx={{
                  alignSelf: 'flex-end',
                  pb: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )
        })}
        <Box display={'flex'} justifyContent={'space-between'} p={1}>
          <Button onClick={handleAddFilter} startIcon={<AddIcon />}>
            Add filter
          </Button>
          <Button
            onClick={handleRemoveAllFilters}
            startIcon={<CloseOutlinedIcon />}
          >
            Clear all filters
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

export default FilterButton

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, CardContent } from '@mui/material'
import { useFormik } from 'formik'
import { Modal } from 'src/common/components'
import * as Yup from 'yup'
import CustomerForm from '../customers/form/customerForm'
import { useCreateCustomerMutation } from './useCustomersRequests'

import Step from '@mui/material/Step'
import StepButton from '@mui/material/StepButton'
import Stepper from '@mui/material/Stepper'
import { useState } from 'react'
import BusinessEntityForm from './businessentities/form/businessentityForm'
import { CustomersBusinessEntitiesEntity as Entity } from 'src/common/types/Customer'
import { useCreateBusinessEntityMutation } from './businessentities/useBusinessEntitiesRequests'
import {
  CustomersCreateModal as CustomersModalProps,
  CustomersValues as Values,
} from 'src/common/types/Customer'
import Button from 'src/common/components/loadingButton'
import { cloneDeep } from 'lodash'
import { matchIsValidTel } from 'mui-tel-input'

const defaultValues: Values = {
  name: '',
  status: 'active',
  website: '',
  accountManager: '',
  paymentTerms: '15',
  submit: null,
}

const customerValidationSchema = Yup.object({
  name: Yup.string().max(255).required('Name is required'),
  status: Yup.string().max(255).required('Status is required'),
  website: Yup.string()
    .max(255)
    .nullable()
    .url('Invalid URL format')
    .matches(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/, 'Invalid URL format'),
  accountManager: Yup.string().max(255), //.required('Account Manager is required'),
  paymentTerms: Yup.string()
    .matches(/^\d*$/, 'Payment Terms must be a valid positive number')
    .max(255)
    .required('Payment Terms is required'),
})

const entityValidationSchema = Yup.object({
  companyName: Yup.string().max(255).required('Company name is required'),
  phone: Yup.string()
    .test('phone', 'Phone number is invalid', (value) => {
      return value === undefined || value === null || value === ''
        ? true
        : matchIsValidTel(value)
    })
    .nullable(),
  address: Yup.string(),
  email: Yup.string()
    .email('Must be a valid email')
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      'Invalid email address',
    )
    .max(255)
    .nullable(),
  fullAddress: Yup.string().max(255).required('Full Address is required'),
  country: Yup.string().max(255).required('Country is required'),
  city: Yup.string().max(255).required('City is required'),
  street: Yup.string().max(255).required('Street is required'),
  zipCode: Yup.string().max(255).required('Zip code is required'),
  paymentTerms: Yup.string()
    .matches(/^\d*$/, 'Payment Terms must be a valid positive number')
    .max(255)
    .required('Payment Terms is required'),
  assignedTo: Yup.string().max(255).nullable(),
  taxId: Yup.string().max(255).nullable(),
  clientNumber: Yup.string().max(255).required('Client Number is required'),
})

const CreateCustomerModal = ({
  handleClose,
  open,
  submitCallback,
}: CustomersModalProps) => {
  const createMutation = useCreateCustomerMutation({
    callback: async (data: any) => {
      handleClose()
      const newItem = cloneDeep({ ...data?.id, ...customerFormik.values })
      submitCallback && (await submitCallback(newItem))
      entityFormik.resetForm()
    },
  })

  const customerFormik = useFormik({
    initialValues: defaultValues,
    validateOnMount: true,
    validationSchema: customerValidationSchema,
    onSubmit: async () => {
      return
    },
  })

  const entityFormik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      clientNumber: '',
      fullAddress: '',
      country: '',
      city: '',
      street: '',
      zipCode: '',
      assignedTo: customerFormik.values?.accountManager,
      taxId: '',
      companyName: customerFormik.values?.name,
      paymentTerms: customerFormik.values?.paymentTerms,
    },
    validationSchema: entityValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: () => {
      return
    },
  })
  const steps = ['Create customer', 'Add entity']
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = async () => {
    if (activeStep === 0) {
      await customerFormik.handleSubmit()
      await customerFormik.validateForm()

      if (Object.keys(customerFormik.errors).length === 0) {
        setActiveStep((prevStep) => prevStep + 1)
      }
    }
  }

  const createEntityMutation = useCreateBusinessEntityMutation({
    callback: handleClose,
  })
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }
  const resetForms = () => {
    setActiveStep(0)
    customerFormik.resetForm()
    entityFormik.resetForm()
  }

  const handleSave = async () => {
    entityFormik.setTouched({
      companyName: true,
      phone: true,
      email: true,
      country: true,
      city: true,
      street: true,
      zipCode: true,
      paymentTerms: true,
      assignedTo: true,
      taxId: true,
      clientNumber: true,
      fullAddress: true,
    })
    await entityFormik.validateForm()

    if (Object.keys(entityFormik.errors).length === 0) {
      const savedCustomer = await createMutation.mutateAsync({
        values: customerFormik.values,
        setFieldError: customerFormik.setFieldError,
      })

      if (savedCustomer && savedCustomer.id) {
        await createEntityMutation.mutateAsync({
          values: {
            ...(entityFormik.values as Entity),
            customerId: savedCustomer.id,
          },

          setFieldError: entityFormik.setFieldError,
        })
        submitCallback &&
          submitCallback({ ...savedCustomer, ...customerFormik.values })
      }

      handleClose()
      resetForms()
    }
  }

  return (
    <Modal
      id="create-customer-modal"
      title={
        <Box
          display="flex"
          p={1}
          gap={2}
          justifyContent={'space-around'}
          mr={4}
          sx={{ height: '100%', alignContent: 'center', alignItems: 'center' }}
        >
          <Stepper nonLinear activeStep={activeStep} sx={{ width: '100%' }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton
                  id={`modal-steps-${steps}`}
                  color="inherit"
                  onClick={handleStep(index)}
                >
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      }
      onClose={handleClose}
      open={open}
      footer={
        <Box
          display="flex"
          justifyContent={'space-between'}
          width={'100%'}
          p={1}
          gap={2}
        >
          <Button
            id="crm-customers-modal-cancel"
            disabled={createMutation.isLoading}
            onClick={() => {
              handleClose()
              resetForms()
            }}
          >
            Cancel
          </Button>
          <div>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                id="crm-customers-modal-back"
                // color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                variant="outlined"
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep === 0 && (
                <Button
                  id="crm-customers-modal-next"
                  onClick={handleNext}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Next
                </Button>
              )}
              {activeStep === 1 && (
                <Button
                  id="crm-customers-modal-save"
                  disabled={createMutation.isLoading}
                  isLoading={createMutation.isLoading}
                  onClick={handleSave}
                  sx={{ mr: 1 }}
                  variant="contained"
                >
                  Save
                </Button>
              )}
            </Box>
          </div>
        </Box>
      }
    >
      <CardContent>
        {activeStep === 0 && <CustomerForm formik={customerFormik} />}
        {activeStep === 1 && <BusinessEntityForm formik={entityFormik} />}
      </CardContent>
    </Modal>
  )
}

export default CreateCustomerModal

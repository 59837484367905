export type OperatorKeys =
  | 'contains'
  | 'not contains'
  | 'equals'
  | 'not equals'
  | 'greater than'
  | 'less than'
  | 'is one of'
  | 'is not one of'
  | 'is empty'
  | 'is not empty'
  | 'starts with'
  | 'ends with'

export const TEXT_OPERATORS = [
  'contains',
  'not contains',
  'equals',
  'not equals',
  'starts with',
  'ends with',
  'is empty',
  'is not empty',
]
export const NUMBER_OPERATORS = ['greater than', 'less than', ...TEXT_OPERATORS]

export const DATE_OPERATORS = [
  'greater than',
  'less than',
  'equals',
  'not equals',
  'starts with',
  'ends with',
  'is empty',
  'is not empty',
]

export const SELECT_OPERATORS = [
  'equals',
  // 'is one of', 'is not one of'
]

export const OPERATORS = [...TEXT_OPERATORS, ...SELECT_OPERATORS]

export const BE_OPERATORS: Record<OperatorKeys, string> = {
  contains: 'like',
  'not contains': 'nlike',
  equals: 'eq',
  'not equals': 'neq',
  'greater than': 'gt',
  'less than': 'lt',
  'is one of': 'in',
  'is not one of': 'nin',
  'is empty': 'IS_EMPTY',
  'is not empty': 'IS_NOT_EMPTY',
  'starts with': 'like',
  'ends with': 'like',
}

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import DashboardIcon from '@mui/icons-material/Dashboard'
import DescriptionIcon from '@mui/icons-material/Description'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import FlightIcon from '@mui/icons-material/Flight'
import HomeIcon from '@mui/icons-material/Home'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PointOfSaleIconIcon from '@mui/icons-material/PointOfSale'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import SailingIcon from '@mui/icons-material/Sailing'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import { SvgIcon } from '@mui/material'
import type { ReactNode } from 'react'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SettingsContext } from 'src/common/contexts/settings-context'
import { tokens } from '../../locales/tokens'
import { paths } from '../../navigator/paths'
import { sortBy } from 'lodash'
import { useAuth } from 'src/common/hooks/use-auth'

export interface Item {
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  items?: Item[]
  label?: ReactNode
  path?: string
  title: string
  scope?: string[]
}

export interface Section {
  items: Item[]
  subheader?: string
  collapse?: boolean
}

export const useSections = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    const appSettingsItems = [
      {
        title: t(tokens.nav.products),
        path: paths.products.index,
        scope: ['product.read'],
        icon: (
          <SvgIcon fontSize="small">
            <AccountBoxOutlinedIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.airports),
        path: paths.airports.index,
        scope: ['geo.read'],
        icon: (
          <SvgIcon fontSize="small">
            <FlightIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.seaports),
        path: paths.seaports.index,
        scope: ['geo.read'],
        icon: (
          <SvgIcon fontSize="small">
            <DirectionsBoatIcon />
          </SvgIcon>
        ),
      },
      {
        title: 'Terms and Conditions',
        path: paths.terms.index,
        scope: ['termsAndConditions.read'],
        icon: (
          <SvgIcon fontSize="small">
            <DescriptionIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.vessels),
        path: paths.vessels.index,
        scope: ['user.read'],
        icon: (
          <SvgIcon fontSize="small">
            <SailingIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.addresses),
        path: paths.addresses.index,
        scope: ['user.read'],
        icon: (
          <SvgIcon fontSize="small">
            <HomeIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.carriers),
        path: paths.carriers.index,
        scope: ['termsAndConditions.read'],
        icon: (
          <SvgIcon fontSize="small">
            <ConnectingAirportsIcon />
          </SvgIcon>
        ),
      },
      {
        title: t(tokens.nav.vendors),
        path: paths.vendors.index,
        scope: ['user.read'],
        icon: (
          <SvgIcon fontSize="small">
            <PointOfSaleIconIcon />
          </SvgIcon>
        ),
      },
    ]

    const sortedAppSettingsItems = sortBy(appSettingsItems, 'title')

    return [
      {
        items: [
          {
            title: t(tokens.nav.dashboard),
            path: paths.dashboard.index,
            scope: ['lead.read'],
            icon: (
              <SvgIcon fontSize="small">
                <DashboardIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.sales),
        items: [
          {
            title: t(tokens.nav.leads),
            path: paths.leads.index,
            scope: ['lead.read'],
            icon: (
              <SvgIcon fontSize="small">
                <PersonAddIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.opportunities),
            path: paths.opportunities.index,
            scope: ['opportunity.read'],
            icon: (
              <SvgIcon fontSize="small">
                <BusinessCenterIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.quotes),
            path: paths.quotes.index,
            scope: ['quote.read'],
            icon: (
              <SvgIcon fontSize="small">
                <RequestQuoteIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.contracts),
            path: paths.contracts.index,
            scope: ['contract.read'],
            icon: (
              <SvgIcon fontSize="small">
                <DescriptionIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.crm),
        items: [
          {
            title: t(tokens.nav.contacts),
            path: paths.contacts.index,
            scope: ['contact.read'],
            icon: (
              <SvgIcon fontSize="small">
                <ContactMailIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.customers),
            path: paths.customers.index,
            scope: ['customer.read'],
            icon: (
              <SvgIcon fontSize="small">
                <PeopleAltIcon />
              </SvgIcon>
            ),
          },

          //we use this comment to generate the code for new modules
        ],
      },
      {
        subheader: t(tokens.nav.operations),
        items: [
          {
            title: t(tokens.nav.files),
            path: paths.files.index,
            scope: ['file.read'],
            icon: (
              <SvgIcon fontSize="small">
                <InsertDriveFileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.purchaseOrders),
            path: paths.purchaseOrders.index,
            scope: ['file.read'],
            icon: (
              <SvgIcon fontSize="small">
                <ShoppingCartIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.finance),
        items: [
          {
            title: t(tokens.nav.invoices),
            path: paths.finance.invoices,
            scope: ['file.read'],
            icon: (
              <SvgIcon fontSize="small">
                <InsertDriveFileIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.usersRolesPermissions),
        items: [
          {
            title: t(tokens.nav.users),
            path: paths.user.index,
            scope: ['user.read'],
            icon: (
              <SvgIcon fontSize="small">
                <PersonIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.usersRoles),
            path: paths.roles.index,
            scope: ['role.read'],
            icon: (
              <SvgIcon fontSize="small">
                <SupervisorAccountIcon />
              </SvgIcon>
            ),
          },
        ],
      },
      {
        subheader: t(tokens.nav.appSettings),
        collapse: true,
        items: sortedAppSettingsItems,
      },
    ]
  }, [t])
}

export const useCustomerSections = () => {
  const { t } = useTranslation()

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.dashboard),
            path: paths.dashboard.index,
            scope: ['note.read'],
            icon: (
              <SvgIcon fontSize="small">
                <DashboardIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.quotes),
            path: paths.quotes.index,
            scope: ['note.read'],
            icon: (
              <SvgIcon fontSize="small">
                <RequestQuoteIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.orderList),
            path: paths.files.index,
            scope: ['note.read'],
            icon: (
              <SvgIcon fontSize="small">
                <InsertDriveFileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.shipments),
            path: paths.shipments.index,
            scope: ['note.read'],
            icon: (
              <SvgIcon fontSize="small">
                <LocalShippingIcon />
              </SvgIcon>
            ),
          },
          {
            title: t(tokens.nav.invoices),
            path: paths.finance.invoices,
            scope: ['note.read'],
            icon: (
              <SvgIcon fontSize="small">
                <InsertDriveFileIcon />
              </SvgIcon>
            ),
          },
        ],
      },
    ]
  }, [t])
}

export const useFilteredSections = () => {
  const { user } = useAuth()

  const { checkedMenuItems } = useContext(SettingsContext)

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const allSections = user?.customerId ? useCustomerSections() : useSections()
  // Get permissions using useRoleGuard for all scopes in allSections
  // const permissions = allSections.flatMap((section) =>
  //   section.items.map((item: any) => ({
  //     scope: item.scope,
  //     hasPermission: useRoleGuard({ scopes: [item.scope] }),
  //   })),
  // )

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const shouldIncludeItem = (item: any) => {
    return checkedMenuItems?.includes(item.title)
  }

  // Filter the sections and items based on checkedMenuItems
  const filteredSections = allSections
    .map((section) => ({
      ...section,
      items: section.items.filter(shouldIncludeItem),
    }))
    .filter((section) => section.items.length > 0)

  return filteredSections
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SortDirection = 'asc' | 'desc'

export type ColumnSort = {
  id: string
  desc: boolean
}

export type SortingState = ColumnSort[]

export type SortingTableState = {
  sorting: SortingState
}
interface Filter {
  column: string
  value?: string | number
}

interface AdvancedFilter {
  column: string
  operator: string
  value: string | number
}

interface FiltersState {
  advancedFilters: {
    [filterKey: string]: AdvancedFilter[]
  }
  simpleFilters: {
    [filterKey: string]: Filter[]
  }
  sorting: {
    [filterKey: string]: SortingState
  }
  generalSearch?: {
    [filterKey: string]: string | null
  }
}

interface SimpleFilterAction {
  filterKey: string
  filter?: Filter
}

interface AdvancedFilterAction {
  filterKey: string
  filter?: AdvancedFilter
  index?: number
}
interface GeneralSearchAction {
  filterKey: string
  term: string
}

const initialState: FiltersState = {
  advancedFilters: {},
  simpleFilters: {},
  sorting: {},
  generalSearch: {},
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    removeSimpleFilter: (state, action: PayloadAction<SimpleFilterAction>) => {
      const { filterKey, filter } = action.payload
      if (state.simpleFilters[filterKey]) {
        state.simpleFilters[filterKey] = state.simpleFilters[filterKey].filter(
          (f) => f.column !== filter?.column,
        )
      }
    },
    removeAllSimpleFilters: (state, action: PayloadAction<string>) => {
      const filterKey = action.payload
      if (state.simpleFilters[filterKey]) {
        state.simpleFilters[filterKey] = []
      }
    },

    updateSimpleFilter: (state, action: PayloadAction<SimpleFilterAction>) => {
      const { filterKey, filter } = action.payload
      if (filter) {
        if (!state.simpleFilters[filterKey]) {
          state.simpleFilters[filterKey] = []
        }

        const existingFilterIndex = state.simpleFilters[filterKey].findIndex(
          (f) => f.column === filter.column,
        )

        if (existingFilterIndex !== -1) {
          state.simpleFilters[filterKey][existingFilterIndex] = filter
        } else {
          state.simpleFilters[filterKey].push(filter)
        }
      }
    },

    addAdvancedFilter: (state, action: PayloadAction<AdvancedFilterAction>) => {
      const { filterKey, filter } = action.payload
      if (!state.advancedFilters[filterKey]) {
        state.advancedFilters[filterKey] = []
      }
      if (filter) {
        state.advancedFilters[filterKey].push(filter)
      }
      delete state.simpleFilters[filterKey]
    },
    removeAdvancedFilter: (
      state,
      action: PayloadAction<AdvancedFilterAction>,
    ) => {
      const { filterKey, index } = action.payload
      if (state.advancedFilters[filterKey] && index !== undefined) {
        state.advancedFilters[filterKey] = state.advancedFilters[
          filterKey
        ].filter((_, i) => i !== index)
      }
    },
    updateGeneralSearch: (
      state,
      action: PayloadAction<GeneralSearchAction>,
    ) => {
      const { filterKey, term } = action.payload

      if (!state.generalSearch) {
        state.generalSearch = {
          filterKey: null,
        }
      }

      if (term) {
        state.generalSearch[filterKey] = term
      } else {
        state.generalSearch[filterKey] = null
      }
    },
    updateAdvancedFilter: (
      state,
      action: PayloadAction<AdvancedFilterAction>,
    ) => {
      const { filterKey, index, filter } = action.payload
      if (state.advancedFilters[filterKey] && index !== undefined && filter) {
        state.advancedFilters[filterKey][index] = filter
      }
    },
    removeAllAdvancedFilters: (state, action: PayloadAction<string>) => {
      const filterKey = action.payload
      if (state.advancedFilters[filterKey]) {
        state.advancedFilters[filterKey] = []
      }
    },
    // Update the entire sorting state for a filter key
    updateSorting: (
      state,
      action: PayloadAction<{ filterKey: string; sorting: SortingState }>,
    ) => {
      const { filterKey, sorting } = action.payload
      if (state.sorting) {
        state.sorting[filterKey] = sorting
      }
    },
    clearSorting: (state, action: PayloadAction<string>) => {
      const filterKey = action.payload
      state.sorting[filterKey] = []
    },
  },
})

export const {
  updateSimpleFilter,
  removeSimpleFilter,
  removeAllSimpleFilters,
  addAdvancedFilter,
  updateAdvancedFilter,
  removeAdvancedFilter,
  removeAllAdvancedFilters,
  updateSorting,
  updateGeneralSearch,
} = filtersSlice.actions

export default filtersSlice.reducer

export const { reducer } = filtersSlice

/* eslint-disable @typescript-eslint/no-explicit-any */

import { Box, Card, Container, Stack, Typography } from '@mui/material'
import BatchPrintTable from './GridBatchPrint'

const BatchPrint = () => {
  return (
    <Box
      component="main"
      sx={{
        py: 4,
      }}
    >
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" spacing={4}>
          <Stack spacing={1}>
            <Typography variant="h4" p={2}>
              Batch Print Invoices
            </Typography>
          </Stack>
        </Stack>
        <Card sx={{ p: 1 }}>
          <BatchPrintTable />
        </Card>
      </Container>
    </Box>
  )
}

export default BatchPrint

/* eslint-disable react/react-in-jsx-scope */
import { ReactNode } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { RTL } from './common/components/rtl'
import { SplashScreen } from './common/components/splash-screen'
import { AuthConsumer, AuthProvider } from './common/contexts/auth/jwt-context'
import ModalProvider from './common/contexts/modal-context'
import {
  SettingsConsumer,
  SettingsProvider,
} from './common/contexts/settings-context'
import { useNprogress } from './common/hooks/use-nprogress'
import { createTheme } from './common/theme'
import './locales/i18n'
import { backOfficeRoutes, customerRoutes } from './navigator/routes'
import { store } from './store'
import GlobalToaster from './common/components/globalFetching'
import { SettingsDrawer } from './common/components'
import { useWebSocket } from './common/hooks/use-websocket'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const WebSocketIntializer = ({ children }: { children: ReactNode }) => {
  useWebSocket()
  return <>{children}</>
}

//  client for react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})
export const App: FC = () => {
  useNprogress()

  const element = useRoutes(backOfficeRoutes)
  const elementCustomer = useRoutes(customerRoutes)
  return (
    <ReduxProvider store={store}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <QueryClientProvider client={queryClient}>
          <WebSocketIntializer>
            <AuthProvider>
              <AuthConsumer>
                {(auth) => (
                  <SettingsProvider user={auth.user}>
                    <SettingsConsumer>
                      {(settings) => {
                        // Prevent theme flicker when restoring custom settings from browser storage
                        if (!settings.isInitialized) {
                          // return null;
                        }

                        const theme = createTheme({
                          colorPreset: settings.colorPreset,
                          contrast: settings.contrast,
                          direction: settings.direction,
                          paletteMode: settings.paletteMode,
                          responsiveFontSizes: settings.responsiveFontSizes,
                        })

                        const isCustomer = !!auth?.user?.customerId
                        return (
                          <ThemeProvider theme={theme}>
                            <ModalProvider>
                              <Helmet>
                                <meta
                                  name="color-scheme"
                                  content={settings.paletteMode}
                                />
                                <meta
                                  name="theme-color"
                                  content={theme.palette.neutral[900]}
                                />
                              </Helmet>
                              <RTL direction={settings.direction}>
                                <CssBaseline />
                                {/* Prevent guards from redirecting */}
                                {!auth.isInitialized ? (
                                  <SplashScreen />
                                ) : (
                                  <>{isCustomer ? elementCustomer : element}</>
                                )}
                                <SettingsDrawer
                                  canReset={settings.isCustom}
                                  onClose={settings.handleDrawerClose}
                                  onReset={settings.handleReset}
                                  onUpdate={settings.handleUpdate}
                                  open={settings.openDrawer}
                                  values={{
                                    colorPreset: settings.colorPreset,
                                    contrast: settings.contrast,
                                    direction: settings.direction,
                                    paletteMode: settings.paletteMode,
                                    responsiveFontSizes:
                                      settings.responsiveFontSizes,
                                    stretch: settings.stretch,
                                    layout: settings.layout,
                                    navColor: settings.navColor,
                                  }}
                                />

                                <GlobalToaster />
                              </RTL>
                            </ModalProvider>
                          </ThemeProvider>
                        )
                      }}
                    </SettingsConsumer>
                  </SettingsProvider>
                )}
              </AuthConsumer>
            </AuthProvider>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}{' '}
          </WebSocketIntializer>
        </QueryClientProvider>
      </LocalizationProvider>
    </ReduxProvider>
  )
}

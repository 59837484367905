/* eslint-disable @typescript-eslint/no-explicit-any */
export const wait = (time: number): Promise<void> =>
  new Promise((res) => setTimeout(res, time))

export const invalidateQueriesWithDelay = (
  queryClient: any,
  queryKey: any,
  delay = 800,
) => {
  setTimeout(() => {
    queryClient.invalidateQueries(queryKey)
  }, delay)
}

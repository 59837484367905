import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useContext } from 'react'
import { SettingsContext } from 'src/common/contexts/settings-context'
import type { NavColor } from '../../common/types/Settings'
import type { Section } from '../navigation/menuConfig'
import { NavigationBars } from './nav-bars'

const SIDE_NAV_WIDTH = 280
const SIDE_NAV_COLLAPSED_WIDTH = 86

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
})

interface VerticalLayoutProps {
  children?: ReactNode
  navColor?: NavColor
  sections?: Section[]
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const settings = useContext(SettingsContext)
  const { openMenu } = settings

  const VerticalLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: openMenu ? SIDE_NAV_WIDTH : SIDE_NAV_COLLAPSED_WIDTH,
    },
  }))

  const { children, sections, navColor } = props

  return (
    <>
      <NavigationBars navColor={navColor} sections={sections} />
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>{children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  )
}

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident']),
  sections: PropTypes.array,
}

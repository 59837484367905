import { LineItem, Package } from 'src/common/types/Quote'

type QuoteRow = {
  unitPrice: number
  quantity: number
}

type Quote = {
  lineItems: QuoteRow[]
  discount: number
  taxtPercentage: number
}

// Calculate total for a single row
export function calculateRowTotal(row: QuoteRow | LineItem): number {
  return (row.unitPrice || 0) * (row.quantity || 0)
}

// Calculate subtotal of the quote (sum of all row totals)
export function calculateSubtotal(quote: Quote): number {
  if (!quote.lineItems) {
    return 0 // or whatever default value you want to return
  }
  return quote.lineItems.reduce(
    (subtotal, row) => subtotal + calculateRowTotal(row),
    0,
  )
}

// Apply discount to the subtotal
export function applyDiscount(subtotal: number, discount: number): number {
  return subtotal - (subtotal * discount) / 100
}

// Apply tax to the subtotal
export function applyTax(subtotal: number, taxtPercentage: number): number {
  const tax = subtotal * taxtPercentage
  return subtotal + tax
}

export function calculateSubDiscountTotal(quote: Quote): number {
  const subtotal = calculateSubtotal(quote)
  return applyDiscount(subtotal, quote.discount)
}

export function calculateTotalSubtotalDifference(
  total: number,
  subtotal: number,
) {
  return total - subtotal
}

// Calculate final total of the invoice
export function calculateFinalTotal(quote: Quote): number {
  const subtotal = calculateSubtotal(quote)
  const subtotalAfterDiscount = applyDiscount(subtotal, quote.discount)

  return applyTax(subtotalAfterDiscount, quote.taxtPercentage)
}

export function calculatePackagesTotal(packages: Package[]) {
  return packages.reduce((acc, pkg) => acc + (pkg.qty ?? 0), 0)
}

export function calculatePackagesWeight(packages: Package[]) {
  return packages.reduce((acc, pkg) => acc + (pkg.weight.value ?? 0), 0)
}

export function calculatePackagesVolume(packages: Package[]) {
  return packages.reduce(
    (acc, pkg) => acc + (pkg.dimensions.volume?.value ?? 0),
    0,
  )
}

type volumeUnit = 'mm3' | 'cm3' | 'm3' | 'in3' | 'ft3'

function toCM3(value: number, unit: volumeUnit) {
  switch (unit) {
    case 'mm3':
      return value / 1000
    case 'cm3':
      return value
    case 'm3':
      return value * 1000000
    case 'in3':
      return value * 16.3871
    case 'ft3':
      return value * 28316.8
    default:
      return value
  }
}

export function calculateChargableWeight(packages: Package[]): number {
  const densityRatio = 6000
  let totalChargableWeight = 0

  packages.forEach((item) => {
    const { value, unit } = item?.dimensions?.volume || {}
    const volumeinCm = toCM3(value || 0, unit)
    const loadingMeterPerColli = volumeinCm / densityRatio
    totalChargableWeight += loadingMeterPerColli
  })

  return totalChargableWeight || 0
}

import { IconButton } from '@mui/material'
import { toast, Toaster, ToastBar } from 'react-hot-toast'
import CloseIcon from '@mui/icons-material/Close'
import 'src/common/components/animations.css'

const GlobalToaster = () => {
  return (
    <Toaster position="top-right" containerStyle={{ marginTop: '40px' }}>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <IconButton
                  aria-label="delete"
                  onClick={() => toast.dismiss(t.id)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}
export default GlobalToaster

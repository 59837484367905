import { Stack } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { useCallback } from 'react'
import { useGetUsers } from 'src/common/apis'
import GooglePlacesAutocomplete from 'src/common/components/autocomplete/places'
import AutocompleteWithCreate from 'src/common/components/autocomplete/common/autocomplete-with-create'
import { FormProps } from 'src/common/types/common'
import { User } from 'src/common/types/User'
import RenderField from 'src/common/components/form/RenderField'

const BusinessEntityForm = ({ formik }: FormProps) => {
  const usersQuery = useGetUsers({})

  const listUsers: User[] = usersQuery.isLoading ? [] : usersQuery?.data

  const AssignUser = useCallback(() => {
    return (
      <AutocompleteWithCreate
        formik={formik}
        options={usersQuery.data || []}
        name="assignedTo"
        label="Assigned to "
        isLoading={usersQuery.isLoading}
        allowCreate={false}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersQuery.isLoading, formik.values.assignedTo, listUsers])

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <RenderField
          formik={formik}
          field={{
            label: 'Company name *',
            name: 'companyName',
            type: 'text',
          }}
        />

        <RenderField
          formik={formik}
          field={{
            label: 'Client Number *',
            name: 'clientNumber',
            type: 'text',
          }}
        />

        <RenderField
          formik={formik}
          field={{
            label: 'Email',
            name: 'email',
            type: 'text',
          }}
        />

        <MuiTelInput
          error={!!(formik.touched.phone && formik.errors.phone)}
          fullWidth
          InputLabelProps={{ shrink: true }}
          helperText={formik.touched.phone && formik.errors.phone}
          label="Phone Number"
          name="phone"
          onBlur={formik.handleBlur}
          onChange={(newValue: string) =>
            formik.setFieldValue('phone', newValue)
          }
          value={formik.values.phone}
          defaultCountry="NL"
          variant="outlined"
        />

        <GooglePlacesAutocomplete formik={formik} name="fullAddress" />

        <RenderField
          field={{
            label: 'Payment terms *',
            name: 'paymentTerms',
            type: 'number',
          }}
          formik={formik}
          suffix="days"
        />

        <RenderField
          formik={formik}
          field={{
            label: 'Tax Id',
            name: 'taxId',
            type: 'text',
          }}
        />

        <AssignUser />
      </Stack>
    </form>
  )
}

export default BusinessEntityForm

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { ENTITIES, INDEXES } from 'src/common/apis/constants'
import { get, post, postDelete, postFormData, put } from 'src/common/utils/api'
import transformToFormData from 'src/common/utils/transformToFormData'
import {
  OperationsFilesDocumentValues as DocumentValues,
  OperationsFilesUpdateMuataionProp as UpdateMuataionProp,
  OperationsMutationProp as mutationProp,
} from 'src/common/types/Operation'
import { useAuth } from 'src/common/hooks/use-auth'

export const useGetFilesQuery = () => {
  return useQuery(['file'])
}

export const useCreateFileMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) =>
      post(
        'operations/files',
        {
          ...values,
        },
        setFieldError,
      ),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => {
      toast.success(`File was created successfully`)
      queryClient.invalidateQueries([INDEXES.FILE])
      callback && callback(data.id)
    },
    onError: () => {
      toast.error(`File could not be created! Please try again`)
    },
  })
}

export const useUpdateFileMutation = ({
  callback,
  fileId,
}: UpdateMuataionProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => put(`operations/files/${fileId}`, values, setFieldError),
    onSuccess: () => {
      toast.success(`Success! File was updated`)
      queryClient.invalidateQueries([INDEXES.FILE])
      queryClient.invalidateQueries([ENTITIES.FILES, fileId])

      callback && callback()
    },
    onError: () => {
      toast.error(`File could not be updated! Please try again`)
    },
  })
}

export const useDeleteFileMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (filesId: string) => postDelete(`operations/files/${filesId}`),
    onSuccess: () => {
      toast.success(`File was deleted successfully`)
      queryClient.invalidateQueries([INDEXES.FILE])
      callback && callback()
    },
    onError: (e: any) => {
      if (e?.message) {
        toast.error(e?.message)
        return
      }
      toast.error(`File could not be deleted! Please try again`)
    },
  })
}

export const useGetFileDetails = (id: string | null) => {
  return useQuery({
    queryKey: ['files', id],
    queryFn: () => get(`operations/files/${id}`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      const { customer, assignedTo, ...other } = data
      return {
        ...other,
        customerId: customer
          ? {
              ...customer,
              label: customer.name,
              value: customer.id,
            }
          : null,
        assignedTo: assignedTo?.id,
      }
    },
  })
}

 
export const useGetCustomerFileDetails = (id: string | null) => {
  const { user } = useAuth()
  return useQuery({
    queryKey: ['customer/files', id],
    queryFn: () => get(`customers/${user?.customerId}/orders/${id}`, {}, false),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      const { customer, assignedTo, ...other } = data
      return {
        ...other,
        customerId: customer
          ? {
              ...customer,
              label: customer.name,
              value: customer.id,
            }
          : null,
        assignedTo: assignedTo?.id,
      }
    },
  })
}
export const useGetDetails = useGetFileDetails

// {{base_URL}}/api/operations/files/9a4f223a-aec4-4867-a56d-409044faa3ea/upload-document

export const useUploadFileMutation = ({ callback }: mutationProp) => {
  //we will have a file id, a File and a type of file name
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: DocumentValues) => {
      const formData = transformToFormData(values)
      return postFormData(`document-manager/documents`, formData)
    },
    onSuccess: (_data, variables) => {
      toast.success(`File was uploaded successfully`)
      queryClient.invalidateQueries([ENTITIES.FILES, variables.fileId])
      if (!variables.isPrivate) {
        toast('This document will be visible to the customer!', {
          icon: '⚠️',
          style: {
            border: '1px solid #FFA500',
            padding: '16px',
            color: '#FFA500',
          },
        })
      }
      callback && callback()
    },
    onError: () => {
      toast.error(`File could not be uploaded! Please try again`)
    },
  })
}

export const useCreateFileBucket = ({ callback }: mutationProp) => {
  //we will have a file id, a File and a type of file name
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      return post(`document-manager/buckets`, values, setFieldError)
    },
    onSuccess: (data, variables) => {
      toast.success(`New bucket was created successfully`)
      queryClient.invalidateQueries([ENTITIES.FILES, variables?.values?.fileId])
      callback && callback()
    },
    onError: () => {
      toast.error(`Bucket could not be created! Please try again`)
    },
  })
}

export const useDeleteFileBucket = ({ callback }: mutationProp) => {
  //we will have a file id, a File and a type of file name
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: any) => {
      return postDelete(`document-manager/buckets/${values?.id}`, values)
    },
    onSuccess: (data, variables) => {
      toast.success(`Bucket was deleted successfully`)
      queryClient.invalidateQueries([ENTITIES.FILES, variables.fileId])
      callback && callback()
    },
    onError: () => {
      toast.error(`Bucket could not be created! Please try again`)
    },
  })
}

export const useRemoveFileMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: { fileId: string; id: string; hash: string }) => {
      return postDelete(`document-manager/documents/${values?.id}`)
    },
    onSuccess: (data, variables) => {
      toast.success(`File was removed!`)
      queryClient.invalidateQueries([ENTITIES.FILES, variables.fileId])
      callback && callback()
    },
    onError: () => {
      toast.error(`File could not be removed! Please try again`)
    },
  })
}

export const useHideFileBucket = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: any) => {
      return post(`document-manager/buckets/${values?.id}/hide`)
    },
    onSuccess: (data, variables) => {
      toast.success(`Bucket was set to private successfully`)
      queryClient.invalidateQueries([ENTITIES.FILES, variables.object_id])
      callback && callback()
    },

    onError: () => {
      toast.error(`Bucket could not be set to private! Please try again`)
    },
  })
}

export const useShowFileBucket = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (bucketId: any) => {
      return post(`document-manager/buckets/${bucketId?.id}/show`)
    },
    onSuccess: (data, variables) => {
      toast.success(`Bucket was set to public successfully`)
      queryClient.invalidateQueries([ENTITIES.FILES, variables.object_id])

      callback && callback()
    },
    onError: () => {
      toast.error(`Bucket could not be set to public! Please try again`)
    },
  })
}

/* eslint-disable react-hooks/exhaustive-deps */
import { INDEXES } from 'src/common/apis/constants'
import CreateContactsModal from 'src/modules/contact/create-modal'
import DynamicAutocomplete from './common/dynamic-autocomplete-with-create'
import { get, isEqual } from 'lodash'
import { Option } from '../grid/types'

interface ContactsAutocompleteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
  readOnly?: boolean
  multiple?: boolean
  isLoading?: boolean
  name?: string
  label?: string
  placeholder?: string
  size?: 'small' | 'medium'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterFilter?: (item: any) => any | null
  filterByCustomerId?: string | null
  hideNullCustomerIds?: boolean | undefined
  contactsOfCustomer?: Option[]
}

const ContactsAutocomplete = ({
  name = 'contact',
  label = 'Contact',
  formik: {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    handleBlur,
  },
  readOnly,
  multiple = false,
  placeholder,
  isLoading,
  afterFilter,
  filterByCustomerId,
  size = 'medium',
  contactsOfCustomer,
}: ContactsAutocompleteProps) => {
  if (isLoading) return null

  const value = values ? get(values, name) || [] : null
  const error = get(errors, name)
  const touchedField = get(touched, name)
  const setFormikFieldValue = (
    inputName: string,
    newValue: Option | Option[] | null,
  ) => {
    if (!isEqual(value, newValue)) {
      setFieldValue(inputName, newValue)
    }
  }

  return (
    <DynamicAutocomplete
      setFieldValue={setFormikFieldValue}
      setFieldTouched={setFieldTouched}
      readOnly={readOnly}
      name={name}
      label={label || 'Contact'}
      index={INDEXES.CONTACT}
      size={size}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onModalSubmit={(newItem: any) => {
        return {
          ...newItem,
          id: newItem.id,
          value: newItem.id,
          label: newItem.name,
        }
      }}
      {...(filterByCustomerId && contactsOfCustomer
        ? {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            options: contactsOfCustomer?.map((contact: any) => ({
              value: contact.id.toString(),
              label: contact.name.toString(),
            })),
          }
        : {})}
      ModalComponent={CreateContactsModal}
      multiple={multiple}
      placeholder={placeholder}
      error={error}
      touched={touchedField}
      handleBlur={handleBlur}
      value={value}
      afterFilter={afterFilter}
      variant="outlined"
    />
  )
}

export default ContactsAutocomplete

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

interface LoadingButtonProps {
  id?: string
  title?: string
  sx?: any
  onClick?: any
  isLoading?: boolean
  disabled?: boolean
  startIcon?: any
  className?: any
  size?: 'small' | 'medium' | 'large'
  variant?: 'outlined' | 'contained' | 'text'
  color?: 'primary' | 'secondary' | 'error' | 'warning'
  type?: 'submit' | string
  fullWidth?: boolean
  children: React.ReactNode
}

const Button = ({
  id,
  sx,
  onClick,
  color,
  isLoading,
  disabled,
  size = 'medium',
  variant = 'outlined',
  startIcon,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  type,
  fullWidth = false,
  className,
  children,
  title,
}: LoadingButtonProps) => {
  return (
    <LoadingButton
      id={id}
      disabled={disabled}
      loading={isLoading}
      size={size}
      sx={sx}
      onClick={onClick}
      variant={variant}
      fullWidth={fullWidth}
      loadingPosition="start"
      color={color}
      startIcon={startIcon}
      className={className}
      title={title}
      // type={type}
    >
      {children}
    </LoadingButton>
  )
}

export default Button

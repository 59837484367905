/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Checkbox, Stack, Typography } from '@mui/material'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { useGetPermissionsQuery } from '../useRolesRequests'
import {
  RolesPermissionGroup as PermissionGroup,
  RolesPermissionModule as PermissionModule,
} from 'src/common/types/Role'
import { RoleType } from 'src/common/types/common'
import RenderField from 'src/common/components/form/RenderField'

const permissionMapping: Record<string, string> = {
  write: 'Write',
  read: 'Read',
  delete: 'Delete',
  create: 'Create',
  edit: 'Edit',
}

const moduleMapping: Record<string, string> = {
  office: 'Office',
  user: 'User',
  sales: 'Sales',
  common: 'Common',
  crm: 'CRM',
  note: 'Note',
  acl: 'ACL',
  lead: 'Lead',
  opportunity: 'Opportunity',
  quote: 'Quote',
  contract: 'Contract',
  customer: 'Customer',
  contact: 'Contact',
  entity: 'Business Entity',
  iso: 'ISO',
  geo: 'Geo',
  service_type: 'Service Type',
  termsAndConditions: 'Terms and Conditions',
  search: 'Search',
  role: 'Role',
  permission: 'Permission',
  airport: 'Airport',
  seaport: 'Seaport',
  vessel: 'Vessel',
  address: 'Address',
  carrier: 'Carrier',
  vendor: 'Vendor',
}

const RoleCreationForm: React.FC<RoleType> = ({ formik, roleType }) => {
  const { data: permissions } = useGetPermissionsQuery()

  const permissionsList: Record<string, PermissionModule | PermissionGroup[]> =
    permissions?.data?.permissions || {}

  // Function to handle the change in checkbox status and update permissions
  const handleSelectAll = (group: any) => {
    const currentPermissions = formik.values.permissions || []
    const groupPermissions = group.permissions

    const newPermissions = currentPermissions.includes(groupPermissions[0])
      ? currentPermissions.filter(
          (permission: string) => !groupPermissions.includes(permission),
        )
      : [...currentPermissions, ...groupPermissions]

    formik.setFieldValue('permissions', newPermissions)
  }

  // Function to handle the change in checkbox status and update permissions
  const handleSelectAllModules = (module: any) => {
    const currentPermissions = formik.values.permissions || []
    const groupPermissions = module.groups.reduce(
      (acc: any, group: any) => [...acc, ...group.permissions],
      [],
    )

    const allPermissionsSelected = groupPermissions.every(
      (permission: string) => currentPermissions.includes(permission),
    )

    let newPermissions

    if (allPermissionsSelected) {
      // If all permissions are selected, deselect all of them
      newPermissions = currentPermissions.filter(
        (permission: string) => !groupPermissions.includes(permission),
      )
    } else {
      // If not all permissions are selected, select all of them
      newPermissions = [...currentPermissions, ...groupPermissions]
    }

    formik.setFieldValue('permissions', newPermissions)
  }

  // Function to handle the change in checkbox status and update permissions for customer roles
  const handleSelectAllCustomerRoles = (module: any) => {
    const currentPermissions = formik.values.permissions || []
    const modulePermissions = module.permissions || []

    const allPermissionsSelected = modulePermissions.every(
      (permission: string) => currentPermissions?.includes(permission),
    )

    let newPermissions

    if (allPermissionsSelected) {
      // If all permissions are selected, deselect all of them
      newPermissions = currentPermissions.filter(
        (permission: string) => !modulePermissions.includes(permission),
      )
    } else {
      // If not all permissions are selected, select all of them
      newPermissions = [...currentPermissions, ...modulePermissions]
    }

    formik.setFieldValue('permissions', newPermissions)
  }

  // Function to get the label of a permission
  const getPermissionLabel = (permission: string) => {
    const parts = permission.split('.')

    if (parts.length === 2) {
      const [module, action] = parts
      const friendlyModule = moduleMapping[module] || module
      const friendlyPermission = permissionMapping[action] || action
      return `${friendlyModule} - ${friendlyPermission}`
    } else if (parts.length === 3) {
      const [scope, module, action] = parts
      const friendlyScope = moduleMapping[scope] || scope
      const friendlyModule = moduleMapping[module] || module
      const friendlyPermission = permissionMapping[action] || action
      return `${friendlyScope} - ${friendlyModule} - ${friendlyPermission}`
    }

    return permission
  }

  // Function to handle the change in checkbox status and update permissions
  const handlePermissionChange = (permission: string) => {
    const checked = !formik.values?.permissions?.includes(permission)
    const currentPermissions = formik.values.permissions || []
    const newPermissions = checked
      ? [...currentPermissions, permission]
      : currentPermissions.filter((p: any) => p !== permission)
    formik.setFieldValue('permissions', newPermissions)
  }

  const renderTreeItems = () => {
    if (roleType === 'customer-portal') {
      return Object.entries(permissionsList[roleType] || {}).map(
        ([key, module]) => (
          <TreeItem
            nodeId={key}
            key={key}
            label={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Checkbox
                  onClick={(e) => e.stopPropagation()}
                  checked={module?.permissions?.every((permission: any) =>
                    formik.values.permissions?.includes(permission),
                  )}
                  onChange={() => handleSelectAllCustomerRoles(module)}
                />
                {module.name}
              </Box>
            }
          >
            {module?.permissions?.map((permission: string) => (
              <TreeItem
                key={permission}
                nodeId={permission}
                onClick={(e) => {
                  e.stopPropagation()
                  handlePermissionChange(permission)
                }}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Checkbox
                      checked={formik.values?.permissions?.includes(permission)}
                      onChange={() => handlePermissionChange(permission)}
                      name="permissions"
                      value={permission}
                    />
                    {getPermissionLabel(permission)}
                  </Box>
                }
              />
            ))}
          </TreeItem>
        ),
      )
    } else {
      return Object.entries(permissionsList[roleType] || {}).map(
        ([key, value]) => {
          const isGrouped = Array.isArray(value)
          const module = isGrouped ? { name: key, groups: value } : value

          return (
            <TreeItem
              nodeId={key}
              key={key}
              label={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Checkbox
                    onClick={(e) => e.stopPropagation()}
                    checked={module?.groups?.every((group: any) =>
                      group.permissions.every((permission: string) =>
                        formik.values.permissions?.includes(permission),
                      ),
                    )}
                    onChange={() => handleSelectAllModules(module)}
                  />
                  {module?.name}
                </Box>
              }
            >
              {module?.groups?.map((group: any) => (
                <TreeItem
                  nodeId={`${key}-${group.name}`}
                  key={`${key}-${group.name}`}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginLeft: '-12px',
                      }}
                    >
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={group.permissions.every((permission: string) =>
                          formik.values.permissions?.includes(permission),
                        )}
                        onChange={() => handleSelectAll(group)}
                      />
                      {group.name}
                    </Box>
                  }
                >
                  {group?.permissions?.map((permission: string) => (
                    <TreeItem
                      key={permission}
                      nodeId={permission}
                      onClick={(e) => {
                        e.stopPropagation()
                        handlePermissionChange(permission)
                      }}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Checkbox
                            checked={formik.values?.permissions?.includes(
                              permission,
                            )}
                            onChange={() => handlePermissionChange(permission)}
                            name="permissions"
                            value={permission}
                          />
                          {getPermissionLabel(permission)}
                        </Box>
                      }
                    />
                  ))}
                </TreeItem>
              ))}
            </TreeItem>
          )
        },
      )
    }
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={3} mb={3}>
        <RenderField
          field={{
            label: 'Role Name',
            name: 'name',
            type: 'text',
          }}
          formik={formik}
          sx={{ maxWidth: 400 }}
        />

        <Box py={2}>
          <Typography
            variant="h6"
            sx={{
              paddingBottom: 2,
            }}
          >
            Permissions:
          </Typography>
          <TreeView
            aria-label="permissions tree"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
          >
            {renderTreeItems()}
          </TreeView>
          {formik.errors.permissions ? (
            <Typography color="error">{formik.errors.permissions}</Typography>
          ) : (
            ''
          )}
        </Box>
      </Stack>
    </form>
  )
}

export default RoleCreationForm

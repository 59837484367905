import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FormState {
  activeStep: number
  showAllSteps: boolean
}

const initialState: FormState = {
  activeStep: 0,
  showAllSteps: false,
}

const quoteFormSlice = createSlice({
  name: 'quoteForm',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setShowAllSteps: (state, action: PayloadAction<boolean>) => {
      state.showAllSteps = action.payload
    },
    incrementStep: (state) => {
      state.activeStep += 1
    },
    decrementStep: (state) => {
      state.activeStep -= 1
    },
  },
})

export const { setActiveStep, setShowAllSteps, incrementStep, decrementStep } =
  quoteFormSlice.actions

export const { reducer } = quoteFormSlice
